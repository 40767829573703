import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePost, getPosts } from "../../../store/actions/postActions";
import swal from "sweetalert";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { publishedUnpublishedPost } from "../../../store/actions/postActions";
import Paginate from "../../pagination/Paginate";
function Posts() {

    const [searchInput, setSearchInput] = useState("");
    const { posts, page_no, total_pages } = useSelector((state) => state.posts);
    const dashboardData = useSelector((state) => state.dashboard_data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPosts(1, searchInput));
    }, [dispatch])

    const addNewPost = () => {
        navigate('/posts/create')
    }
    const postSearch = () => {
        dispatch(getPosts(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deletePost(id))
            }
        });
    }
    const enableDisablePost = (id, action) => {
        const button_text = action ? "Unpublish" : "Publish";
        swal({
            title: "",
            text: "Are you sure?",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", button_text],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(publishedUnpublishedPost(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getPosts(current_page, searchInput));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Posts</h4>
                                        <p className="sub-header">
                                            {dashboardData.posts} Posts.
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    {/* <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewPost}><i className="mdi mdi-plus-circle mr-2"></i> Add New Post</button>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Post Content" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={postSearch}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-columns">
                                                {/* {
                                                    posts && posts.map((post) => {
                                                        let profile_pic;
                                                        let multimedia_block;
                                                        let is_active;

                                                        if (post.is_active) {
                                                            is_active = "Published";
                                                        }
                                                        else {
                                                            is_active = "Hidden";
                                                        }
                                                        let sliders = [];

                                                        // if (post.posted_by.profile_pic !== '-' && post.posted_by.profile_pic!=undefined ) {
                                                        //     profile_pic = <img src={post.posted_by.profile_pic} className="rounded-circle img-thumbnail" alt="profile-image" />
                                                        // } else {
                                                        //     profile_pic = <img src="assets/images/users/no-avatar.jpg" className="rounded-circle img-thumbnail" alt="profile-image" />
                                                        // }
                                                        if (post.has_multimedia == true) {

                                                            if (post.videos.length !== 0) {
                                                                post.videos.map((video) => {
                                                                    sliders.push(video.thumbnail)
                                                                });
                                                            }
                                                            if (post.images.length !== 0) {
                                                                post.images.map((image) => {
                                                                    sliders.push(image)
                                                                });
                                                            }
                                                            multimedia_block = (
                                                                <AwesomeSlider animation="fall-animation" >
                                                                    <div data-src={sliders.length !== 0 ? sliders[0] : "assets/images/small/img-3.jpg"} />
                                                                </AwesomeSlider>
                                                            )
                                                        }
                                                        else {
                                                            multimedia_block = ""
                                                        }
                                                        return (
                                                            <div className="card text-center card-box border" key={post._id}>

                                                                <div className="dropdown float-right">
                                                                    <a href="#" className="dropdown-toggle card-drop arrow-none" data-toggle="dropdown" aria-expanded="false">
                                                                        <div><i className="mdi mdi-dots-horizontal h3 m-0 text-muted"></i></div>
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <span className="dropdown-item" onClick={() => handleDelete(post._id)}  >Delete </span>
                                                                        <span className="dropdown-item" onClick={() => publishedUnpublishedPosts(post._id, post.is_published)} >
                                                                            Enable and Disable Posts
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <div className="member-card">
                                                                    <div className="d-flex d-flex justify-content-start">
                                                                        <div className="avatar-md member-thumb ">
                                                                            {profile_pic}
                                                                            <i className="mdi mdi-star-circle member-star text-success" title="verified user"></i>
                                                                        </div>
                                                                        <div className="ml-2">
                                                                            <h4 className="mb-1">{post?.posted_by?.name}</h4>
                                                                            <p className="text-muted"><i className="far fa-marker"></i>{post.location.place}<span> | </span>
                                                                                <span>
                                                                                    {post?.posted_by?.college_name}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <p className="text-muted text-left">
                                                                        {post.text_content}
                                                                    </p>
                                                                    {multimedia_block}
                                                                    <div className="mt-4">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="mt-2 mb-1">
                                                                                    <h4 className="mb-1">{post.like_count}</h4>
                                                                                    <p className="mb-0 text-muted" title="Likes">
                                                                                        <i className="mdi mdi-heart-outline"></i>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="mt-2">
                                                                                    <h4 className="mb-1">{post.comment_count}</h4>
                                                                                    <p className="mb-0 text-muted" title="Comments">
                                                                                        <i className="far fa-comments"></i>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="mt-2">
                                                                                    <h4 className="mb-1">{is_active}   </h4>
                                                                                    <p className="mb-0 text-muted" title="Comments">
                                                                                        <i className="fas fa-map-pin"></i>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                        <table id="datatable-buttons" class="table table-striped dt-responsive nowrap table-actions-bar">
                                            <thead>
                                                <tr>
                                                    <th>Content</th>
                                                    <th>Posted By</th>
                                                    <th>Published</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    posts && posts.map((post) => {
                                                        let sliders = [];
                                                        let multimedia_block;
                                                        if (post.has_multimedia == true) {
                                                            if (post.videos.length !== 0) {
                                                                post.videos.map((video) => {
                                                                    sliders.push(video.thumbnail)
                                                                });
                                                            }
                                                            if (post.images.length !== 0) {
                                                                post.images.map((image) => {
                                                                    sliders.push(image)
                                                                });
                                                            }
                                                            multimedia_block = (<>
                                                                <AwesomeSlider animation="fall-animation" bullets={false}>
                                                                    {
                                                                        (sliders.length !== 0) ?
                                                                            sliders.map((slider) => {
                                                                                return (<div data-src={slider} />);
                                                                            }) :
                                                                            <div data-src="assets/images/no-thumbnail.jpg" />
                                                                    }
                                                                </AwesomeSlider>
                                                            </>
                                                            )
                                                        }
                                                        else {
                                                            multimedia_block = (<AwesomeSlider animation="fall-animation" bullets={false} buttons={false}>
                                                                <div data-src="assets/images/no-thumbnail.jpg" />
                                                            </AwesomeSlider>)
                                                        }
                                                        return (<tr key={post._id}>
                                                            <td style={{ "width": "50%" }}>
                                                                <div class="media mb-1">
                                                                    <div className="col-3">
                                                                        {multimedia_block}
                                                                    </div>
                                                                    <div class="media-body">
                                                                        {/* <h4 class="header-title">Round</h4> */}
                                                                        <p class="sub-header">
                                                                            {post.text_content}
                                                                        </p>
                                                                        <div id="round" class="rating-star" >
                                                                            <i class="fas fa-heart text-primary" title="Like" ></i>
                                                                            &nbsp;
                                                                            <span>{post.like_count}</span>
                                                                            &nbsp;
                                                                            &nbsp;
                                                                            <i class="fas fa-comment text-green" title="Comments" ></i>
                                                                            &nbsp;
                                                                            <span>{post.comment_count}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ "width": "15%" }}>
                                                                {
                                                                    post?.posted_by?.profile_pic === '-' || post?.posted_by?.profile_pic == undefined || post?.posted_by?.profile_pic == "" ?
                                                                        <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" className="rounded-circle avatar-sm border" /> :
                                                                        <img src={post?.posted_by?.profile_pic} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm border" />
                                                                }
                                                                <span class="ml-1">
                                                                    <b>{post?.posted_by?.name}</b>
                                                                </span>
                                                            </td>
                                                            <td>{post.createdAt?.split("T")[0]}</td>
                                                            <td>
                                                                {(post.is_published) ? <span><i className="fas fa-eye text-primary"></i> Published</span> : <span><i className="fas fa-eye-slash"></i> Hidden</span>}
                                                            </td>
                                                            <td>
                                                                {/* <a class="table-action-btn" title="View User" href="/users/view/62badea1eef1f6e4e09c9c85">
                                                                    <i class="mdi mdi-eye"></i>
                                                                </a> */}
                                                                <span class="table-action-btn" title="Change Status" onClick={() => enableDisablePost(post._id, post.is_published)} >
                                                                    <i class="mdi mdi-pencil"></i>
                                                                </span>
                                                                <span class="table-action-btn" title="Post Delete" onClick={() => handleDelete(post._id)} >
                                                                    <i class="mdi mdi-close"></i>
                                                                </span>
                                                            </td>
                                                        </tr>);
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                        <div className="table-responsive">

                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Posts;