import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getColleges } from "../../../store/actions/collegeActions";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { deleteCollege } from "../../../store/actions/collegeActions";
import Paginate from "../../pagination/Paginate";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function Colleges() {
    SetDocumentTitle(`Colleges \u2022 ${APP_NAME}`);
    const [searchInput, setSearchInput] = useState("");
    const { colleges, page_no, total_pages } = useSelector((state) => state.colleges);//User State from Store
    const dashboardData = useSelector((state) => state.dashboard_data);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getColleges(1, searchInput));
    }, [dispatch])

    const addNewCollege = () => {
        navigate('/colleges/create')
    }
    const collegeSearch = () => {
        dispatch(getColleges(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteCollege(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getColleges(current_page, searchInput));
    }
    String.prototype.trunc = function (n) {
        return this.substr(0, n - 1) + (this.length > n ? '...' : '');
    };
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Colleges</h4>
                                        <p className="sub-header">
                                            {dashboardData.colleges} Colleges.
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewCollege}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={collegeSearch}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Description</th>
                                                        <th>Parent University</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>Register</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {colleges && colleges.map((college) => {
                                                        return (
                                                            <tr key={college._id}>
                                                                <td>{college.name}</td>
                                                                <td>{college.email}</td>
                                                                <td>{college.description.trunc(40)}</td>
                                                                <td>{college.university}</td>
                                                                <td></td>
                                                                <td>{college.dial_code} <b>{college.phone}</b></td>
                                                                <td>{college.createdAt?.split("T")[0]}</td>
                                                                <td>
                                                                    <Link to={`/colleges/view/${college._id}`} className="table-action-btn" title="View College">
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>
                                                                    <Link to={`/colleges/${college._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="College Delete" onClick={() => handleDelete(college._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Colleges;