//Final ***
//Loading Bar
export const ENABLE_LOADING = "enable-loading";
export const DISABLE_LOADING = "disable-loading";
//Sidebar Toggle
export const COLLAPSE_SIDEBAR = "collapse-sidebar";
export const EXPAND_SIDEBAR = "expand-sidebar";
//App Notification Message Actions
export const SET_MESSAGE = "set-message";
export const CLEAR_MESSAGE = "clear-message";
//Media Content
export const GET_MEDIA_CONTENT = 'get-media-content';
export const GET_SINGLE_MEDIA_CONTENT = 'get-single-media-content';
export const GET_GENRES = 'get-genres';
//Public Login
export const PUBLIC_LOGIN_SUCCESS = 'public-login-success';
export const PUBLIC_LOGOUT = "public-logout";

//Public uploader
export const GET_PUBLIC_GENRES = 'get-public-genres';
export const GET_PUBLIC_LANGUAGES = 'get-public-languages';
export const PUBLIC_REDIRECT = 'public-redirect';
export const RESET_PUBLIC_REDIRECT = 'reset-public-redirect'
export const GET_MOVIE_META = 'get-movie-meta';
export const LOCATION_RELOAD = 'location-reload';
export const CONTENT_CATEGORIES = 'content-categories';
export const CONTENT_GENRES = 'content-genres';
export const CONTENT_LANGUAGES = 'content-languages';

//live tv channel
export const GET_CHANNELS = 'get-channels';
export const ADD_CHANNEL = 'add-channel';
export const DELETE_CHANNEL = 'delete-channel';
export const UPDATE_CHANNEL = 'update-channel';

//live tv genres
export const GET_TV_GENRES = 'get-tv-genres';

//Event
export const GET_EVENTS = "get-events";
export const GET_EVENT_BOOKINGS = "get-event-bookings";

export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_POST = 'ADD_POST';
export const GET_POSTS = 'GET_POSTS';
export const SINGLE_POST = 'SINGLE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const PUBLISH_POST = 'PUBLISH_POST';
export const UNPUBLISH_POST = 'UNPUBLISH_POST';
export const LOGIN_START = "LOGIN_START";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const CLEAR_AUTHENTICATION_ERROR = "CLEAR_AUTHENTICATION_ERROR";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_OTP_VERIFICATION = "FORGOT_PASSWORD_OTP_VERIFICATION";
export const RESET_PASSWORD = "RESET_PASSWORD";


//News Feed - Done
export const ADD_NEWS_FEED = "ADD_NEWS_FEED";
export const GET_NEWS_FEEDS = "GET_NEWS_FEEDS";
export const DELETE_NEWS_FEED = "DELETE_NEWS_FEED";
export const UPDATE_NEWS_FEED = "UPDATE_NEWS_FEED";


//News Category
export const GET_NEWS_CATEGORY = "GET_NEWS_CATEGORY";


//Playlists - Done
export const ADD_PLAYLIST = "ADD_PLAYLIST";
export const GET_PLAYLISTS = "GET_PLAYLISTS";
export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST";

//Colleges - Done
export const GET_COLLEGES = 'GET_COLLEGES';
export const ADD_COLLEGE = 'ADD_COLLEGE';
export const UPDATE_COLLEGE = "UPDATE_COLLEGE";
export const DELETE_COLLEGE = "DELETE_COLLEGE";

//Content Reported
export const GET_REPORTED_CONTENT = "GET_REPORTED_CONTENT";

//Marketplace Category - Done
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';

//Marketplace Subcategory - Done
export const GET_PRODUCT_SUBCATEGORIES = 'GET_PRODUCT_SUBCATEGORIES';
//Marketplace Product - Done
export const GET_PRODUCTS = 'GET_PRODUCTS';




//Business Category - Done
export const ADD_BUSINESS_CATEGORY = "ADD_BUSINESS_CATEGORY";
export const GET_BUSINESS_CATEGORIES = "GET_BUSINESS_CATEGORIES";
export const DELETE_BUSINESS_CATEGORY = "DELETE_BUSINESS_CATEGORY";
export const UPDATE_BUSINESS_CATEGORY = "UPDATE_BUSINESS_CATEGORY";


//Business Account 
export const ADD_BUSINESS_ACCOUNT = "ADD_BUSINESS_ACCOUNT";
export const GET_BUSINESS_ACCOUNTS = "GET_BUSINESS_ACCOUNTS";
export const DELETE_BUSINESS_ACCOUNT = "DELETE_BUSINESS_ACCOUNT";

export const LOGOUT_USER = "LOGOUT_USER";

//Shorts
export const GET_SHORTS = 'GET_SHORTS';




//Songs
export const ADD_SONG = "ADD_SONG";
export const GET_SONGS = "GET_SONGS";
export const DELETE_SONG = "DELETE_SONG";
// export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST";




export const UPDATE_EVENT = "UPDATE_EVENT";


//Feed Url
export const GET_FEED_URLS = "GET_FEED_URLS";

//Sports Games
export const GET_SPORT_GAMES = "GET_SPORT_GAMES";

//Sports Teams
export const GET_SPORT_TEAMS = "GET_SPORT_TEAMS";

//Sport Highlights
export const GET_SPORT_HIGHLIGHT = "GET_SPORT_HIGHLIGHT";


//Banner
export const ADD_BANNER = "ADD_BANNER";
export const GET_BANNERS = "GET_BANNERS";
export const DELETE_BANNER = "DELETE_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";





//Get Databoard Data
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";


export const API_PARAMS_VALUE = {
    admin_platform: 'admin'
}