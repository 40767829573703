import React from 'react';
class Paginate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page_no: this.props.page_no,
            total_pages: this.props.total_pages,
            page_item: [],
        }
        var i = 0;
        while (++i <= this.props.total_pages) this.state.page_item.push(i);
    }
    changeCurrentPage = (page) => {
        this.setState({ page_no: page });
        this.props.onClick(page);
    }
    render() {
        return (
            <div className='col-sm-12 col-md-7'>
                <nav className='dataTables_paginate paging_simple_numbers'>
                    <ul className="pagination pagination-split pagination pagination-rounded">
                        <li className={(this.state.page_no == 1) ? "page-item paginate_button  disabled" : "page-item paginate_button "} >
                            <span className="page-link" aria-label="Previous" onClick={() => { this.changeCurrentPage(this.state.page_no - 1) }}>
                                <span aria-hidden="true">«</span>
                                <span className="sr-only">Previous</span>
                            </span>
                        </li>
                        {
                            this.state.page_item && this.state.page_item.map((page) => {
                                return (
                                    <li key={page} className={(this.state.page_no === page) ? "page-item active paginate_button " : "page-item paginate_button "} onClick={() => { this.changeCurrentPage(page) }}>
                                        <span className="page-link">{page}</span>
                                    </li>
                                );
                            })
                        }
                        <li className={(this.state.total_pages == this.state.page_no) ? "page-item disabled paginate_button " : "page-item paginate_button "} >
                            <a className="page-link" aria-label="Next" onClick={() => { this.changeCurrentPage(this.state.page_no + 1) }}>
                                <span aria-hidden="true">»</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Paginate;