import { GET_TV_GENRES } from "../../components/constants/actionTypes";
const liveTvGenresReducer = (state = { genres: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_TV_GENRES:
            return {
                genres: action.genres.results,
                page_no: action.genres.page_no,
                total_pages: action.genres.total_pages,
            };
        default:
            return state;
    }
}
export default liveTvGenresReducer;


