import axios from "axios";
import { Component } from "react";
import { API_URI } from "../../api/index";
import { GET_POSTS, ADD_POST, UPDATE_POST, SINGLE_POST, DELETE_POST, UNPUBLISH_POST, PUBLISH_POST } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { API_PARAMS_VALUE } from "../../components/constants/actionTypes";
import { setLoading } from "./dashboardActions";
export const getPosts = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/posts/all`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery
            },
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                let posts = response.data;
                dispatch({
                    type: GET_POSTS,
                    posts
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const deletePost = (id) => {
    return (dispatch) => {
        axios({
            method: 'DELETE',
            url: `${API_URI}/posts/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getPosts(1, ""));
                // dispatch({
                //     type: DELETE_POST,
                //     id
                // })
            }
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const publishedUnpublishedPost = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PATCH',
            url: `${API_URI}/posts/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getPosts(1, ""));
                // if (response.data.status_code == 204) {
                //     dispatch({
                //         type: UNPUBLISH_POST,
                //         id
                //     })
                // }
                // else if (response.data.status_code == 200) {
                //     dispatch({
                //         type: UNPUBLISH_POST,
                //         id
                //     })
                // }
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {

            dispatch(setLoading(false));
        })
    }
}