import axios from "axios";
import { CONTENT_CATEGORIES, CONTENT_GENRES, CONTENT_LANGUAGES, GET_MEDIA_CONTENT, GET_SINGLE_MEDIA_CONTENT } from "../../components/constants/actionTypes";
import { API_URI } from "../../api";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setLoading } from "./dashboardActions";
import { setMessage } from "./appNotificationMessageActions";
export const getMediaContent = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/media-content/all-content`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                const media_content = response.data;
                dispatch({
                    type: GET_MEDIA_CONTENT,
                    media_content
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const getGenre = (type) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/media-content/genre`,
            data: {
                page_number: 1,
                document_limit: 20,
                query: '',
                type: type,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status_code === 200) {
                let responseData = response.data;
                let genresData = responseData.results;
                let genres = [];
                genresData.map((genre) => {
                    genres.push({ value: genre._id, label: genre.name }); //Create genres for select box;
                });
                dispatch({
                    type: CONTENT_GENRES,
                    genres
                });
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const getCategories = (type) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/media-content/genre`,
            data: {
                page_number: 1,
                document_limit: 20,
                query: '',
                type: type,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status_code === 200) {
                let responseData = response.data;
                let genresData = responseData.results;
                let genres = [];
                genresData.map((genre) => {
                    genres.push({ value: genre._id, label: genre.name }); //Create genres for select box;
                });
                dispatch({
                    type: CONTENT_CATEGORIES,
                    genres
                });
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const getLanguages = () => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'GET',
            url: `${window.location.origin}/assets/data/langcode.json`,
            responseData: 'json',
        }).then((response) => {
            if (response.status === 200) {
                let languages = response.data;//Set languages data form langcode json
                dispatch({
                    type: CONTENT_LANGUAGES,
                    languages
                });
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const getSingleMediaContent = (id, type) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'GET',
            url: `${API_URI}/hbcu-tv/get-content-details/${type}/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                let single_content = responseData.results[0];
                dispatch({
                    type: GET_SINGLE_MEDIA_CONTENT,
                    single_content
                });
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const updateSingleMediaContentu = (formData, id, type) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/media-content/edit/${type}/${id}`,
            data: formData,
            headers: multipartFormData(),
        }).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, true));
                dispatch(getMediaContent(1, ''));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}
