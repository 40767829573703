


import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import PageHeader from "../../../layouts/PageHeader";
import Footer from "../../../layouts/Footer";
import RightSidebar from "../../../layouts/RightSidebar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteEvent, getEventBookings } from "../../../../store/actions/eventActions";
import { Link, useNavigate } from "react-router-dom";
import { getEvents } from "../../../../store/actions/eventActions";
import swal from "sweetalert";
import { noAvator, noThumbnail, SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import Paginate from "../../../pagination/Paginate";

function Booking() {
    SetDocumentTitle(`Event bookings \u2022 ${APP_NAME}`);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const { event_bookings, total_pages, page_no } = useSelector((state) => state.events.eventsBookingsData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEventBookings(1, ''));
    }, [dispatch])

    const handleDelete = (id) => {
        // swal({
        //     title: "",
        //     text: "Are you sure? Once deleted, you will not be able to recover this!",
        //     // icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        //     buttons: ["Cancel", "Delete"],
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         dispatch(deleteEvent(id));
        //     }
        // });
    }
    const search = () => {
        dispatch(getEventBookings(1, searchInput));
    }

    const handlePageClick = (current_page) => {
        dispatch(getEventBookings(current_page, searchInput));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Event Bookings</h4>
                                        <p className="sub-header">
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">

                                                    {/* <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Title" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>

                                                        <th>Name/Email</th>
                                                        <th>Event Name</th>
                                                        <th>Event date</th>
                                                        <th>Tickets</th>
                                                        <th>Transaction Reference</th>
                                                        <th>Sub Total</th>
                                                        <th>Total Amount</th>
                                                        <th>Booked By</th>
                                                        <th>Created At</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {event_bookings && event_bookings.map((events_booking) => {
                                                        return (
                                                            <tr key={events_booking._id}>
                                                                <td>
                                                                    <h5>{events_booking?.email}</h5>
                                                                    <small>{events_booking?.full_name}</small>
                                                                </td>
                                                                <td><b>{events_booking?.event?.[0]}</b></td>
                                                                <td>{events_booking?.event_date}</td>
                                                                <td>{events_booking?.tickets}</td>
                                                                <td>{events_booking?.transaction_id}</td>
                                                                <td>{(events_booking?.amount?.sub_total) ? `$ ${events_booking?.amount?.sub_total}` : 'n/a'}</td>
                                                                <td>{(events_booking?.amount?.total_amount) ? `$ ${events_booking?.amount?.total_amount}` : 'n/a'} </td>
                                                                <td>
                                                                    {
                                                                        (events_booking?.created_by?.[0]?.name) ?
                                                                            <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" class="rounded-circle avatar-sm"></img>
                                                                            : noAvator()
                                                                    }
                                                                    <span className="ml-2">
                                                                        {events_booking?.created_by?.[0]?.name}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {events_booking?.createdAt?.split('T')[0]}
                                                                    <br />
                                                                    {events_booking?.createdAt?.split('T')[1]?.split('.')[0]}
                                                                </td>
                                                                <td>
                                                                    {/* <Link to={`/events_bookings/view/${events_booking._id}`} className="table-action-btn" title="View">
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>
                                                                    <Link to={`/events_bookings/${events_booking._id}`} className="table-action-btn" title="Edit">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="Delete" onClick={() => handleDelete(events_booking._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span> */}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div className="table-responsive">
                                                {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Booking;