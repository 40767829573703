import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addProductCategory, updateProductCategory } from "../../../../store/actions/marketplaceActions";
function ProductCategory() {
    const [formInput, setFormInput] = useState({
        name: "",
        image: "",
        is_published: true,
    });
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const categories = useSelector((state) => state.product_categories.categories);
    useEffect(() => {
        if (id) {
            setEditMode(true);
            const singleCategory = categories.find(category => category._id === id);
            setFormInput({
                name: singleCategory?.name,
                is_published: singleCategory?.is_published,
            });
        }
    }, [dispatch, id]);



    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('image', formInput.image);
        formData.append('is_published', formInput.is_published);
        console.log(formInput);
        if (!editMode) {
            dispatch(addProductCategory(formData));
            navigator("/marketplace/category");
        } else {

            dispatch(updateProductCategory(id, formData));
            navigator("/marketplace/category");
        }
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Category" : "Edit Category"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.all_users.count} Users Found */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">

                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    {editMode ?
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Status</label>
                                                            <div className="col-md-2">
                                                                <div className="checkbox checkbox-primary">
                                                                    <input id="is_published" type="checkbox" checked={formInput.is_published} onClick={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                    <label htmlFor="is_published">
                                                                        {(formInput.is_published) ? "Active" : "InActive"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Image</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, image: e.target.files[0] })} required={editMode ? false : true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default ProductCategory;