import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { forgotPasswordRequest } from "../../store/actions/authActions";
import { APP_NAME } from "../constants/uiTypes";
import { SetDocumentTitle } from "../helper/functions";
function ForgotPassword() {
    SetDocumentTitle(`Forgot password? \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        email: "",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { is_otp_send } = useSelector((state) => state.forgot_password);
    console.log(is_otp_send);
    useEffect(() => {
        if (is_otp_send === true) {
            navigate(`/forgot-password/otp-verification`);
        }
    }, [is_otp_send]);
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(forgotPasswordRequest(formInput));
    }
    return (
        <>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="fas fa-home h2"></i>
                </Link>
            </div>
            <div className="account-pages w-100 mt-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card mb-0">
                                <div className="card-body p-4">
                                    <div className="account-box">
                                        <div className="text-center account-logo-box">
                                            <div>
                                                <Link to="/">
                                                    <img src="logo101.png" alt="The HBCU Logo" height="80" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="account-content mt-4">
                                            <div className="text-center">
                                                <p className="text-muted mb-0 mb-3">Enter your email address and we'll send you an email with instructions to reset your password.  </p>
                                            </div>
                                            <form className="form-horizontal" onSubmit={handleSubmit}>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="email_address">Email address</label>
                                                        <input className="form-control" type="email" id="email_address" placeholder="john@deo.com" required={true} onChange={(e) => setFormInput({ ...formInput, email: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group row text-center mt-2">
                                                    <div className="col-12">
                                                        <button className="btn btn-md btn-block btn-primary waves-effect waves-light" type="submit">Send</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="clearfix"></div>
                                            <div className="row mt-4">
                                                <div className="col-sm-12 text-center">
                                                    <p className="text-muted mb-0">Back to
                                                        <Link to="/login" className="text-dark ml-1">
                                                            <b>Sign In</b>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;