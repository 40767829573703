import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { forgotPasswordOTPVerification, resetPassword } from "../../store/actions/authActions";
import { APP_NAME } from "../constants/uiTypes";
import { SetDocumentTitle } from "../helper/functions";
function OTPVerification() {
    SetDocumentTitle(`Email OTP Verification \u2022 ${APP_NAME}`);
    const { is_otp_send, user, is_otp_verified, auth_token, password_reset_done } = useSelector((state) => state.forgot_password);
    const { tab } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formInput, setFormInput] = useState({
        id: "",
        email: "",
        otp: "",
        message: "",
        btn_text: "",
        password: ""
    });
    useEffect(() => {
        if (!is_otp_send) {
            navigate('/forgot-password');
        }
        if (is_otp_verified) {
            navigate('/forgot-password/reset-password');
        }
        if (password_reset_done) {
            navigate('/login');
        }
        if (tab) {
            switch (tab) {
                case 'otp-verification':
                    setFormInput({
                        id: user?._id,
                        email: user?.email,
                        message: `<p class="text-muted mb-0 mb-1">
                        We've emailed on <span class="h4">${user?.email}</span>  instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.
                    </p>
                    <p class="text-muted mb-0 mb-3">
                        If you don’t receive an email, please make sure you’ve entered the address you registered with, and check your spam folder
                    </p>`,
                        btn_text: "Verfiy OTP"

                    });
                    break;
                case 'reset-password':
                    setFormInput({
                        id: user?._id,
                        email: user?.email,
                        message: `<p class="text-muted mb-0 mb-1">
                                   Change your password.
                                 </p>`,
                        btn_text: "Change Password",
                    });
                    break;
                default:


            }
        }
    }, [is_otp_verified, password_reset_done, tab]);

    const handleSubmit = (event) => {
        event.preventDefault();
        switch (tab) {
            case 'otp-verification':
                dispatch(forgotPasswordOTPVerification({
                    otp: formInput.otp,
                    id: formInput.id,
                }));
                break;
            case 'reset-password':
                dispatch(resetPassword(
                    {
                        id: formInput.id,
                        password: formInput.password,
                    },
                    {
                        'X-Auth-Token': auth_token,
                    }
                ));
                break;
            default:
        }
    }
    return (
        <>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="fas fa-home h2"></i>
                </Link>
            </div>
            <div className="account-pages w-100 mt-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card mb-0">
                                <div className="card-body p-4">
                                    <div className="account-box">
                                        <div className="text-center account-logo-box">
                                            <div>
                                                <Link to="/">
                                                    <img src="logo101.png" alt="The HBCU Logo" height="80" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="account-content mt-4">
                                            <div className="text-center">
                                                <div dangerouslySetInnerHTML={{ __html: formInput.message }}>
                                                </div>
                                            </div>
                                            <form className="form-horizontal" onSubmit={handleSubmit}>
                                                <div className="form-group row">
                                                    {
                                                        (tab !== undefined && tab === "otp-verification") ? <div className="col-12">
                                                            <label htmlFor="otp">OTP</label>
                                                            <input className="form-control" type="number" id="otp" required={true} placeholder="Enter OTP" onChange={(e) => setFormInput({ ...formInput, otp: e.target.value })} />
                                                        </div> : null


                                                    }
                                                    {
                                                        (tab && tab === "reset-password") ? <div className="col-12">
                                                            <label htmlFor="password">Password</label>
                                                            <input className="form-control" type="password" id="password" required={true} placeholder="Enter your new password" onChange={(e) => setFormInput({ ...formInput, password: e.target.value })} />
                                                        </div> : null
                                                    }

                                                </div>
                                                <div className="form-group row text-center mt-2">
                                                    <div className="col-12">
                                                        <button className="btn btn-md btn-block btn-primary waves-effect waves-light" type="submit">
                                                            {formInput.btn_text}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="clearfix"></div>
                                            <div className="row mt-4">
                                                <div className="col-sm-12 text-center">
                                                    <p className="text-muted mb-0">Back to
                                                        <Link to="/forgot-password" className="text-dark ml-1">
                                                            <b>Forgot password</b>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OTPVerification;