


import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { addSong, updateSong } from "../../../store/actions/shortsActions";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPlaylists } from "../../../store/actions/shortsActions";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function Song() {
    SetDocumentTitle(`Songs Actions \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        artist: "",
        thumbnail: "",
        playlist_id: "",
        song_url: "",
    })

    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const playlists = useSelector((state) => state.playlists);
    const songs = useSelector((state) => state.songs);
    const { id } = useParams();
    useEffect(() => {
        dispatch(getPlaylists(1));
        if (id) {
            setEditMode(true);
            const singleSong = songs.find(song => song._id == id);
            setFormInput({
                name: singleSong?.name,
                artist: singleSong?.artist,
                playlist_id: singleSong?.playlist?.[0]?._id,
                thumbnail: "",
                song_url: "",
            })
        }
    }, [dispatch, id])//Load when something dispatched...


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('artist', formInput.artist);
        formData.append('thumbnail', formInput.thumbnail);
        formData.append('playlist_id', formInput.playlist_id);
        formData.append('song', formInput.song_url);
        if (!editMode) {//Create New Post 
            dispatch(addSong(formData));
            navigate('/songs');
        }
        else {
            dispatch(updateSong(id, formData));
            navigate('/songs');
        }
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Song" : "Edit Song"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.length} Users Fetched */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Name</label>
                                                            <div className="col-md-10">
                                                                <input type="text" required className="form-control" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} />
                                                                {/* onChange={(e) => setPost({ ...post, post_title: e.target.value })} */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Artist</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" value={formInput.artist} onChange={(e) => setFormInput({ ...formInput, artist: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Select Playlist</label>
                                                            <div className="col-md-10">
                                                                <select class="form-control" value={formInput.playlist_id} onChange={(e) => setFormInput({ ...formInput, playlist_id: e.target.value })}>
                                                                    <option value="">Please choose</option>
                                                                    {playlists && playlists.map((playlist) => {
                                                                        return (
                                                                            <option value={playlist._id}>{playlist.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Image</label>
                                                            <div className="col-md-10">
                                                                <input type="file" required={!editMode ? true : false} accept="image/png, image/jpg, image/png" style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, thumbnail: e.target.files[0] })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Song</label>
                                                            <div className="col-md-10">
                                                                <input type="file" accept="image/mp3" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, song_url: e.target.files[0] })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button type="submit" className="btn btn-primary mb-2">
                                                                {!editMode ? "Submit" : "Update"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Song;