import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import { addGenre, updateGenre } from "../../../../store/actions/genreAndCategoryActions";
function Genre() {
    SetDocumentTitle(`Genre & Category \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        type: "",
        image: "",
        is_published: false,
    });
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const { genres } = useSelector((state) => state.hbcuGenreAndCategory);
    useEffect(() => {
        if (id) {
            setEditMode(true);
            const singleGenres = genres.find(genre => genre._id === id);
            setFormInput({
                name: singleGenres?.name,
                image: "",
                is_published: singleGenres?.is_published,
                type: singleGenres?.type,
            });
        }
    }, [dispatch, id]);
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('type', formInput.type);
        formData.append('image', formInput.image);
        formData.append('is_published', formInput.is_published);
        if (!editMode) {
            dispatch(addGenre(formData));
            navigator("/genre-and-category");
        } else {
            dispatch(updateGenre(id, formData));
            navigator("/genre-and-category");
        }
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Genre & Category" : "Edit Genre & Category"}
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">

                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >
                                                            Name
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">
                                                            Type
                                                        </label>
                                                        <div className="col-md-4">
                                                            <select className="form-control" value={formInput.type} onChange={(e) => setFormInput({ ...formInput, type: e.target.value })} required={true}>
                                                                <option value="">Please choose...</option>
                                                                <option value="genre-wise" >Genre</option>
                                                                <option value="category-wise" >Category</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {!editMode ? null
                                                        : <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Status</label>
                                                            <div className="col-md-2">
                                                                <div className="checkbox checkbox-primary">
                                                                    <input id="is_published" type="checkbox" onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} checked={formInput.is_published} />
                                                                    <label htmlFor="is_published">
                                                                        {(formInput.is_published) ? "Active" : "Inactive"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Image</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={formInput.type === 'genre-wise' ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, image: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )


}
export default Genre;