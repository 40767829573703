

import { GET_PUBLIC_GENRES, GET_PUBLIC_LANGUAGES, PUBLIC_REDIRECT, GET_MOVIE_META, LOCATION_RELOAD, RESET_PUBLIC_REDIRECT } from "../../components/constants/actionTypes"
import axios from "axios";
import { API_URI } from "../../api/index";
import { setLoading } from "./dashboardActions";
import { setMessage } from "./appNotificationMessageActions";
export const getGenre = (type, token) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/hbcu-tv/media-content/genre`,
                data: {
                    page_number: 1,
                    document_limit: 20,
                    query: '',
                    type: type,
                },
                headers: {
                    'X-Auth-Token': token,
                }
            }).then((response) => {
                if (response.data.status_code === 200) {
                    let responseData = response.data;
                    let genresData = responseData.results;
                    let genres = [];
                    genresData.map((genre) => {
                        genres.push({ value: genre._id, label: genre.name }); //Create genres for select box;
                    });
                    dispatch({
                        type: GET_PUBLIC_GENRES,
                        genres
                    });
                } else {
                    let responseData = response.data;
                    dispatch(setMessage(responseData.status_code, responseData.message, false));
                }
            }).catch((error) => {
                dispatch(setMessage(200, error.toString(), false));
            }).finally(() => {
                dispatch(setLoading(false));
            });
    }
}
export const getLanguages = () => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'GET',
            url: `${window.location.origin}/assets/data/langcode.json`,
            responseData: 'json',
        }).then((response) => {
            if (response.status === 200) {
                let languages = response.data;//Set languages data form langcode json
                dispatch({
                    type: GET_PUBLIC_LANGUAGES,
                    languages
                });
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const setRedirect = (redirect_to) => {
    return (dispatch) => {
        dispatch({
            type: PUBLIC_REDIRECT,
            redirect_to
        });
    }
}

export const resetRedirect = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_PUBLIC_REDIRECT,
        });
    }
}

export const getMovieMeta = (type, id, token) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'GET',
                url: `${API_URI}/hbcu-tv/get-content-details/${type}/${id}`,
                headers: {
                    'X-Auth-Token': token,
                },
            }
        ).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                let movie = responseData.results[0];
                dispatch({
                    type: GET_MOVIE_META,
                    movie
                });
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const uploadMovieMeta = (formData, token) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/media-content/upload-meta`,
            data: formData,
            headers: {
                'X-Auth-Token': token,
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                let media = responseData.results[0];
                let redirect_to = `/public/uploader/media/${media.type}/${media._id}`;
                dispatch(setRedirect(redirect_to));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const uploadSeriesMeta = (formData, token) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/hbcu-tv/media-content/upload-series`,
                data: formData,
                headers: {
                    'X-Auth-Token': token,
                },
            }
        ).then((response) => {
            if (response.data.status) {
                window.location.reload();
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(200, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}












