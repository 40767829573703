import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePost } from "../../../store/actions/postActions";
import swal from "sweetalert";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Paginate from "../../pagination/Paginate";
import { getShorts, publishedUnpublishedShorts } from "../../../store/actions/shortsActions";
function Shorts() {

    const [searchInput, setSearchInput] = useState("");
    const { shorts, page_no, total_pages } = useSelector((state) => state.shorts);
    const dashboardData = useSelector((state) => state.dashboard_data);
    console.log(shorts);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getShorts(1, searchInput));
    }, [dispatch])

    // const addNewPost = () => {
    //     navigate('/posts/create')
    // }
    const shortsSearch = () => {
        dispatch(getShorts(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
              //  dispatch(deletePost(id))
            }
        });
    }
    const enableDisableShorts = (id, action) => {
        const button_text = action ? "Unpublish" : "Publish";
        swal({
            title: "",
            text: "Are you sure?",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", button_text],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(publishedUnpublishedShorts(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getShorts(current_page, searchInput));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Shorts</h4>
                                        <p className="sub-header">
                                            {dashboardData.shorts} Shorts.
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Post Content" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={shortsSearch}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table id="datatable-buttons" class="table table-striped dt-responsive nowrap table-actions-bar">
                                            <thead>
                                                <tr>
                                                    <th>Content</th>
                                                    <th>Posted By</th>
                                                    <th>Published</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    shorts && shorts.map((short) => {
                                                        let sliders = [];
                                                        let multimedia_block;
                                                        multimedia_block = (
                                                            <AwesomeSlider animation="fall-animation" bullets={false} buttons={false}>
                                                                <div data-src={(short.thumbnail != "") ? short.thumbnail : "assets/images/no-thumbnail.jpg"} />
                                                            </AwesomeSlider>);
                                                        return (<tr key={short._id}>
                                                            <td style={{ "width": "50%" }}>
                                                                <div class="media mb-1">
                                                                    <div className="col-3">
                                                                        {multimedia_block}
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h4 class="header-title">{short.title}</h4>
                                                                        <p class="sub-header">
                                                                            {short.description}
                                                                        </p>
                                                                        <div id="round" class="rating-star" >
                                                                            <i class="fas fa-heart text-primary" title="Like" ></i>
                                                                            &nbsp;
                                                                            <span>{short.like_count}</span>
                                                                            &nbsp;
                                                                            &nbsp;
                                                                            <i class="fas fa-comment text-green" title="Comments" ></i>
                                                                            &nbsp;
                                                                            <span>{short.comment_count}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ "width": "15%" }}>
                                                                {
                                                                    short?.posted_by?.profile_pic === '-' || short?.posted_by?.profile_pic == undefined || short?.posted_by?.profile_pic == "" ?
                                                                        <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" className="rounded-circle avatar-sm border" /> :
                                                                        <img src={short?.posted_by?.profile_pic} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm border" />
                                                                }
                                                                <span class="ml-1">
                                                                    <b>{short?.posted_by?.name}</b>
                                                                </span>
                                                            </td>
                                                            <td>{short.createdAt?.split("T")[0]}</td>
                                                            <td>
                                                                {(short.is_published) ? <span><i className="fas fa-eye text-primary"></i> Published</span> : <span><i className="fas fa-eye-slash"></i> Hidden</span>}
                                                            </td>
                                                            <td>
                                                                {/* <a class="table-action-btn" title="View User" href="/users/view/62badea1eef1f6e4e09c9c85">
                                                                    <i class="mdi mdi-eye"></i>
                                                                </a> */}
                                                                <span class="table-action-btn" title="Change Status" onClick={() => enableDisableShorts(short._id, short.is_published)} >
                                                                    <i class="mdi mdi-pencil"></i>
                                                                </span>
                                                                <span class="table-action-btn" title="Shorts Delete" onClick={() => handleDelete(short._id)} >
                                                                    <i class="mdi mdi-close"></i>
                                                                </span>
                                                            </td>
                                                        </tr>);
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                        <div className="table-responsive">

                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Shorts;