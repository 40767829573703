import axios from "axios";
import { API_URI } from "../../api";
import { GET_SPORT_GAMES, GET_SPORT_HIGHLIGHT, GET_SPORT_TEAMS } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setLoading } from "./dashboardActions";
export const getSportGames = (page_number, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/sports/list-of-sports`,
            data: {
                'page_number': page_number,
                'query': searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let games = response.data;
                dispatch({
                    type: GET_SPORT_GAMES,
                    games
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            console.log("SONG LOADING FALSE")
            dispatch(setLoading(false));
        })
    }
}

export const deleteGame = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/sports/list-of-sports/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getSportGames(1));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export const addGame = (game) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/sports/list-of-sports/create`,
                data: game,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let game = response.data.results[0];
                dispatch(getSportGames(1));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        });
    }
}

export const updateGame = (game, id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'PUT',
                url: `${API_URI}/sports/list-of-sports/${id}`,
                data: game,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let game = response.data.results[0];
                dispatch(getSportGames(1));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        });
    }
}


export const getTeams = (page_number, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/sports/teams`,
            data: {
                'page_number': page_number,
                'query': searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let teams = response.data;
                dispatch({
                    type: GET_SPORT_TEAMS,
                    teams
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            console.log("SONG LOADING FALSE")
            dispatch(setLoading(false));
        })
    }
}

export const addTeam = (team) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/sports/teams/create`,
                data: team,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let team = response.data.results[0];
                console.log(team);
                dispatch(getTeams(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        });
    }
}

export const deleteTeam = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/sports/teams/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getTeams(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const updateTeam = (team, id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'PUT',
                url: `${API_URI}/sports/teams/${id}`,
                data: team,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let team = response.data.results[0];
                console.log(team);
                // dispatch(getSportGames(1));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        });
    }
}

export const addHighlight = (highlight) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/sports/sport-highlights/create`,
                data: highlight,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let highlight = response.data.results[0];
                dispatch(getHighlight(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        });
    }
}


export const getHighlight = (page_number, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/sports/sport-highlights`,
            data: {
                'page_number': page_number,
                'query': searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let highlight = response.data;
                dispatch({
                    type: GET_SPORT_HIGHLIGHT,
                    highlight
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            console.log("SONG LOADING FALSE")
            dispatch(setLoading(false));
        })
    }
}


export const deleteHighlight = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/sports/sport-highlights/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getHighlight(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}