

import { PUBLIC_LOGIN_SUCCESS, PUBLIC_LOGOUT } from "../../components/constants/actionTypes"
import axios from "axios";
import { API_URI } from "../../api/index";
import { setLoading } from "./dashboardActions";
import { setMessage } from "./appNotificationMessageActions";
export const loginStart = (loginCredentials) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/login`,
            data: loginCredentials,
        }).then((response) => {
            if (response.data.status) {
                let token = response.headers['x-auth-token']
                let responseData = response.data;
                let user = responseData.results[0];
                dispatch({
                    type: PUBLIC_LOGIN_SUCCESS,
                    user,
                    token,
                })
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const logOut = () => {
    return (dispatch) => {
        dispatch({
            type: PUBLIC_LOGOUT,
        });
        dispatch(setMessage(200, 'Logged Out.', true));
    }
}











