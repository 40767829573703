import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import swal from "sweetalert";
import { deleteGame, getSportGames } from "../../../../store/actions/sportsActions";
function SportGames() {
    SetDocumentTitle(`Sport Games \u2022 ${APP_NAME}`);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { games, page_no, total_pages } = useSelector((state) => state.list_of_sports);
    useEffect(() => {
        dispatch(getSportGames(1, ""));
    }, [dispatch])//Load when something dispatched...

    const addNew = () => {
        navigate('/sports-games/create')
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteGame(id))
            }
        });
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Sport Games</h4>
                                        <p className="sub-header">
                                            {/* {songs.length} Sport Fetched */}
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNew}><i className="mdi mdi-plus-circle mr-2"></i> Add New</button>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <input id="demo-input-search2" type="text" placeholder="Search" className="form-control" autocomplete="off" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover m-0 table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Image
                                                        </th>
                                                        <th>Name</th>
                                                        <th>Icon</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {games && games.map((game) => {
                                                        return (
                                                            <tr key={game._id}>
                                                                <td>
                                                                    {
                                                                        (game.image !== "" && game.image !== undefined) ?
                                                                            <img src={game.image} alt="image" className="img-fluid" width={"80px"} />
                                                                            :
                                                                            <img src="/assets/images/no-thumbnail.jpg" alt="image" className="img-fluid" width={"80px"} />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <h5 className="m-0 font-weight-medium">{game.name}</h5>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (game.icon !== "" && game.icon !== undefined) ?
                                                                            <img src={game.icon} alt="image" className="img-fluid" width={"80px"} />
                                                                            :
                                                                            <img src="/assets/images/no-thumbnail.jpg" alt="image" className="img-fluid" width={"80px"} />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(game.is_published) ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <Link to={`/sports-games/${game._id}`} className="table-action-btn">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span onClick={() => handleDelete(game._id)} style={{ cursor: "pointer" }} className="table-action-btn">
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default SportGames;