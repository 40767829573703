import { useEffect, useState } from "react";
import { APP_NAME } from "../../constants/uiTypes";
import { SetDocumentTitle } from "../../helper/functions";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { API_URI } from "../../../api";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/dashboardActions";
import axios from "axios";
import { loginStart } from "../../../store/actions/publicAuthActions";

const animatedComponents = makeAnimated();

function PublicLogin(props) {
    const dispatch = useDispatch();
    const [formInput, setFormInput] = useState({ //UI input state
        login_type: "email",
        email: "",
        password: "",
    });
    const [formError, setInputError] = useState({
        email: "",
        password: ""
    });
    const login = () => {
        if (formInput.email == "") {
            setInputError({ ...formError, email: "Email is required." })
        }
        else if (formInput.password == "") {
            setInputError({ ...formError, password: "Password is required." })
        }
        else {
            setInputError({ ...formError, email: "", password: "" })
            dispatch(loginStart(formInput));
        }
    }
    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-lg mb-0">
                            <div className="card-body p-4">
                                <div className="account-box">
                                    <div className="account-logo-box">
                                        <div className="text-center">
                                        </div>
                                        <h5 className="text-uppercase mb-1 mt-4">Login In</h5>
                                        <p className="mb-0">Login to your account</p>
                                    </div>
                                    <div className="account-content mt-4">
                                        <form className="form-horizontal" >
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <label htmlFor="emailaddress">Email address</label>
                                                    <input className="form-control" type="email" required="" placeholder="john@deo.com" onChange={(e) => setFormInput({ ...formInput, email: e.target.value })} />
                                                    {formError.email ? <div className="invalid-feedback d-block">{formError.email}</div> : null}

                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <label htmlFor="password">Password</label>
                                                    <input className="form-control" type="password" required="" id="password" placeholder="Enter your password" onChange={(e) => setFormInput({ ...formInput, password: e.target.value })} />
                                                    {formError.password ? <div className="invalid-feedback d-block">{formError.password}</div> : null}
                                                </div>
                                            </div>
                                            <div className="form-group row text-center mt-2">
                                                <div className="col-12">
                                                    <button className="btn btn-md btn-block btn-primary waves-effect waves-light" type="button" onClick={login}>Sign In</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PublicLogin;