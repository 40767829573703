import { combineReducers } from "redux";
import postReducer from "./postReducer";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist'
import collegeReducer from "./collegeReducer";
import { shortsReducer } from "./shortsReducer";
import { playlistReducer } from "./shortsReducer";
import { songReducer } from "./shortsReducer";
import { newsFeedReducer } from "./newsFeedReducer";
import { sidebarReducer, loaderReducer, dashboardReducer, reportedContentReducer } from "./dashboardReducer";
import eventReducer from "./eventReducer";
import { businessAccountReducer, businessCategoryReduceer } from "./businessReducer";
import { appBannerReducer } from "./appBannerReducer";
import newsCategoryReducer from "./newsCategoryReducer";
import { productCategoriesReducer, productReducer, productSubcategoriesReducer } from "./marketplaceReducer";
import feedUrlsReducer from "./feedUrlsReducer";
import { listOfSportsReducer, sportHighlightsReducer, teamsReducer } from "./sportsReducer";
import { forgotPasswordReducer } from "./forgotPasswordReducer";
import { appNotificationMessageReducer } from "./appNotificationMessageReducer";
import mediaContentReducer from "./mediaContentReducer";
import { genreAndCategoryReducer } from "./genreAndCategoryReducer";
import publicAuthReducer from "./publicAuthReducer";
import uploaderReducer from "./uploaderReducer";
import liveTvReducer from "./liveTvReducer";
import liveTvGenresReducer from "./liveTvGenresReducer";
const rootReducer = combineReducers({
    //Final ***State
    loader: loaderReducer,
    sidebar: sidebarReducer,
    appNotificationMessage: appNotificationMessageReducer,
    mediaContent: mediaContentReducer,
    hbcuGenreAndCategory: genreAndCategoryReducer,
    public_auth: publicAuthReducer,
    uploader: uploaderReducer,
    liveTv: liveTvReducer,
    tv: liveTvGenresReducer,
    //End Final ***State





    auth: authReducer,
    forgot_password: forgotPasswordReducer,

    posts: postReducer,
    users: userReducer,
    colleges: collegeReducer,
    playlists: playlistReducer,
    shorts: shortsReducer,
    songs: songReducer,
    news_feeds: newsFeedReducer,
    news_categories: newsCategoryReducer,
    product_categories: productCategoriesReducer,
    products: productReducer,
    events: eventReducer,
    business_accounts: businessAccountReducer,
    business_categories: businessCategoryReduceer,
    business_subcategories: productSubcategoriesReducer,
    banners: appBannerReducer,
    dashboard_data: dashboardReducer,
    reported_content: reportedContentReducer,
    feed_urls: feedUrlsReducer,
    list_of_sports: listOfSportsReducer,
    sport_teams: teamsReducer,
    highlights: sportHighlightsReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["sidebar", "auth", "public_auth"],//Store only public and auth state.
}
export default persistReducer(persistConfig, rootReducer);

