import { GET_NEWS_CATEGORY } from "../../components/constants/actionTypes";

const newsCategoryReducer = (state = [], action) => {
    switch (action.type) {
        case GET_NEWS_CATEGORY:
            return action.news_categories;
        default:
            return state;
    }
}

export default newsCategoryReducer;