import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
import { getSingleMediaContent } from "../../../store/actions/mediaContentActions";

function SingleMediaContent() {
    SetDocumentTitle(`Single Content \u2022 ${APP_NAME}`);
    const dispatch = useDispatch();
    const { id, type } = useParams();
    const { single_content } = useSelector((state) => state.mediaContent);
    const [formInput, setFormInput] = useState({
        _id: "",
        thumbnail: "",
        cover_image: "",
        overview: "",
        genres: [],
        languages: [],
        starring: [],
        director: "",
        rating: "",
        duration: "",
        maturity_rating: "",
        released_date: "",
        year: 2020,
        type: "",
        name: "",
        media: "",
        series: []
    });
    useEffect(() => {
        if (id && type) {
            if (formInput?._id != id) {
                dispatch(getSingleMediaContent(id, type));
            }
            setFormInput({
                ...formInput,
                _id: single_content?._id,
                name: single_content?.name,
                overview: single_content?.overview,
                director: single_content?.director,
                rating: single_content?.rating,
                duration: single_content?.duration,
                cover_image: single_content?.cover_image,
                thumbnail: single_content?.thumbnail,
                maturity_rating: single_content?.maturity_rating,
                released_date: single_content?.released_date,
                released_year: single_content?.released_date,
                type: single_content?.type,
                languages: single_content?.languages,
                starring: single_content?.starring,
                media: single_content?.media,
                // genres: single_content?.genres,
                is_published: single_content?.is_published,
                in_trending: single_content?.in_trending,
                is_featured: single_content?.is_featured,
            });
        }
    }, [id, type, single_content]);
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card mb-3 border" >
                                                    <div className="row g-0">
                                                        <div className="col-md-4">
                                                            <img src={formInput?.cover_image} className="rounded-start" alt="..." height={'100%'} width={'100%'} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="card-body">
                                                                <h4 className="card-title">{formInput?.name}</h4>
                                                                <p>
                                                                    <span className="pr-1">{formInput?.year}</span>
                                                                    <span className="pr-1">&bull;</span>
                                                                    <span className="pr-1"><span>{formInput?.maturity_rating}</span></span>
                                                                    <span className="pr-1">&bull;</span>
                                                                    <span className="pr-1">{formInput?.rating}/10</span>
                                                                    <span className="pr-1">&bull;</span>
                                                                    <span className="pr-1">{formInput?.duration}</span>
                                                                </p>
                                                                <p className="card-text">{formInput?.overview}</p>
                                                                <p className="card-text">
                                                                    <b>Director : </b>
                                                                    <small className="text-muted">
                                                                        {formInput?.director}
                                                                    </small>
                                                                </p>
                                                                <p className="card-text">
                                                                    <b>Stars : </b>
                                                                    <small className="text-muted">
                                                                        {formInput?.starring}
                                                                    </small>
                                                                </p>
                                                                <p className="card-text">
                                                                    <b>Languages : </b>
                                                                    <small className="text-muted">
                                                                        {formInput?.languages}
                                                                    </small>
                                                                </p>
                                                                <p className="card-text">
                                                                    <b>Genres : </b>
                                                                    <small className="text-muted">
                                                                        {formInput?.genres}
                                                                    </small>
                                                                </p>
                                                                <p className="card-text">
                                                                    <b>Status : </b>
                                                                    <small className="text-muted">
                                                                        {formInput?.is_published ? "Published" : "Not published"}
                                                                    </small>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default SingleMediaContent;