import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCollege, getColleges, updateCollege } from "../../../../store/actions/collegeActions";
import { useParams } from "react-router-dom";
import { addBusinessCategory, getBusinessCategories, updateBusinessCategory } from "../../../../store/actions/businessActions";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
function BusinessCategory() {
    SetDocumentTitle(`Business Category Action \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        is_published: true,
        icon: "",
    });
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();
    const navigator = useNavigate();

    const { id } = useParams();
    const categories = useSelector((state) => state.business_categories);
    console.log(categories);
    useEffect(() => {
        dispatch(getBusinessCategories());
        if (id) {
            setEditMode(true);
            const singleCategory = categories.find(category => category._id === id);
            console.log(singleCategory);
            setFormInput({
                name: singleCategory?.name,
                is_published: singleCategory?.is_published,
            });
        }
    }, [dispatch, id])
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('icon', formInput.icon);
        formData.append('is_published', formInput.is_published)
        if (!editMode) {
            dispatch(addBusinessCategory(formData));
            navigator("/business/category");
        } else {
            dispatch(updateBusinessCategory(id, formData));
            navigator("/business/category");
        }
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Business Category" : "Edit Business Category"}
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">
                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-2 col-form-label">Status</label>
                                                        <div className="col-4">
                                                            <div className="checkbox checkbox-primary pl-1">
                                                                <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                <label htmlFor="is_published">
                                                                    {formInput.is_published == true ? "Active" : "Inactive"}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Icon</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, icon: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )


}
export default BusinessCategory;