import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addProduct, getProductCategories, updateProduct } from "../../../../store/actions/marketplaceActions";
import axios from "axios";
import { setLoading } from "../../../../store/actions/dashboardActions";
import { authHeader } from "../../../../services/auth-header";
import { API_URI } from "../../../../api";
import Select from 'react-select';
import { getUsers } from "../../../../store/actions/userActions";
import { getBusinessAccounts } from "../../../../store/actions/businessActions";
function Product() {
    let accountsOptions = [];
    const [formInput, setFormInput] = useState({
        name: "",
        sort_description: "",
        description: "",
        product_details: "",
        seller_id: "",
        seller: [],
        category_id: "",
        subcategory_id: "",
        price: "",
        cover_image: "",
        images: "",
        is_published: true,
        in_trending: false,
    });
    const [editMode, setEditMode] = useState(false); //Edit mode
    const [editor, setEditor] = useState(''); //Rich text editor
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const categories = useSelector((state) => state.product_categories.categories);
    const { accounts } = useSelector((state) => state.business_accounts);
    const [subcategories, setSubcategories] = useState([]);
    const products = useSelector((state) => state.products.products);
    useEffect(() => {
        dispatch(getProductCategories(1, ""));
        dispatch(getBusinessAccounts(1, ""));
        if (id) {
            setEditMode(true);
            const singleProduct = products.find(product => product._id === id);
            setFormInput({
                ...formInput,
                name: singleProduct?.name,
                sort_description: singleProduct?.sort_description,
                description: singleProduct?.description,
                category_id: singleProduct?.category_id,
                subcategory_id: singleProduct?.subcategory_id,
                price: singleProduct?.price,
                is_published: singleProduct?.is_published,
                in_trending: singleProduct?.in_trending,
                seller_id: singleProduct?.seller_id,
                seller: singleProduct?.seller,
                product_details: singleProduct?.product_details,
            });
            setTimeout(() => {
                setEditor(singleProduct.product_details ? singleProduct.product_details : "");
                fetchSubcategories(singleProduct.category_id);
            }, 10);
        }
    }, [dispatch, id]);

    accounts.map((account) => {//Map all user and store in new Array
        accountsOptions.push({ value: account._id, label: `${account.name}` })
    });

    const onCategoryChange = (e) => {
        setFormInput({ ...formInput, category_id: e.target.value });
        fetchSubcategories(e.target.value)
    }

    const fetchSubcategories = (category_id) => { //Fetch Subcategory based on category....
        dispatch(setLoading(true))
        axios({
            method: 'POST',
            url: `${API_URI}/marketplace/category/subcategory`,
            data: {
                page_number: 1,
                document_limit: 100,
                category_id: category_id,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                setSubcategories(response.data.results)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('sort_description', formInput.sort_description);
        formData.append('description', formInput.description);
        formData.append('product_details', formInput.product_details);
        formData.append('category_id', formInput.category_id);
        formData.append('subcategory_id', formInput.subcategory_id);
        formData.append('seller_id', formInput.seller_id);
        formData.append('price', formInput.price);
        formData.append('cover_image', formInput.cover_image);
        formData.append('in_trending', formInput.in_trending);
        formData.append('is_published', formInput.is_published);
        for (let i = 0; i < formInput.images.length; i++) {
            formData.append("images", formInput.images[i]);
        }
        if (!editMode) {
            dispatch(addProduct(formData));
            navigator("/marketplace/products");
        } else {
            dispatch(updateProduct(id, formData));
            navigator("/marketplace/products");
        }
    }

    const reactQuillhandleChange = (html) => {
        setEditor(html);
        setFormInput({ ...formInput, product_details: html });
    }

    const getUsersOptions = (user) => {
        setFormInput({ ...formInput, seller_id: user?.value })
    }
    const searchUser = (input) => {
        dispatch(getUsers(1, input));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Product" : "Edit Product"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.all_users.count} Users Found */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">

                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="iPhone 13" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Sort Description</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="2" placeholder="Apple iPhone 13 (64GB) - Starlight" value={formInput.sort_description} onChange={(e) => setFormInput({ ...formInput, sort_description: e.target.value })} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Description</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="5" placeholder="15 cm (6.1-inch) Super Retina XDR display Cinematic mode adds shallow depth of ......" value={formInput.description} onChange={(e) => setFormInput({ ...formInput, description: e.target.value })} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Product details</label>
                                                        <div className="col-md-10">
                                                            <ReactQuill value={editor} onChange={reactQuillhandleChange} modules={{
                                                                toolbar: [
                                                                    [{ 'header': [1, 2, false] }],
                                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],

                                                                    ['clean']
                                                                ],
                                                            }} formats={[
                                                                'header',
                                                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                                'list', 'bullet', 'indent',
                                                                'link', 'image'
                                                            ]} />
                                                        </div>
                                                    </div>
                                                    {editMode ?
                                                        <>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Status</label>
                                                                <div className="col-md-2">
                                                                    <div className="checkbox checkbox-primary">
                                                                        <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                        <label htmlFor="is_published">
                                                                            {(formInput.is_published) ? "Active" : "Inactive"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Trending</label>
                                                                <div className="col-md-2">
                                                                    <div className="checkbox checkbox-primary">
                                                                        <input id="in_trending" type="checkbox" checked={formInput.in_trending} onChange={(e) => setFormInput({ ...formInput, in_trending: !formInput.in_trending })} />
                                                                        <label htmlFor="in_trending">
                                                                            {(formInput.in_trending) ? "Yes" : "No"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div></>
                                                        : null}
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Price</label>
                                                        <div className="col-md-10">
                                                            <input type="number" className="form-control" value={formInput.price} onChange={(e) => setFormInput({ ...formInput, price: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Seller</label>
                                                        <div className="col-md-10">
                                                            <Select name="form-field-name" options={accountsOptions} onChange={getUsersOptions} onInputChange={searchUser} />
                                                            {editMode ? <> Current Seller Name: <b>{formInput?.seller?.[0]?.name}</b></> : null}

                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Category</label>
                                                        <div className="col-md-10">
                                                            <select className="form-control" value={formInput.category_id} onChange={onCategoryChange}>
                                                                <option>Please choose</option>
                                                                {categories && categories.map((category) => {
                                                                    return (<option key={category._id} value={category._id}>{category.name}</option>);
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Subcategory</label>
                                                        <div className="col-md-10">
                                                            <select className="form-control" value={formInput.subcategory_id} onChange={(e) => setFormInput({ ...formInput, subcategory_id: e.target.value })}>
                                                                <option>Please choose</option>
                                                                {subcategories && subcategories.map((subcategory) => {
                                                                    return (<option key={subcategory._id} value={subcategory._id}>{subcategory.name}</option>);
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Cover Image</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, cover_image: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Images</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, images: e.target.files })} multiple={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Product;