


import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { deleteNewsFeed, getNewsFeed, getNewsFromRssFeed } from "../../../store/actions/newsFeedActions";
import Paginate from "../../pagination/Paginate";
import Select from "react-select";
import { addFeedUrl, getFeedUrls, removeFeedUrl } from "../../../store/actions/feedUrlsActions";
function NewsFeeds() {

    let urls = [];
    const dispatch = useDispatch();

    let navigator = useNavigate();
    const [formInput, setFormInput] = useState({
        title: "",
        link: "",
    });
    const [searchInput, setSearchInput] = useState("");
    const [rssFeed, setRssFeed] = useState([]);
    const { news_feeds, page_no, total_pages } = useSelector((state) => state.news_feeds);
    const { feed_urls } = useSelector((state) => state);
    feed_urls.map((data) => {
        urls.push({ label: data.title, value: data.link, id: data._id });
    });
    const addNewsFeed = () => {
        navigator("/news-feed/create")
    }

    const { id } = useParams(); //View Playlist in modal

    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteNewsFeed(id));
            }
        });
    }
    useEffect(() => {
        dispatch(getFeedUrls());
        dispatch(getNewsFeed(1, ""));
    }, [dispatch, id])//Load when something dispatched...

    const Search = () => {
        dispatch(getNewsFeed(1, searchInput));
    }
    const handlePageClick = (current_page) => {
        dispatch(getNewsFeed(current_page, searchInput));
    }
    const handleUrlFormSubmit = (event) => {
        event.preventDefault();
        dispatch(addFeedUrl(formInput));
        setFormInput({ ...formInput, title: "", link: "", });
    }

    const parseRssFeed = () => {
        if (rssFeed.length != 0) {
            rssFeed.map((feed) => {
                dispatch(getNewsFromRssFeed({ rss_feed_uri: feed.value }));
            })
        }
        setRssFeed([]); //Reset the State
    }
    const deleteFeedUrls = () => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                if (rssFeed.length != 0) {
                    rssFeed.map((feed) => {
                        dispatch(removeFeedUrl(feed.id));
                    })
                }
            }
        });
        setRssFeed([]); //Reset the State
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div id="news-feed-view-modal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'none', padding: 1 + "rem" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header border-0">
                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                            <h4 className="modal-title">RSS Feed URLs</h4>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleUrlFormSubmit}>
                                                <div className="form-group row">
                                                    <label className="col-md-2 col-form-label">Title</label>
                                                    <div className="col-md-10">
                                                        <input type="text" className="form-control" placeholder="eg. HBCU Buzz" required={true} value={formInput.title} onChange={(e) => setFormInput({ ...formInput, title: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-md-2 col-form-label">Link</label>
                                                    <div className="col-md-10">
                                                        <textarea className="form-control" placeholder="eg. https://hbcubuzz.com/feed" rows="2" required={true} value={formInput.link} onChange={(e) => setFormInput({ ...formInput, link: e.target.value })}  >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-auto text-right">
                                                    <button type="submit" className="btn btn-light-o mb-2">
                                                        <i class="mdi mdi-plus-circle mr-2"></i>
                                                        Add New Url
                                                    </button>
                                                </div>
                                            </form>
                                            <br />
                                            <br />
                                            <div className="form-group row">
                                                <label className="col-md-2 col-form-label" htmlFor="example-textarea">All Feeds</label>
                                                <div className="col-md-10">
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        value={rssFeed}
                                                        options={urls}
                                                        onChange={(value) => setRssFeed(value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0">
                                            <button type="button" className="btn btn-primary waves-effect" data-dismiss="modal" onClick={parseRssFeed} >Fetch</button>
                                            <button type="button" className="btn btn-light-o waves-effect" data-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal" onClick={deleteFeedUrls} >Delete Selected</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All News Feed</h4>
                                        <p className="sub-header">
                                            {news_feeds.length} News Fetched
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary mr-2" type="button" onClick={addNewsFeed}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                        <button id="demo-btn-addrow " className="btn btn-light-o" type="button" data-toggle="modal" data-target="#news-feed-view-modal">
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Fetch from RSS Feed
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Title" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={Search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Posted At</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {news_feeds && news_feeds.map((news_feed) => {
                                                        return (
                                                            <tr key={news_feed._id}>
                                                                <td><b>{news_feed.title}</b></td>
                                                                <td>{news_feed.description}</td>
                                                                <td>{news_feed.createdAt}</td>
                                                                <td style={{ width: "18%" }}>
                                                                    <Link to={`/news-feed/web-view/${news_feed._id}`} data-toggle="modal" data-target="#playlist-view-modal" className="table-action-btn" title="View News" >
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>

                                                                    <Link to={`/news-feed/${news_feed._id}`} className="table-action-btn" title="Edit News">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <Link to="#" onClick={() => handleDelete(news_feed._id)} className="table-action-btn" title="Delete News"  >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default NewsFeeds;