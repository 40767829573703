import { ADD_BANNER, DELETE_BANNER, GET_BANNERS, UPDATE_BANNER } from "../../components/constants/actionTypes";
import axios from "axios";
import { API_URI } from "../../api";
import { setLoading } from "./dashboardActions";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";

export const getBanners = () => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/banners`,
            data: {
                page_number: 1,
                document_limit: 40,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let banners = response.data.results;
                dispatch({
                    type: GET_BANNERS,
                    banners
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export const addBanner = (banner) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/banners/create`,
                data: banner,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let banner = response.data.results[0];
                dispatch({
                    type: ADD_BANNER,
                    banner
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const deleteBanner = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/banners/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch({
                    type: DELETE_BANNER,
                    id
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const updateBanner = (id, banner) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/banners/${id}`,
            headers: multipartFormData(),
            data: banner,
        }).then((response) => {
            if (response.data.status) {
                let banner = response.data.results[0];
                dispatch({
                    type: UPDATE_BANNER,
                    banner
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}
