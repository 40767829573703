import { GET_NEWS_CATEGORY } from "../../components/constants/actionTypes";
import { API_URI } from "../../api";
import axios from "axios";
import { setLoading } from "./dashboardActions";
import { authHeader } from "../../services/auth-header";
export const getNewsCategory = (pageNumber) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/news-feeds/categories`,
            data: {
                page_number: pageNumber,
                document_limit: 100,
            },
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                let news_categories = response.data.results;
                dispatch({
                    type: GET_NEWS_CATEGORY,
                    news_categories
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}