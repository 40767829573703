import { GET_GENRES } from "../../components/constants/actionTypes";
import axios from "axios";
import { API_URI } from "../../api";
import { setLoading } from "./dashboardActions";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setMessage } from "./appNotificationMessageActions";

export const getGenres = (pageNumber, searchQuery, type) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/media-content/genre`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
                type: type,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                let genres = responseData;
                dispatch({
                    type: GET_GENRES,
                    genres,
                })
                // dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}
export const addGenre = (genre) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/hbcu-tv/media-content/genre/create`,
                data: genre,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                dispatch(getGenres(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const deleteGenre = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/hbcu-tv/media-content/genre/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                dispatch(getGenres(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const updateGenre = (id, genre) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/hbcu-tv/media-content/genre/${id}`,
            headers: multipartFormData(),
            data: genre,
        }).then((response) => {
            if (response.data.status) {
                let responseData = response.data;
                dispatch(getGenres(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}



