import { useSelector } from 'react-redux';
import style from './Loader.css';
import { useEffect } from 'react';
function Loader() {
    const is_loading = useSelector((state) => state.loader.is_loading);
    useEffect(() => {
    }, [is_loading])
    return (
        <div className={is_loading ? "lds-roller" : "lds-roller lds-hide-loader"} >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}
export default Loader;