import { Link } from "react-router-dom";
function LeftSidebar() {
    return (
        <div className="left-side-menu">
            <div className="scroll-menu">
                <div id="sidebar-menu">
                    {/* New Menu Working */}
                    <ul class="nav flex-column flex-nowrap overflow-hidden">
                        <li class="nav-item">
                            <Link to='/dashboard' className="nav-link text-truncate">
                                <i className="fa fa-home"></i>
                                <span> Dashboard </span>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to='/users' className="nav-link text-truncate">
                                <i className="fa fa-users"></i>
                                <span> Users </span>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to='/colleges' className="nav-link text-truncate">
                                <i className="fas fa-university"></i>
                                <span> Colleges </span>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to='/app-banner' className="nav-link text-truncate">
                                <i className="fas fa-money-check"></i>
                                <span> App Banner </span>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#socialSubmenu" data-toggle="collapse" data-target="#socialSubmenu">
                                <i class="fa fa-share-alt"></i>
                                <span>Social</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="socialSubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <Link to='/posts' className="nav-link text-truncate">
                                            <i className="mdi mdi-wallpaper"></i>
                                            <span>Posts</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/shorts' className="nav-link text-truncate">
                                            <i className="mdi mdi-camera"></i>
                                            <span>Shorts</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-truncate collapsed py-1" href="#shortsOtherSubmenu" data-toggle="collapse" data-target="#shortsOtherSubmenu">
                                            <i className="fa fa-circle-notch"></i>
                                            <span>Shorts Other </span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        <div class="collapse" id="shortsOtherSubmenu" aria-expanded="false">
                                            <ul class="nav flex-column nav-second-level third-level">
                                                <li class="nav-item">
                                                    <Link to='/playlists' className="nav-link text-truncate">
                                                        <i className="mdi mdi-playlist-music-outline"></i>
                                                        <span>Playlists</span>
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link to='/songs' className="nav-link text-truncate">
                                                        <i className="mdi mdi-library-music-outline"></i>
                                                        <span>Songs</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#eventsSubmenu" data-toggle="collapse" data-target="#eventsSubmenu">
                                <i className="fa fa-calendar-plus"></i>
                                <span>Events</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="eventsSubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <Link to='/events' className="nav-link text-truncate">
                                            <i className="fas fa-calendar"></i>
                                            <span>Event</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/events/bookings' className="nav-link text-truncate">
                                            <i className="fa fa-ticket-alt"></i>
                                            <span>Bookings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>


                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#newsFeedSubmenu" data-toggle="collapse" data-target="#newsFeedSubmenu">
                                <i className=" fas fa-rss-square"></i>
                                <span>News Feed</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="newsFeedSubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <Link to='/news-feed' className="nav-link text-truncate">
                                            <i className="far fa-newspaper"></i>
                                            <span>News</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/news-feed' className="nav-link text-truncate">
                                            <i className="mdi mdi-code-string"></i>
                                            <span>News Feed Categories</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#businessDirectorySubmenu" data-toggle="collapse" data-target="#businessDirectorySubmenu">
                                <i className="fas fa-business-time"></i>
                                <span>Business Directory</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="businessDirectorySubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <Link to='/business/accounts' className="nav-link text-truncate">
                                            <i className="fa fa-user-tie"></i>
                                            <span>Business Account</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/business/category' className="nav-link text-truncate">
                                            <i className="mdi mdi-code-string"></i>
                                            <span>Business Categories</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>


                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#marketplaceSubmenu" data-toggle="collapse" data-target="#marketplaceSubmenu">
                                <i className="fas fa-shopping-cart"></i>
                                <span>Marketplace</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="marketplaceSubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <Link to='/marketplace/products' className="nav-link text-truncate">
                                            <i className="fa fa-box-open"></i>
                                            <span>Products</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/marketplace/category' className="nav-link text-truncate">
                                            <i className="fa fa-th-large"></i>
                                            <span>Category</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/marketplace/subcategory' className="nav-link text-truncate">
                                            <i className="fa fa-th"></i>
                                            <span>Subcategory</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#hbcuTVSubmenu" data-toggle="collapse" data-target="#hbcuTVSubmenu">
                                <i className="fas fa-chalkboard-teacher"></i>
                                <span>HBCU TV Censor Board </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="hbcuTVSubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <a class="nav-link collapsed text-truncate" href="#liveTvSubmenu" data-toggle="collapse" data-target="#liveTvSubmenu">
                                            <i className="fa fa-tv"></i>
                                            <span>Live TV</span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        <div class="collapse" id="liveTvSubmenu" aria-expanded="false">
                                            <ul class="nav flex-column nav-second-level third-level">
                                                <li class="nav-item">
                                                    <Link to='/live-tv' className="nav-link text-truncate">
                                                        <i className="fa fa-circle-notch"></i>
                                                        <span>Channels</span>
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link to='/live-tv/genres' className="nav-link text-truncate">
                                                        <i className="fa fa-circle-notch"></i>
                                                        <span>Genres</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link collapsed text-truncate" href="#hbcuTvSubmenu" data-toggle="collapse" data-target="#hbcuTvSubmenu">
                                            <i className="fa fa-tablet-alt"></i>
                                            <span>HBCU TV</span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        <div class="collapse" id="hbcuTvSubmenu" aria-expanded="false">
                                            <ul class="nav flex-column nav-second-level third-level">
                                                <li class="nav-item">
                                                    <Link to='/content' className="nav-link text-truncate">
                                                        <i className="fa fa-circle-notch"></i>
                                                        <span>Content</span>
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link to='/genre-and-category' className="nav-link text-truncate">
                                                        <i className="fa fa-circle-notch"></i>
                                                        <span>Genre & Category</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link collapsed text-truncate" href="#sportsSubmenu" data-toggle="collapse" data-target="#sportsSubmenu">
                                <i className="fas fa-football-ball"></i>
                                <span>Sports</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div class="collapse" id="sportsSubmenu" aria-expanded="false">
                                <ul class="nav flex-column nav-second-level">
                                    <li class="nav-item">
                                        <Link to='/sports-games' className="nav-link text-truncate">
                                            <i className="fa fa-sitemap"></i>
                                            <span>List of Games</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/teams' className="nav-link text-truncate">
                                            <i className="fa fa-users-cog"></i>
                                            <span>Team Management</span>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/sport-highlights' className="nav-link text-truncate">
                                            <i className="fa fa-vr-cardboard"></i>
                                            <span>Sport Watch</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <Link to='/reports' className="nav-link text-truncate">
                                <i className="far fa-file-archive"></i>
                                <span> Reports </span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="clearfix"></div>
            </div>
        </div >
    )
}
export default LeftSidebar;