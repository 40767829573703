import { GET_FEED_URLS } from "../../components/constants/actionTypes";
const feedUrlsReducer = (state = [], action) => {
    switch (action.type) {
        case GET_FEED_URLS:
            return action.feed_urls;
        default:
            return state;
    }
}

export default feedUrlsReducer;