import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { deleteSong, getSongs } from "../../../store/actions/shortsActions";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function Songs() {
    SetDocumentTitle(`Songs \u2022 ${APP_NAME}`);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const songs = useSelector((state) => state.songs);
    useEffect(() => {
        dispatch(getSongs(1));
    }, [dispatch])//Load when something dispatched...

    const addNewSong = () => {
        navigate('/songs/create')
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteSong(id))
            }
        });
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Songs</h4>
                                        <p className="sub-header">
                                            {songs.length} Songs Fetched
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewSong}><i className="mdi mdi-plus-circle mr-2"></i> Add New</button>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <input id="demo-input-search2" type="text" placeholder="Search" className="form-control" autocomplete="off" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover m-0 table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Image
                                                        </th>
                                                        <th>Name</th>
                                                        <th>Artist</th>
                                                        <th>Playlist Name</th>
                                                        <th>Song</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {songs && songs.map((song) => {
                                                        return (
                                                            <tr key={song._id}>
                                                                <td>
                                                                    {
                                                                        song.thumbnail ?
                                                                            <img src={song.thumbnail} alt="image" className="img-fluid avatar-lg" />
                                                                            :
                                                                            <img src="/assets/images/playlist/playlist-thumb.jpg" alt="image" className="img-fluid avatar-lg" />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <h5 className="m-0 font-weight-medium">{song.name}</h5>
                                                                </td>
                                                                <td>{song.artist}</td>
                                                                <td>
                                                                    {song?.playlist?.[0]?.name}
                                                                </td>
                                                                <td>
                                                                    <audio controls>
                                                                        <source src={song.song_url} type="audio/mp3" />
                                                                        Your browser does not support the audio tag.
                                                                    </audio>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/songs/${song._id}`} className="table-action-btn">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span onClick={() => handleDelete(song._id)} style={{ cursor: "pointer" }} className="table-action-btn">
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Songs;