import { multipartFormData } from "../../services/header"
import axios from "axios";
import { API_URI } from "../../api";
import { ADD_PLAYLIST, GET_PLAYLISTS, DELETE_PLAYLIST, UPDATE_PLAYLIST, GET_SONGS, ADD_SONG, DELETE_SONG, GET_SHORTS } from "../../components/constants/actionTypes";
import { setLoading } from "./dashboardActions";
import { authHeader } from "../../services/auth-header";

export const getPlaylists = (page_number) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'GET',
            url: `${API_URI}/shorts/playlist`,
            data: {
                'page_number': page_number
            },
            //  headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let playlists = response.data.results;
                dispatch({
                    type: GET_PLAYLISTS,
                    playlists
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const addPlaylist = (playlist) => {
    return (dispatch,) => {
        axios.post(`${API_URI}/shorts/playlist/create`, playlist,
            {
                headers: multipartFormData()
            }).then((response) => {
                if (response.data.status) {
                    let playlist = response.data.results[0];
                    dispatch({
                        type: ADD_PLAYLIST,
                        playlist
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
    }
}

export const deletePlaylist = (id) => {
    return (dispatch) => {
        axios.delete(`${API_URI}/shorts/playlist/${id}`)
            .then((response) => {
                if (response.data.status) {
                    dispatch({
                        type: DELETE_PLAYLIST,
                        id
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
    }
}

export const updatePlaylist = (id, playlist) => {
    return (dispatch) => {
        axios.put(`${API_URI}/shorts/playlist/${id}`, playlist,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    let playlist = response.data.results[0];
                    dispatch({
                        type: UPDATE_PLAYLIST,
                        playlist
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
    }
}

export const getSongs = (page_number) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/shorts/songs`,
            data: {
                'page_number': page_number
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let songs = response.data.results;
                dispatch({
                    type: GET_SONGS,
                    songs
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            console.log("SONG LOADING FALSE")
            dispatch(setLoading(false));
        })
    }
}

export const addSong = (song) => {
    return (dispatch,) => {
        axios.post(`${API_URI}/shorts/songs/create`, song,
            {
                headers: multipartFormData()
            }).then((response) => {
                if (response.data.status) {
                    let song = response.data.results[0];
                    dispatch({
                        type: ADD_SONG,
                        song
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
    }
}

export const deleteSong = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/shorts/songs/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch({
                    type: DELETE_SONG,
                    id
                });
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const updateSong = (id, song) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.put(`${API_URI}/shorts/songs/${id}`,
            song,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    dispatch(getSongs(1));
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                dispatch(setLoading(false));
            });
    }
}



export const getShorts = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/shorts/all`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery
            },
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                let shorts = response.data;
                dispatch({
                    type: GET_SHORTS,
                    shorts
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const publishedUnpublishedShorts = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PATCH',
            url: `${API_URI}/shorts/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getShorts(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}
