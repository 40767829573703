import { useEffect, useState } from "react";
import { APP_NAME } from "../constants/uiTypes";
import { SetDocumentTitle } from "../helper/functions";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/actions/dashboardActions";
import { API_URI } from "../../api";
import { multipartFormData } from "../../services/header";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { authHeader } from "../../services/auth-header";
import PublicLogin from "./components/PublicLogin";
import { setMessage } from "../../store/actions/appNotificationMessageActions";
import { forceReloadUI, getMovieMeta, uploadSeriesMeta } from "../../store/actions/uploaderActions";
import PublicNavbar from "./components/PublicNavbar";
import { PUBLIC_REDIRECT } from "../constants/actionTypes";

const animatedComponents = makeAnimated();
function MyMediaContent(props) {
    SetDocumentTitle(`Add Content  \u2022 ${APP_NAME}`);
    const dispatch = useDispatch();
    const { id, type } = useParams();
    const { isLoggedIn, user, token } = useSelector((state) => state.public_auth);
    const uploaderState = useSelector((state) => state.uploader);
    const { movie, page_reload } = uploaderState;

    const [uploading, setUploading] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [uploadingProgressMessage, setUploadingProgressMessage] = useState("");
    const [hasMedia, setMedia] = useState(false);
    const [formInput, setFormInput] = useState({ //UI input state
        thumbnail: "",
        cover_image: "",
        overview: "",
        genres: [],
        languages: [],
        starring: [],
        director: "",
        rating: "",
        duration: "",
        maturity_rating: "",
        released_date: "",
        year: 2020,
        type: "",
        name: "",
        media: "",
        series: []
    });


    const [episodeFormInput, setEpisodeFormInput] = useState({ //UI input state
        thumbnail: "",
        cover_image: "",
        overview: "",
        genres: [],
        languages: [],
        starring: [],
        director: "",
        rating: "",
        duration: "",
        maturity_rating: "",
        released_date: "",
        year: 2020,
        type: "",
        name: "",
        media: "",
        episode: 0,
        season: 0,
    });

    const [episodeMediaInput, setEpisodeMediaInput] = useState({ //UI input state
        content_id: "",
        media: "",
    });

    useEffect(() => {
        if (id && type) {
            if (movie === null) {
                dispatch(getMovieMeta(type, id, token));
            }
            /**
            * Get parent movie data
            */
            setFormInput({
                ...formInput,
                thumbnail: movie?.thumbnail,
                cover_image: movie?.cover_image,
                overview: movie?.overview,
                genres: [],
                languages: [],
                starring: movie?.starring,
                director: movie?.director,
                rating: movie?.rating,
                duration: movie?.duration,
                maturity_rating: movie?.maturity_rating,
                released_date: "",
                year: movie?.released_year,
                type: movie?.type,
                name: movie?.name,
                media: movie?.media,
                series: movie?.related?.series,
            });
            /**
            * If Movie Having media 
            */
            if (movie?.media?.url !== "" && movie?.media?.url !== undefined) {
                setMedia(true);
            }
        }
    }, [dispatch, id, type, uploaderState]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (id !== "") {
            setUploading(true);
            console.log(formInput.media);
            const media_file = formInput.media;
            const reader = new FileReader();
            reader.readAsArrayBuffer(media_file);
            reader.addEventListener('progress', (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadingProgressMessage('Reading File ...');
                setUploadingProgress(parseInt(progress));
            });
            reader.onload = () => {
                setUploadingProgress(0);
                console.log(reader.result)
                axios(
                    {
                        method: 'POST',
                        url: `${API_URI}/hbcu-tv/media-content/uploading`,
                        data: reader.result,
                        headers: {
                            'Accept': '*/*',
                            'X-Auth-Token': localStorage.getItem('public_token'),
                            'Content-Type': `${media_file.type}`,
                            'Content-Length': `${media_file.size}`,
                            'Content-ID': `${id}`,
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            console.log(progressEvent);
                            setUploadingProgressMessage('Uploading file ...');
                            setUploadingProgress(parseInt(progress));
                        },
                        onDownloadProgress: (progressEvent) => {
                            const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                            console.log(progress);
                        },
                        validateStatus: (statusbar) => {
                            console.log(statusbar, `validateStatus`);
                        },
                    }
                ).then((response) => {
                    if (response.data.status) {
                        const content = response.data.results[0];
                        window.location.reload();
                    }
                }).catch((error) => {
                    dispatch(setMessage(400, 'Error: Network Error', false));
                    console.log(`Error: Network Error:: ${error}`);
                }).finally(() => {
                    setUploading(false);
                })
            };
            reader.onerror = error => {
                console.log(error);
            };




            // const formData = new FormData();
            // formData.append('content_id', id);
            // formData.append('media', formInput.media);
            // axios(
            //     {
            //         method: 'POST',
            //         url: `${API_URI}/hbcu-tv/media-content/upload-media`,
            //         data: formData,
            //         headers: {
            //             'X-Auth-Token': localStorage.getItem('public_token'),
            //         },
            //     }
            // onUploadProgress: (progressEvent) => {
            //     const progress = (progressEvent.loaded / progressEvent.total) * 100;
            //     console.log(progressEvent);
            //     setUploadingProgress(parseInt(progress));
            // },
            // onDownloadProgress: (progressEvent) => {
            //     const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
            //     console.log(progress);
            // },
            // ).then((response) => {
            //     if (response.data.status) {
            //         const content = response.data.results[0];
            //         window.location.reload();
            //     }
            // }).catch((error) => {
            //     console.log(error)
            // }).finally(() => {
            //     setUploading(false);
            // })
        }

    }

    const addEpisodeSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('content_id', id);
        formData.append('season', episodeFormInput.season);
        formData.append('episode', episodeFormInput.episode);
        formData.append('name', episodeFormInput.name);
        formData.append('overview', episodeFormInput.overview);
        formData.append('director', episodeFormInput.director);
        formData.append('released_date', episodeFormInput.released_date);
        formData.append('rating', episodeFormInput.rating);
        formData.append('maturity_rating', episodeFormInput.maturity_rating);
        dispatch(uploadSeriesMeta(formData, token))

    }
    const addEpisodeMedia = (event) => {
        event.preventDefault();
        dispatch(setLoading(true));
        const formData = new FormData();
        formData.append('content_id', episodeMediaInput?.content_id);
        formData.append('media', episodeMediaInput?.media);
        axios(
            {
                method: 'POST',
                url: `${API_URI}/hbcu-tv/media-content/upload-media`,
                data: formData,
                headers: {
                    'X-Auth-Token': localStorage.getItem('public_token'),
                },
            }
        ).then((response) => {
            if (response.data.status) {
                const content = response.data.results[0];
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }

    return (
        <>
            {isLoggedIn ?
                <>
                    <PublicNavbar name={user?.name} image={user?.cover_pic} />
                    <div className="container mb-2 mt-4">
                        <Link to='/public/uploader'>
                            <h5>
                                <i className="mdi mdi-arrow-left-bold mr-1"></i>
                                Back to Uploader
                            </h5>
                        </Link>
                    </div>
                    <div className="container ">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <fieldset >
                                        <legend>Uploaded Mediameta</legend>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card mb-3 border" >
                                                    <div className="row g-0">
                                                        <div className="col-md-4">
                                                            <img src={formInput?.cover_image} className="rounded-start" alt="..." height={'100%'} width={'100%'} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="card-body">
                                                                <h5 className="card-title">{formInput?.name}</h5>
                                                                <p>
                                                                    <span className="pr-1">{formInput?.year}</span>
                                                                    <span className="pr-1">&bull;</span>
                                                                    <span className="pr-1"><span>{formInput?.maturity_rating}</span></span>
                                                                    <span className="pr-1">&bull;</span>
                                                                    <span className="pr-1">{formInput?.rating}/10</span>
                                                                </p>
                                                                <p className="card-text">{formInput?.overview}</p>
                                                                <p className="card-text">
                                                                    <b>Director : </b>
                                                                    <small className="text-muted">
                                                                        {formInput?.director}
                                                                    </small>
                                                                </p>
                                                                <p className="card-text">
                                                                    <b>Stars : </b>
                                                                    <small className="text-muted">
                                                                        {/* {formInput?.starring} */}
                                                                    </small>
                                                                </p>
                                                                {(formInput?.type === "movie") ?
                                                                    <>
                                                                        <div className="movie">
                                                                            {/* Set uploading true and show progress */}
                                                                            <small>
                                                                                {uploadingProgressMessage}
                                                                            </small>
                                                                            {uploading ?
                                                                                <>
                                                                                    <div className="progress m-0">
                                                                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ "width": `${uploadingProgress}%` }}></div>
                                                                                    </div>
                                                                                </> :
                                                                                null
                                                                            }
                                                                            {
                                                                                (hasMedia) ?
                                                                                    <>
                                                                                        <video controls={true} poster={formInput?.thumbnail} width="100%" >
                                                                                            <source src={formInput?.media?.url} type="video/mp4" />
                                                                                        </video>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="mb-2">
                                                                                            <label htmlFor="disabledSelect" className="form-label">Upload Movie</label>
                                                                                            <br />
                                                                                            <input type="file" onChange={(event) => setFormInput({ ...formInput, media: event.target.files[0] })} required={true} />
                                                                                        </div>
                                                                                        <button type="submit" className="btn btn-primary" disabled={uploading}>
                                                                                            <i className="fa fa-upload mr-2"></i>
                                                                                            {!uploading ? 'Upload Movies' : 'Uploading Movies'}
                                                                                        </button>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    : null
                                                                }
                                                                {(formInput?.type === "tv-series") ?
                                                                    <>
                                                                        <div className="tv-series">
                                                                            <h3>Add Episodes</h3>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="disabledSelect" className="form-label">Name</label>
                                                                                    <input type="text" className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, name: event.target.value.trim() })} />
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledSelect" className="form-label">Overview</label>
                                                                                        <textarea className="form-control" rows={3} placeholder="overview ...." onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, overview: event.target.value.trim() })}>
                                                                                        </textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledSelect" className="form-label">Season</label>
                                                                                        <input type="number" className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, season: event.target.value })} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledSelect" className="form-label">Episode</label>
                                                                                        <input type="Number" className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, episode: event.target.value })} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledTextInput" className="form-label">Release Date</label>
                                                                                        <input type="date" className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, released_date: event.target.value })} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledTextInput" className="form-label">Maturity ratings</label>
                                                                                        <select className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, maturity_rating: event.target.value })}  >
                                                                                            <option defaultValue>Please choose ...</option>
                                                                                            <option value="TV-Y">All Children (TV-Y)</option>
                                                                                            <option value="TV-Y7">Directed to Older Children (TV-Y7)</option>
                                                                                            <option value="TV-Y7 FV">Directed to Older Children - Fantasy Violence (TV-Y7 FV)</option>
                                                                                            <option value="TV-G">General Audience (TV-G)</option>
                                                                                            <option value="TV-PG">Parental Guidance Suggested (TV-PG)</option>
                                                                                            <option value="TV-14">Parents Strongly Cautioned (TV-14)</option>
                                                                                            <option value="TV-MA">Mature Audience Only (TV-MA)</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledTextInput" className="form-label">Director</label>
                                                                                        <input type="text" className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, director: event.target.value })} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-2">
                                                                                        <label htmlFor="disabledTextInput" className="form-label">Rating</label>
                                                                                        <input type="number" className="form-control" onChange={(event) => setEpisodeFormInput({ ...episodeFormInput, rating: event.target.value })} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <button className="btn btn-primary" onClick={addEpisodeSubmit}>
                                                                                        Add episode
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                        {(formInput?.type === "tv-series") ?
                            <div className="card">
                                <div className="card-body">
                                    <h3>{formInput?.name} ({formInput?.year})</h3>
                                    <form onSubmit={addEpisodeMedia}>

                                        {(formInput?.media?.url !== undefined && formInput?.media?.url !== "") ?
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <video controls={true} poster={formInput?.thumbnail} width="100%" >
                                                            <source src={formInput?.media?.url} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                            : <>
                                                <h5 className="card-title pt-2">Add Trailer</h5>
                                                <div className="d-flex">
                                                    <input type="file" required={true} onChange={(event) => setEpisodeMediaInput({ ...episodeMediaInput, media: event.target.files[0] })} />
                                                    <button className="btn btn-sm btn-primary" onClick={(event) => setEpisodeMediaInput({ ...episodeMediaInput, content_id: id })} >
                                                        <i className="fas fa-upload"></i>
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    </form>
                                    {
                                        formInput?.series && formInput.series.map((series) => {
                                            return (
                                                <>
                                                    <h4>Series {series._id} - Episode List</h4>
                                                    <div className="row" key={series._id}>
                                                        {
                                                            series?.data && series.data.map((episode) => {
                                                                return (
                                                                    <div className="col-md-6" key={episode._id}>
                                                                        <div className="card mb-3 border" >
                                                                            <div className="row g-0">
                                                                                <div className="col-md-4">
                                                                                    <img src={episode?.cover_image} className="rounded-start" alt="..." height={'100%'} width={'100%'} />
                                                                                </div>
                                                                                <div className="col-md-8">
                                                                                    <form onSubmit={addEpisodeMedia}>
                                                                                        <h5 className="card-title pt-2">{episode?.name}</h5>
                                                                                        <p>
                                                                                            <span className="pr-1">S{episode?.season}</span>
                                                                                            <span className="pr-1">&bull;</span>
                                                                                            <span className="pr-1">E{episode?.episode}</span>
                                                                                        </p>
                                                                                        {(episode?.media?.url !== undefined && episode?.media?.url !== "") ?
                                                                                            <a href={episode?.media?.url} target="_blank">
                                                                                                <i className="fas fa-eye"></i> view
                                                                                            </a>
                                                                                            :
                                                                                            <div className="d-flex">
                                                                                                <input type="file" required={true} onChange={(event) => setEpisodeMediaInput({ ...episodeMediaInput, media: event.target.files[0] })} />
                                                                                                <button className="btn btn-sm btn-primary" onClick={(event) => setEpisodeMediaInput({ ...episodeMediaInput, content_id: episode._id })} >
                                                                                                    <i className="fas fa-upload"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        }
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            : null}
                    </div>
                </>
                : <PublicLogin></PublicLogin>
            }
        </>
    )
}
export default MyMediaContent;