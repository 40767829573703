import { GET_POSTS } from "../../components/constants/actionTypes";
const postReducer = (state = { posts: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_POSTS:
            return {
                posts: action.posts.results,
                page_no: action.posts.page_no,
                total_pages: action.posts.total_pages,
            }
        default:
            return state;
    }
}

export default postReducer;