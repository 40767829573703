


import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { addEvent, updateEvent } from "../../../store/actions/eventActions";
import axios from "axios";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";

function Event() {
    SetDocumentTitle(`Event Actions \u2022 ${APP_NAME}`);
    const current_date = new Date().toISOString().split('T');
    const [formInput, setFormInput] = useState({
        title: "",
        description: "",
        lat: "",
        long: "",
        place: "",
        price: 0,
        venue_location: "",
        time_zone: "",
        seats: 0,
        start_date: current_date[0],
        end_date: current_date[0],
        start_time: "",
        end_time: "",
        is_online: false,
        is_published: true,
        link: "",
        image: "",
    });
    const [editMode, setEditMode] = useState(false);
    const [timezone, setTimezone] = useState([]); //To manange time zone array
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { events, total_pages, page_no } = useSelector((state) => state.events.eventsData);
    const { id } = useParams();
    useEffect(() => {
        axios.get(`${window.location.origin}/assets/data/timezone.json`)
            .then((response) => {
                if (response.status == 200) {
                    setTimezone(response.data); //Set time zones 
                }
            }).catch((error) => {
                console.log(error);
            });

        if (id) {
            setEditMode(true);
            const singleEvent = events.find(event => event._id == id);
            console.log(singleEvent);
            setFormInput({
                title: singleEvent.title,
                description: singleEvent.description,
                lat: singleEvent.description,
                long: singleEvent.description,
                place: singleEvent.location.place,
                price: singleEvent.price,
                venue_location: singleEvent.venue_location,
                time_zone: singleEvent.time_zone,
                seats: singleEvent.seats,
                start_date: singleEvent.date.start,
                end_date: singleEvent.date.end,
                start_time: singleEvent.time.start,
                end_time: singleEvent.time.end,
                is_online: singleEvent.is_online,
                is_published: singleEvent.is_published,
                link: singleEvent.link,
                // description: singlePlaylist.description,
                // thumbnail: "",
            })
        }

    }, [dispatch, id])//Load when something dispatched...

    console.log(formInput);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', formInput.title);
        formData.append('description', formInput.description);
        formData.append('lat', "");
        formData.append('long', "");
        formData.append('place', formInput.place);
        formData.append('price', formInput.price);
        formData.append('venue_location', formInput.venue_location);
        formData.append('time_zone', formInput.time_zone);
        formData.append('seats', formInput.seats);
        formData.append('end_date', formInput.end_date);
        formData.append('start_date', formInput.start_date);
        formData.append('start_time', formInput.start_time);
        formData.append('end_time', formInput.end_time);
        formData.append('is_online', formInput.is_online);
        formData.append('is_published', formInput.is_published);
        formData.append('link', formInput.link);
        formData.append('image', formInput.image);
        if (!editMode) {
            dispatch(addEvent(formData));
            navigate('/events');
        }
        else {
            dispatch(updateEvent(id, formData));
            navigate('/events');
        }
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Event" : "Edit Event"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.length} Users Fetched */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label" >Title</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" required={true} value={formInput.title} onChange={(e) => setFormInput({ ...formInput, title: e.target.value })} />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Description</label>
                                                            <div className="col-md-10">
                                                                <textarea className="form-control" rows="5" value={formInput.description} onChange={(e) => setFormInput({ ...formInput, description: e.target.value })} >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Start/End Date</label>
                                                            <div className="col-md-10">
                                                                <div className="row ">
                                                                    <div className="col-6">
                                                                        <input type="date" className="form-control" required={true} value={formInput.start_date} onChange={(e) => setFormInput({ ...formInput, start_date: e.target.value })} min={current_date[0]} />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <input type="date" className="form-control" required={true} value={formInput.end_date} onChange={(e) => setFormInput({ ...formInput, end_date: e.target.value })} min={formInput.start_date} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Start/End Time/Time Zone Abbreviations</label>
                                                            <div className="col-md-10">
                                                                <div className="row ">
                                                                    <div className="col-4">
                                                                        <input type="time" className="form-control" required={true} value={formInput.start_time} onChange={(e) => setFormInput({ ...formInput, start_time: e.target.value })} />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <input type="time" className="form-control" required={true} value={formInput.end_time} onChange={(e) => setFormInput({ ...formInput, end_time: e.target.value })} />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <select className="form-control" required={true} value={formInput.time_zone} onChange={(e) => setFormInput({ ...formInput, time_zone: e.target.value })} defaultValue="EST">
                                                                            {timezone && timezone.map((timezone, index) => {
                                                                                return (<option value={timezone.abbreviation} key={index} >{timezone.time_zone_name}</option>)
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Pricing</label>
                                                            <div className="col-md-10">
                                                                <input type="number" className="form-control" placeholder="eg. $25/per person" required={true} value={formInput.price} onChange={(e) => setFormInput({ ...formInput, price: e.target.value })} min={0} />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Seat</label>
                                                            <div className="col-md-10">
                                                                <input type="number" className="form-control" placeholder="" required={true} value={formInput.seats} onChange={(e) => setFormInput({ ...formInput, seats: e.target.value })} min={0} />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-2 col-form-label">Event</label>
                                                            <div class="col-md-4">
                                                                <div class="checkbox checkbox-primary">
                                                                    <input id="is_online" type="checkbox" checked={formInput.is_online} onChange={(e) => setFormInput({ ...formInput, is_online: !formInput.is_online })} />
                                                                    <label htmlFor="is_online">{(formInput.is_online == true) ? "Online" : "Offline"}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(formInput.is_online == true) ? <div class="form-group row">
                                                            <label class="col-md-2 col-form-label">Link</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" placeholder="" value={formInput.link} onChange={(e) => setFormInput({ ...formInput, link: e.target.value })} />
                                                            </div>
                                                        </div> : null}
                                                        {editMode ? <div class="form-group row">
                                                            <label class="col-md-2 col-form-label">Published</label>
                                                            <div class="col-md-4">
                                                                <div class="checkbox checkbox-primary">
                                                                    <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                    <label htmlFor="is_published">{(formInput.is_published == true) ? "Published" : "Hide"}</label>
                                                                </div>
                                                            </div>
                                                        </div> : null}

                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Location</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" placeholder="eg. Spelman College" value={formInput.place} onChange={(e) => setFormInput({ ...formInput, place: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Venue Location</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" placeholder="eg. Auditorium Hall" value={formInput.venue_location} onChange={(e) => setFormInput({ ...formInput, venue_location: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Image</label>
                                                            <div className="col-md-10">
                                                                <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, image: e.target.files[0] })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button type="submit" className="btn btn-primary mb-2">
                                                                {!editMode ? "Submit" : "Update"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Event;