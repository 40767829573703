import { GET_MEDIA_CONTENT, GET_SINGLE_MEDIA_CONTENT, CONTENT_GENRES, CONTENT_LANGUAGES, CONTENT_CATEGORIES } from "../../components/constants/actionTypes";



const mediaContentReducer = (state = {
    content: [],
    page_no: 1,
    total_pages: 1,
    single_content: null,
    genres: [],
    categories: [],
    languages: [],
}, action) => {
    switch (action.type) {
        case GET_MEDIA_CONTENT:
            return {
                ...state,
                content: action.media_content.results,
                page_no: action.media_content.page_no,
                total_pages: action.media_content.total_pages,
            };
        case GET_SINGLE_MEDIA_CONTENT:
            return {
                ...state,
                single_content: action.single_content,
            }
        case CONTENT_GENRES:
            return {
                ...state,
                genres: action.genres,
            }
        case CONTENT_CATEGORIES:
            return {
                ...state,
                categories: action.genres,
            }
        case CONTENT_LANGUAGES:
            return {
                ...state,
                languages: action.languages
            }
        default:
            return state;
    }
}
export default mediaContentReducer;