import { GET_CHANNELS } from "../../components/constants/actionTypes";
const liveTvReducer = (state = { channels: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_CHANNELS:
            return {
                channels: action.channels.results,
                page_no: action.channels.page_no,
                total_pages: action.channels.total_pages,
            };
        default:
            return state;
    }
}
export default liveTvReducer;


