import { GET_SPORT_GAMES, GET_SPORT_TEAMS, GET_SPORT_HIGHLIGHT } from "../../components/constants/actionTypes";

export const listOfSportsReducer = (state = { games: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_SPORT_GAMES:
            return {
                games: action.games.results,
                page_no: action.games.page_no,
                total_pages: action.games.total_pages,
            };
        default:
            return state;
    }
}

export const teamsReducer = (state = { teams: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_SPORT_TEAMS:
            return {
                teams: action.teams.results,
                page_no: action.teams.page_no,
                total_pages: action.teams.total_pages,
            };
        default:
            return state;
    }
}

export const sportHighlightsReducer = (state = { sport_highlights: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_SPORT_HIGHLIGHT:
            return {
                sport_highlights: action.highlight.results,
                page_no: action.highlight.page_no,
                total_pages: action.highlight.total_pages,
            };
        default:
            return state;
    }
}