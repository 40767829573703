import { useEffect, useState } from "react";

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const SetDocumentTitle = (title) => {
    const [document_title, setTitle] = useState(title);
    useEffect(() => {
        document.title = document_title;
    }, [document_title]);

    return [document_title, setTitle];
}

export const noThumbnail = () => {
    return (<img src="assets/images/no-thumbnail.jpg" alt="noThumbnail" className="img-thumbnail img-fluid" width="100px" />);
}

export const noAvator = () => {
    return (<img src="assets/images/users/no-avatar.jpg" alt="noAvator" className="rounded-circle avatar-sm" width="100px" />);
}