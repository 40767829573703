import { ADD_NEWS_FEED, DELETE_NEWS_FEED, GET_NEWS_FEEDS, UPDATE_NEWS_FEED } from "../../components/constants/actionTypes";
export const newsFeedReducer = (state = { news_feeds: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_NEWS_FEEDS:
            return {
                news_feeds: action.news_feeds.results,
                page_no: action.news_feeds.page_no,
                total_pages: action.news_feeds.total_pages,
            }
        case ADD_NEWS_FEED:
            return [action.news_feed, ...state]
        case DELETE_NEWS_FEED:
            return state.filter(news_feed => {
                if (news_feed._id === action.id) {
                    return false;
                } else {
                    return true;
                }
            });
        case UPDATE_NEWS_FEED:
            state.map((news_feed) => {
                if (news_feed._id === action.news_feed._id) {
                    return false;
                }
                return true;
            });
        default:
            return state;
    }
}