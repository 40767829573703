import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logOut } from "../../store/actions/authActions";
import { sidebarMenuToggle } from "../../store/actions/dashboardActions";
import { APP_NAME } from "../constants/uiTypes"
function Topbar() {
    const sidebar = useSelector((state) => state.sidebar.enlarged);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const toggleSidebar = () => {
        dispatch(sidebarMenuToggle(!sidebar));
    }
    const logout = () => {
        dispatch(logOut());
    }
    return (<div className="navbar-custom">
        <ul className="list-unstyled topnav-menu float-right mb-0">
            <li className="dropdown notification-list">
                {user ?
                    <div className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                        {
                            user.profile_pic === "-" || user.profile_pic === "" ?
                                <img src="/assets/images/users/no-avatar.jpg" alt={`${user.name}'s avatar`} className="rounded-circle" />
                                :
                                <img src={user.profile_pic} alt={`${user.name}'s avatar`} className="rounded-circle" />
                        }
                        <span className="pro-user-name ml-1">
                            {user.name}
                            <i className="mdi mdi-chevron-down"></i>
                        </span>
                    </div>
                    :
                    <div className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                        <img src="/assets/images/users/no-avatar.jpg" alt="user image" className="rounded-circle" />
                        <span className="pro-user-name ml-1">
                            Anonymous  <i className="mdi mdi-chevron-down"></i>
                        </span>
                    </div>
                }

                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">

                    <div className="dropdown-header noti-title">
                        <h6 className="text-overflow m-0">Welcome !</h6>
                    </div>
                    <div className="dropdown-divider"></div>
                    <span className="dropdown-item notify-item" onClick={() => logout()}>
                        <i className="fe-log-out"></i>
                        <span>Logout</span>
                    </span>
                </div>
            </li>
            {/* <li className="dropdown notification-list">
                <a href="#" className="nav-link right-bar-toggle waves-effect waves-light">
                    <i className="fe-settings noti-icon"></i>
                </a>
            </li> */}
        </ul>

        <div className="logo-box">
            <Link to="/dashboard" className="logo text-center">
                <span className="logo-lg">
                    <img src="logo512-white.png" alt="" height="50" />
                    <span className="logo-lg-text-light">{APP_NAME}</span>
                </span>
                <span className="logo-sm">
                    {/* <span className="logo-sm-text-dark">U</span> */}
                    <img src="logo512-white.png" alt="" height="50" />
                </span>
            </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button className="button-menu-mobile waves-effect waves-light" onClick={() => toggleSidebar()}>
                    <i className="fe-menu"></i>
                </button>
            </li>
            {/* <li className="d-none d-sm-block">
                <form className="app-search">
                    <div className="app-search-box">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search..." />
                            <div className="input-group-append">
                                <button className="btn" type="submit">
                                    <i className="fe-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </li> */}
        </ul>
    </div>)
}

export default Topbar;