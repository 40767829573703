import axios from "axios";
import { API_URI } from "../../api";
import { COLLAPSE_SIDEBAR, GET_REPORTED_CONTENT, EXPAND_SIDEBAR, ENABLE_LOADING, DISABLE_LOADING, GET_DASHBOARD_DATA } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { logOut } from "./authActions";
export const sidebarMenuToggle = (state) => {
    return (dispatch) => {
        let width = document.body.clientWidth;
        if (state) {
            if (width >= 767.98) { //for those devices which width is more than 767px 
                document.body.classList.add('enlarged');
            } else {
                document.body.classList.add('sidebar-enable');
            }
            dispatch({
                type: EXPAND_SIDEBAR,
                state
            })
        } else {
            if (width >= 767.98) { //for those devices which width is more than 767px 
                document.body.classList.remove('enlarged');
            } else {
                document.body.classList.remove('sidebar-enable');
            }
            dispatch({
                type: COLLAPSE_SIDEBAR,
                state
            })
        }
    }
}

export const setLoading = (state) => {
    return (dispatch) => {
        if (state) {
            dispatch({
                type: ENABLE_LOADING,
                state
            })
        } else {
            dispatch({
                type: DISABLE_LOADING,
                state
            })
        }
    }
}

export const loadDashboardData = () => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios.get(
            `${API_URI}/home/dashboard/`,
            {
                headers: authHeader()
            }).then((response) => {
                if (response.data.status) {
                    let data = response.data.results[0];
                    dispatch({
                        type: GET_DASHBOARD_DATA,
                        data
                    });
                }
            }).catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status == 401) {
                        console.log("True");
                        dispatch(logOut());
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }).finally(() => {
                dispatch(setLoading(false));
            })
    }
}


export const loadReportedContent = (pageNumber, reported_content) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/home/reports/`,
                data:
                {
                    page_number: pageNumber,
                    document_limit: 20,
                    reported_content: reported_content,
                },
                headers: authHeader(),
            }
        ).then((response) => {
            if (response.data.status) {
                const contents = response.data;
                dispatch({
                    type: GET_REPORTED_CONTENT,
                    contents
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}
