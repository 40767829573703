import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import './App.css'
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from './store/reducers/rootReducer';
import { Provider } from 'react-redux';
import Router from './routes';
import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { composeWithDevTools } from 'redux-devtools-extension';
import Loader from './components/layouts/elements/loader/Loader';
export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunk),
  // other store enhancers if any
));
export const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Loader></Loader>
      <Router />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
