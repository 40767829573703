import { Link } from "react-router-dom";
function PageNotFoundOr404() {
    return (
        <>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="fas fa-home h2"></i>
                </Link>
            </div>
            <div className="account-pages w-100 mt-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card shadow-lg mb-0">
                                <div className="card-body p-4">
                                    <div className="account-box">
                                        <div className="account-logo-box">
                                            <div className="text-center">
                                                <a href="index.html">
                                                    <img src="logo192.png" alt="The HBCU App Logo" height="80" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="account-content text-center mt-4">
                                            <h1 className="text-error">404</h1>
                                            <h3 className="text-uppercase text-danger mt-4">Page Not Found</h3>
                                            <p className="text-muted mt-3">
                                                It's looking like you may have taken a wrong turn. Don't worry... it
                                                happens to the best of us. You might want to check your internet connection. Here's a
                                                little tip that might help you get back on track.
                                            </p>
                                            <Link to="/" className="btn btn-md btn-block btn-primary waves-effect waves-light mt-3"> Return Home</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
export default PageNotFoundOr404;