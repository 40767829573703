


import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteEvent } from "../../../store/actions/eventActions";
import { Link, useNavigate } from "react-router-dom";
import { getEvents } from "../../../store/actions/eventActions";
import swal from "sweetalert";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
import Paginate from "../../pagination/Paginate";

function Events() {
    SetDocumentTitle(`Events \u2022 ${APP_NAME}`);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const { events, total_pages, page_no } = useSelector((state) => state.events.eventsData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEvents(1, ''));
    }, [dispatch])

    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteEvent(id));
            }
        });
    }
    const search = () => {
        dispatch(getEvents(1, searchInput));
    }

    const add = () => {
        navigate('/events/create')
    }
    String.prototype.trunc = function (n) {
        return this.substr(0, n - 1) + (this.length > n ? '...' : '');
    };
    const handlePageClick = (current_page) => {
        dispatch(getEvents(current_page, searchInput));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Events</h4>
                                        <p className="sub-header">
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button className="btn btn-primary" type="button" onClick={add}><i className="mdi mdi-plus-circle mr-2"></i> Add New Event</button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Title" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Booking</th>
                                                        <th>Seats/Available</th>
                                                        <th>Status</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {events && events.map((event) => {
                                                        return (
                                                            <tr key={event._id}>
                                                                <td>
                                                                    {event.image !== '' ?
                                                                        <img src={event.image} alt="" className="img-thumbnail img-fluid" width="100px" />
                                                                        :
                                                                        <img src="/assets/images/no-thumbnail.jpg" alt="" className="img-thumbnail img-fluid" width="100px" />
                                                                    }
                                                                </td>
                                                                <td><b>{event.title}</b></td>
                                                                <td>{event.description.trunc(40)}</td>
                                                                <td>{event.booking_available ? "Available" : "Not Available"}</td>
                                                                <td>{event.seats} /<b>{event.seats_availability}</b></td>
                                                                <td>{event?.is_published ? "Published" : ""}</td>
                                                                <td>{event.date?.start} </td>
                                                                <td>{event.time?.start} - {event.time?.end}</td>
                                                                <td>
                                                                    <Link to={`/events/view/${event._id}`} className="table-action-btn" title="View event">
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>
                                                                    <Link to={`/events/${event._id}`} className="table-action-btn" title="Edit event">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="event Delete" onClick={() => handleDelete(event._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div className="table-responsive">
                                                {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Events;