import { GET_PRODUCTS, GET_PRODUCT_CATEGORIES, GET_PRODUCT_SUBCATEGORIES } from "../../components/constants/actionTypes";
export const productCategoriesReducer = (state = { categories: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_PRODUCT_CATEGORIES:
            return {
                categories: action.categories.results,
                page_no: action.categories.page_no,
                total_pages: action.categories.total_pages,
            };
        default:
            return state;
    }
}


export const productSubcategoriesReducer = (state = { subcategories: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_PRODUCT_SUBCATEGORIES:
            return {
                subcategories: action.subcategories.results,
                page_no: action.subcategories.page_no,
                total_pages: action.subcategories.total_pages,
            };
        default:
            return state;
    }
}




export const productReducer = (state = { products: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                products: action.products.results,
                page_no: action.products.page_no,
                total_pages: action.products.total_pages,
            };
        default:
            return state;
    }
}