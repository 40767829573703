import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers } from "../../../store/actions/userActions";
import swal from "sweetalert";
import Paginate from '../../pagination/Paginate';
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";

function Users() {
    SetDocumentTitle(`Users \u2022 ${APP_NAME}`);
    const [searchInput, setSearchInput] = useState("");
    const { users, page_no, total_pages } = useSelector((state) => state.users);//User State from Store
    const dashboardData = useSelector((state) => state.dashboard_data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers(1, searchInput));//Get First page with empty query
    }, [dispatch])

    const add = () => {
        navigate('/users/create')
    }
    const search = () => {
        dispatch(getUsers(1, searchInput));
    }

    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteUser(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getUsers(current_page, searchInput));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Users</h4>
                                        <p className="sub-header">
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={add}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name, Email" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Profile Pic</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Dob</th>
                                                        <th>Gender</th>
                                                        <th>Verified</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users && users.map((user) => {
                                                        return (
                                                            <tr key={user._id}>
                                                                <td>
                                                                    {
                                                                        user.profile_pic === '-' || user.profile_pic === "" ?
                                                                            <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" /> :
                                                                            <img src={user.profile_pic} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" />
                                                                    }
                                                                </td>
                                                                <td>{user.name}</td>
                                                                <td>{user.email}</td>
                                                                <td>{user.dob}</td>
                                                                <td>{user.gender}</td>
                                                                {user.is_otp_verified ?
                                                                    <td>
                                                                        <i className="fas fa-check-circle text-primary"></i> Verified
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <i className="fas fa-times-circle text-danger"></i> Unverified
                                                                    </td>}
                                                                {user.user_activated ?
                                                                    <td>
                                                                        <i className="fas fa-user-check text-primary"></i> Active

                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <i className="fas fa-user-times text-danger"></i> Inactive
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={`/users/view/${user._id}`} className="table-action-btn" title="View User">
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>
                                                                    <Link to={`/users/${user._id}`} className="table-action-btn" title="Edit User">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <Link to="" onClick={() => handleDelete(user._id)} className="table-action-btn" title="User Delete">
                                                                        <i className="mdi mdi-close"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Users;