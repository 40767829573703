
import { GET_EVENTS, GET_EVENT_BOOKINGS } from "../../components/constants/actionTypes";
import { API_URI } from "../../api/index";
import { multipartFormData } from "../../services/header"
import axios from "axios";
import { setLoading } from "./dashboardActions";
import { authHeader } from "../../services/auth-header";
import { setMessage } from "./appNotificationMessageActions";
export const addEvent = (event) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/events/create`,
            data: event,
            headers: multipartFormData()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let events = responseData;
                dispatch(getEvents(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const getEvents = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/events`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                event_type: 'web',
                query: searchQuery
            },
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let events = responseData;
                dispatch({
                    type: GET_EVENTS,
                    events,
                })
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const updateEvent = (id, event) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/events/${id}`,
            headers: multipartFormData(),
            data: event,
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let events = responseData;
                dispatch(getEvents(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const deleteEvent = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/events/${id}`,
            headers: authHeader(),
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let events = responseData;
                dispatch(getEvents(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const getEventBookings = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/events/bookings`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery
            },
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let event_bookings = responseData;
                dispatch({
                    type: GET_EVENT_BOOKINGS,
                    event_bookings,
                })
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

