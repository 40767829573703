import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_OTP_VERIFICATION, RESET_PASSWORD } from "../../components/constants/actionTypes";

export const forgotPasswordReducer = (state = { user: null, is_otp_send: false, auth_token: null, is_otp_verified: false, password_reset_done: false }, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {
                user: action.user,
                is_otp_send: action.is_otp_send,
                auth_token: null,
                is_otp_verified: false,
            };
        case FORGOT_PASSWORD_OTP_VERIFICATION:
            return {
                user: action.user,
                is_otp_send: action.is_otp_send,
                auth_token: action.auth_token,
                is_otp_verified: action.is_otp_verified,
            }
        case RESET_PASSWORD:
            return {
                user: action.user,
                is_otp_send: action.is_otp_send,
                auth_token: action.auth_token,
                is_otp_verified: action.is_otp_verified,
                password_reset_done: action.password_reset_done,
            }
        default:
            return state;
    }
}