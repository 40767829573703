import { GET_USERS } from "../../components/constants/actionTypes";
const userReducer = (state = { users: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                users: action.users.results,
                page_no: action.users.page_no,
                total_pages: action.users.total_pages,
            };
        default:
            return state;
    }
}
export default userReducer;


