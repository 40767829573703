import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import PageHeader from "../../../layouts/PageHeader";
import Footer from "../../../layouts/Footer";
import RightSidebar from "../../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import Paginate from "../../../pagination/Paginate";
import { deleteProductCategory, deleteProductSubcategory, getProductCategories, getProductSubcategories } from "../../../../store/actions/marketplaceActions";
function ProductSubcategories() {
    const [searchInput, setSearchInput] = useState("");
    const { subcategories, page_no, total_pages } = useSelector((state) => state.business_subcategories);//User State from Store
    const dashboardData = useSelector((state) => state.dashboard_data);
    console.log(subcategories)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProductSubcategories(1, searchInput));
    }, [dispatch])

    const addNew = () => {
        navigate('/marketplace/subcategory/create')
    }
    const search = () => {
        dispatch(getProductSubcategories(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteProductSubcategory(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getProductSubcategories(current_page, searchInput));
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Subcategories</h4>
                                        <p className="sub-header">
                                            {dashboardData.colleges} Subcategories.
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNew}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Name</th>
                                                        <th>Category Name</th>
                                                        <th>Status</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subcategories && subcategories.map((subcategory) => {
                                                        return (
                                                            <tr key={subcategory._id}>
                                                                <td>
                                                                    {
                                                                        subcategory.image === '' || subcategory.image == undefined ?
                                                                            <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" /> :
                                                                            <img src={subcategory.image} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" />
                                                                    }
                                                                </td>
                                                                <td>{subcategory.name}</td>
                                                                <td>{subcategory?.category?.[0]?.name}</td>
                                                                <td>
                                                                    {(subcategory.is_published) ? <span><i className="fas fa-eye text-primary"></i> Published</span> : <span><i className="fas fa-eye-slash"></i> Hidden</span>}
                                                                </td>
                                                                <td>{subcategory.createdAt?.split("T")[0]}</td>
                                                                <td>
                                                                    <Link to={`/marketplace/subcategory/${subcategory._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="College Delete" onClick={() => handleDelete(subcategory._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default ProductSubcategories;