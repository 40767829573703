import { COLLAPSE_SIDEBAR, DISABLE_LOADING, ENABLE_LOADING, EXPAND_SIDEBAR, GET_DASHBOARD_DATA, GET_REPORTED_CONTENT } from "../../components/constants/actionTypes";

//Final ***
export const loaderReducer = (state = { is_loading: false }, action) => {
    switch (action.type) {
        case ENABLE_LOADING:
            return Object.assign({}, state, { is_loading: true })
        case DISABLE_LOADING:
            return Object.assign({}, state, { is_loading: false })
        default:
            return state;
    }
}

//Final ***
export const sidebarReducer = (state = {}, action) => {
    switch (action.type) {
        case COLLAPSE_SIDEBAR:
            return Object.assign({}, state, { enlarged: false })
        case EXPAND_SIDEBAR:
            return Object.assign({}, state, { enlarged: true })
        default:
            return state;
    }
}


export const dashboardReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return action.data;
        default:
            return state;
    }
}


export const reportedContentReducer = (state = { contents: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_REPORTED_CONTENT:
            return {
                contents: action.contents.results,
                page_no: action.contents.page_no,
                total_pages: action.contents.total_pages,
            }
        default:
            return state;
    }
}
