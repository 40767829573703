import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import { addChannel, updateChannel } from "../../../../store/actions/liveTvActions";
import { getTvGenres } from "../../../../store/actions/liveTvGenresActions";
function Channel() {
    SetDocumentTitle(`TV Channel \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        icon: "",
        overview: "",
        is_published: false,
        media_link: "",
        categories: "",
    });
    const [genresArray, setGenresArray] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const { channels } = useSelector((state) => state.liveTv);
    const { genres } = useSelector((state) => state.tv);
    useEffect(() => {
        dispatch(getTvGenres(1, '', 40))
        if (id) {
            setEditMode(true);
            const singleChannel = channels.find(channel => channel._id === id);
            setFormInput({
                name: singleChannel?.name,
                icon: singleChannel?.icon,
                overview: singleChannel?.overview,
                is_published: singleChannel?.is_published,
                media_link: singleChannel?.media?.hls_url,
                categories: singleChannel?.categories?.[0],
            });
            setGenresArray(genres);
        }
    }, [dispatch, id]);
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('icon', formInput.icon);
        formData.append('overview', formInput.overview);
        formData.append('media_link', formInput.media_link);
        formData.append('categories[]', formInput.categories);
        if (!editMode) {
            dispatch(addChannel(formData));
            navigator("/live-tv");
        } else {
            formData.append('is_published', formInput.is_published);
            dispatch(updateChannel(id, formData));
            navigator("/live-tv");
        }
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create TV Channel" : "Edit TV Channel"}
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">

                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >
                                                            Name
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Overview</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="5" value={formInput.overview} onChange={(e) => setFormInput({ ...formInput, overview: e.target.value })} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >
                                                            Channel Link
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.media_link} onChange={(e) => setFormInput({ ...formInput, media_link: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">
                                                            Category
                                                        </label>
                                                        <div className="col-md-4">
                                                            <select className="form-control" value={formInput.categories} onChange={(e) => setFormInput({ ...formInput, categories: e.target.value })} required={true}>
                                                                <option value="">Please choose...</option>
                                                                {genresArray && genresArray.map((genre) => {
                                                                    return (<option key={genre._id} value={genre._id}>{genre.name}</option>);
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {!editMode ? null
                                                        : <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Status</label>
                                                            <div className="col-md-2">
                                                                <div className="checkbox checkbox-primary">
                                                                    <input id="is_published" type="checkbox" onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} checked={formInput.is_published} />
                                                                    <label htmlFor="is_published">
                                                                        {(formInput.is_published) ? "Active" : "Inactive"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Icon</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, icon: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )


}
export default Channel;