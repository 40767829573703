import axios from "axios";
import { API_URI } from "../../api/index";
import { multipartFormData } from "../../services/header"
import { ADD_NEWS_FEED, DELETE_NEWS_FEED, GET_NEWS_FEEDS, UPDATE_NEWS_FEED } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";

import { API_PARAMS_VALUE } from "../../components/constants/actionTypes";
import { setLoading } from "./dashboardActions";
export const getNewsFeed = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/news-feeds`,
            data: {
                page_number: pageNumber,
                document_limit: 100,
                query: searchQuery,
            },
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                let news_feeds = response.data;
                dispatch({
                    type: GET_NEWS_FEEDS,
                    news_feeds,
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const addNewsFeed = (newsFeed) => {
    return (dispatch,) => {
        axios.post(`${API_URI}/news-feeds/create`, newsFeed,
            {
                headers: multipartFormData()
            }).then((response) => {
                if (response.data.status) {
                    let news_feed = response.data.results[0];
                    dispatch({
                        type: ADD_NEWS_FEED,
                        news_feed
                    });
                }
            }).catch((error) => {
                console.log(error)
            })
    }
}


export const updateNewsFeed = (id, news_feed) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/news-feeds/${id}`,
            data: news_feed,
            headers: multipartFormData()
        }).then((response) => {
            if (response.data.status) {
                let news_feed = response.data.results[0];
                dispatch({
                    type: UPDATE_NEWS_FEED,
                    news_feed
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const deleteNewsFeed = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/news-feeds/${id}`,
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                dispatch({
                    type: DELETE_NEWS_FEED,
                    id
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export const getNewsFromRssFeed = (body) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/news-feeds/get-news-from-rss-feed`,
            data: body,
            headers: authHeader()
        }).then((response) => {
            dispatch(getNewsFeed(1))
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}
