import axios from "axios";
import { GET_PRODUCTS, GET_PRODUCT_CATEGORIES, GET_PRODUCT_SUBCATEGORIES } from "../../components/constants/actionTypes";
import { API_URI } from "../../api";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setLoading } from "./dashboardActions";

/**
 * Done
 */
export const getProductCategories = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/marketplace/category`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                const categories = response.data;
                dispatch({
                    type: GET_PRODUCT_CATEGORIES,
                    categories
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const addProductCategory = (category) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/marketplace/category/create`,
                data: category,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                dispatch(getProductCategories(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const updateProductCategory = (id, category) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.put(`${API_URI}/marketplace/category/${id}`,
            category,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    dispatch(getProductCategories(1, ""))
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                dispatch(setLoading(false));
            })
    }
}



export const deleteProductCategory = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/marketplace/category/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getProductCategories(1, ""))
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const getProducts = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/marketplace/product/all`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                const products = response.data;
                dispatch({
                    type: GET_PRODUCTS,
                    products
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const addProduct = (product) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/marketplace/product/create`,
                data: product,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                dispatch(getProducts(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const deleteProduct = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/marketplace/product/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getProducts(1, ""))
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}


export const updateProduct = (id, product) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.put(`${API_URI}/marketplace/product/${id}`,
            product,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    dispatch(getProducts(1, ""))
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                dispatch(setLoading(false));
            })
    }
}



export const getProductSubcategories = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/marketplace/category/subcategory`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                const subcategories = response.data;
                dispatch({
                    type: GET_PRODUCT_SUBCATEGORIES,
                    subcategories
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const addProductSubcategory = (subcategory) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/marketplace/category/subcategory/create`,
                data: subcategory,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                dispatch(getProductSubcategories(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}



export const updateProductSubcategory = (id, subcategory) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/marketplace/category/subcategory/${id}`,
            data: subcategory,
            headers: multipartFormData(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getProductSubcategories(1, ""))
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const deleteProductSubcategory = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/marketplace/category/subcategory/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getProductSubcategories(1, ""))
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}