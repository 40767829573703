import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addBanner, updateBanner } from "../../../store/actions/appBannerActions";
import { toast } from "react-toastify";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";

function Banner() {
    SetDocumentTitle(`Banner Action \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        title: "",
        description: "",
        redirect_url: "",
        media: "",
        media_type: "",
        is_published: true,
        banner_type: "",
    });
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const banners = useSelector((state) => state.banners);
    useEffect(() => {
        if (id) {
            setEditMode(true);
            const singleBanner = banners.find(banner => banner._id === id);
            const capitalize = s => s && s[0].toUpperCase() + s.slice(1)
            setFormInput({
                title: singleBanner.title,
                description: singleBanner.description,
                redirect_url: singleBanner.redirect_url,
                is_published: singleBanner.is_published,
                media_type: capitalize(singleBanner?.media_type),
                banner_type: singleBanner.banner_type,
            });
        }
    }, [dispatch, id]);

    const validateAndUpload = (event) => {
        const file = event.target.files[0];
        const media_type = file.type;
        if (file !== "" && file !== undefined) {
            console.log(file.size)
            console.log(file.type)
            if (file.size >= 100000000) {
                toast.error(`File size should be less than 100 MB.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
                    {
                        toastId: 'unsupported',
                    });
                file.value = ""; //Reset file input
            } else if (!((media_type == "video/mp4") || (media_type == "image/jpeg") || (media_type == "image/png"))) {
                toast.error(`Unsupported media type.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
                    {
                        toastId: 'unsupported',
                    });
                file.value = ""; //Reset file input
            } else {
                var reader = new FileReader();
                reader.addEventListener("load", function () {
                    var dataUrl = reader.result;
                    const dimension_video = document.getElementById("dimension-video");
                    dimension_video.src = dataUrl;
                    dimension_video.addEventListener('loadedmetadata', function (e) {
                        let videoWidth = dimension_video.videoWidth;
                        let videoHeight = dimension_video.videoHeight;
                        if (videoWidth < videoHeight) {
                            //portrait video not allowed
                            console.log(dimension_video.videoWidth, dimension_video.videoHeight);
                            toast.error(`Banner should have only landscape videos.`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            },
                                {
                                    toastId: 'landscape',
                                });
                            file.value = ""; //Reset file input
                        }
                    });
                }, false);
                reader.readAsDataURL(file);
                setFormInput({ ...formInput, media: file })
            }
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', formInput.title);
        formData.append('description', formInput.description);
        formData.append('redirect_url', formInput.redirect_url);
        formData.append('media', formInput.media);
        formData.append('media_type', formInput.media_type);
        formData.append('is_published', formInput.is_published);
        formData.append('banner_type', formInput.banner_type);
        if (!editMode) {
            dispatch(addBanner(formData));
            navigator("/app-banner");
        } else {
            dispatch(updateBanner(id, formData));
            navigator("/app-banner");
        }
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Banner" : "Edit Banner"}
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">
                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Banner Type</label>
                                                        <div className="col-md-10">
                                                            <select className="form-control" value={formInput.banner_type} onChange={(e) => setFormInput({ ...formInput, banner_type: e.target.value })} required={true}>
                                                                <option value="">Please select</option>
                                                                <option value="app-home-banner" >App Home Banner</option>
                                                                {/* <option value="2">Business Banner</option>
                                                                <option value="3">Sports Banner</option>
                                                                <option value="3">Sports Banner</option> */}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Title</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.title} required={true} onChange={(e) => setFormInput({ ...formInput, title: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Description</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="5" value={formInput.description} onChange={(e) => setFormInput({ ...formInput, description: e.target.value })} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Redirect Url</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.redirect_url} required={true} onChange={(e) => setFormInput({ ...formInput, redirect_url: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Banner Type</label>
                                                        <div className="col-md-10">
                                                            <select className="form-control" required={true} value={formInput.media_type} onChange={(e) => setFormInput({ ...formInput, media_type: e.target.value })}>
                                                                <option value="">Please select</option>
                                                                <option value="Images">Images</option>
                                                                <option value="Videos">Videos</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-2 col-form-label">Status</label>
                                                        <div className="col-4">
                                                            <div className="checkbox checkbox-primary pl-1">
                                                                <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                <label htmlFor="is_published">
                                                                    {formInput.is_published == true ? "Active" : "Inactive"}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Image/Videos</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={validateAndUpload} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                    {/**Video tag for dimension */}
                                                    <video id="dimension-video" className="d-none">
                                                    </video>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )


}
export default Banner;