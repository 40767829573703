import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addBusinessAccount, addBusinessCategory, getBusinessCategories, updateBusinessAccount, updateBusinessCategory } from "../../../../store/actions/businessActions";
import { getUsers } from "../../../../store/actions/userActions";
import Select from 'react-select';
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
function BusinessAccount() {
    SetDocumentTitle(`Business Account Action \u2022 ${APP_NAME}`);
    let usersOptions = [];
    const [formInput, setFormInput] = useState({
        lat: "",
        long: "",
        place: "",
        slogan: "",
        about: "",
        dial_code: "",
        phone: "",
        business_hours: [],
        website: "",
        profile_pic: "",
        cover_pic: "",
        is_published: true,
        in_trending: false,
        name: "",
        category: "",
        id: "",
        user: {
            name: "",
            email: ""
        }
    });
    const [editMode, setEditMode] = useState(false);
    const [weekdays, setWeekdays] = useState({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
    });
    const [monday, setMonday] = useState({ open: "", closed: "", day: "Monday" });
    const [tuesday, setTuesday] = useState({ open: "", closed: "", day: "Tuesday" });
    const [wednesday, setWednesday] = useState({ open: "", closed: "", day: "Wednesday" });
    const [thursday, setThursday] = useState({ open: "", closed: "", day: "Thursday" });
    const [friday, setFriday] = useState({ open: "", closed: "", day: "Friday" });
    const [saturday, setSaturday] = useState({ open: "", closed: "", day: "Saturday" });
    const [sunday, setSunday] = useState({ open: "", closed: "", day: "Sunday" });
    const dispatch = useDispatch();
    const navigator = useNavigate();

    const { id } = useParams();
    const categories = useSelector((state) => state.business_categories);

    const { users } = useSelector((state) => state.users);
    const { accounts } = useSelector((state) => state.business_accounts);
    useEffect(() => {
        dispatch(getBusinessCategories());
        dispatch(getUsers(1, ""));
        if (id) {
            setEditMode(true);
            let singleAccount = accounts.find(account => account._id === id);
            console.log(singleAccount);
            setFormInput({
                name: singleAccount?.name,
                slogan: singleAccount?.slogan,
                about: singleAccount?.about,
                dial_code: singleAccount?.dial_code,
                phone: singleAccount?.phone,
                website: singleAccount?.website,
                is_published: singleAccount?.is_published,
                in_trending: singleAccount?.in_trending,
                category: singleAccount?.category_id,
                id: singleAccount?.user_id,
                user: singleAccount?.user,
            });
            if (singleAccount?.business_hours?.length > 0) {
                const enableDay = {};
                singleAccount?.business_hours.map((hours) => {
                    var hours = JSON.parse(hours);
                    var day = day => day.toLowerCase();
                    switch (day(hours.day)) {
                        case "monday":
                            enableDay[day(hours.day)] = true;
                            setMonday(hours);
                            break;
                        case "tuesday":
                            enableDay[day(hours.day)] = true;
                            setTuesday(hours);
                            break;
                        case "wednesday":
                            enableDay[day(hours.day)] = true;
                            setWednesday(hours);
                            break;
                        case "thursday":
                            enableDay[day(hours.day)] = true;
                            setThursday(hours);
                            break;
                        case "friday":
                            enableDay[day(hours.day)] = true;
                            setFriday(hours);
                            break;
                        case "saturday":
                            enableDay[day(hours.day)] = true;
                            setSaturday(hours);
                            break;
                        case "sunday":
                            enableDay[day(hours.day)] = true;
                            setSunday(hours);
                            break;
                    }
                    setWeekdays(enableDay);// Enable Those weekdays which value found in response... :-)
                });
            }
        }
    }, [dispatch, id]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        Object.keys(weekdays).forEach(function (key) {
            if (weekdays[key]) {
                switch (key) {
                    case "monday":
                        formData.append('business_hours', JSON.stringify(monday));
                        break;
                    case "tuesday":
                        formData.append('business_hours', JSON.stringify(tuesday));
                        break;
                    case "wednesday":
                        formData.append('business_hours', JSON.stringify(wednesday));
                        break;
                    case "thursday":
                        formData.append('business_hours', JSON.stringify(thursday));
                        break;
                    case "friday":
                        formData.append('business_hours', JSON.stringify(friday));
                        break;
                    case "saturday":
                        formData.append('business_hours', JSON.stringify(saturday));
                        break;
                    case "sunday":
                        formData.append('business_hours', JSON.stringify(sunday));
                        break;
                }
            }
        });

        formData.append('name', formInput.name);
        formData.append('about', formInput.about);
        formData.append('slogan', formInput.slogan);
        // formData.append('lat', formInput.lat)
        // formData.append('long', formInput.long);
        // formData.append('place', formInput.place);
        formData.append('dial_code', formInput.dial_code)
        formData.append('phone', formInput.phone);
        formData.append('website', formInput.website);
        formData.append('category_id', formInput.category);
        formData.append('profile_pic', formInput.profile_pic);
        formData.append('cover_pic', formInput.cover_pic);
        formData.append('in_trending', formInput.in_trending);
        formData.append('is_published', formInput.is_published);
        formData.append('id', formInput.id);
        if (!editMode) {
            dispatch(addBusinessAccount(formData));
            navigator("/business/accounts");
        } else {
            dispatch(updateBusinessAccount(id, formData));
            navigator("/business/accounts");
        }
    }

    users.map((user) => {//Map all user and store in new Array
        usersOptions.push({ value: user._id, label: `${user.name} ( ${user.email} )`, key: user._id })
    });

    function getUsersOptions(selectUser) {
        setFormInput({ ...formInput, id: selectUser?.value })
    }

    function searchUser(input) {
        dispatch(getUsers(1, input));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Business Account" : "Edit Business Account"}
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">
                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >User Account</label>
                                                        <div className="col-md-10">
                                                            <Select name="user-account" options={usersOptions} onChange={getUsersOptions} onInputChange={searchUser} />
                                                            {editMode ? <> Current User Account: <b>{formInput?.user?.name} ({formInput?.user?.email})</b></> : null}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Business Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Business Slogan</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Slogan" value={formInput.slogan} onChange={(e) => setFormInput({ ...formInput, slogan: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">About</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="5" onChange={(e) => setFormInput({ ...formInput, about: e.target.value })} value={formInput.about}  >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Business Category</label>
                                                        <div className="col-md-10">
                                                            <select className="form-control" required={true} value={formInput.category} onChange={(e) => setFormInput({ ...formInput, category: e.target.value })} defaultValue="">
                                                                <option>Please select</option>
                                                                {categories && categories.map((category) => {
                                                                    return (<option key={category._id} value={category._id}>{category.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Business Hours</label>
                                                        <div className="col-md-10">
                                                            <div className="table-responsive">
                                                                <div className="table-responsive">
                                                                    <table className="table mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Day</th>
                                                                                <th>Opening Time</th>
                                                                                <th>Closing Time</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Monday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.monday} value={monday.open} onChange={(e) => setMonday({ ...monday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.monday} value={monday.closed} onChange={(e) => setMonday({ ...monday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="monday" type="checkbox" checked={weekdays.monday} onClick={(e) => setWeekdays({ ...weekdays, monday: !weekdays.monday })} />
                                                                                        <label htmlFor="monday">{(weekdays.monday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Tuesday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.tuesday} value={tuesday.open} onChange={(e) => setTuesday({ ...tuesday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.tuesday} value={tuesday.closed} onChange={(e) => setTuesday({ ...tuesday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="tuesday" type="checkbox" checked={weekdays.tuesday} onClick={(e) => setWeekdays({ ...weekdays, tuesday: !weekdays.tuesday })} />
                                                                                        <label htmlFor="tuesday">{(weekdays.tuesday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Wednesday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.wednesday} value={wednesday.open} onChange={(e) => setWednesday({ ...wednesday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.wednesday} value={wednesday.closed} onChange={(e) => setWednesday({ ...wednesday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="wednesday" type="checkbox" checked={weekdays.wednesday} onClick={(e) => setWeekdays({ ...weekdays, wednesday: !weekdays.wednesday })} />
                                                                                        <label htmlFor="wednesday">{(weekdays.wednesday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Thursday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.thursday} value={thursday.open} onChange={(e) => setThursday({ ...thursday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.thursday} value={thursday.closed} onChange={(e) => setThursday({ ...thursday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="is_checked" type="checkbox" checked={weekdays.thursday} onClick={(e) => setWeekdays({ ...weekdays, thursday: !weekdays.thursday })} />
                                                                                        <label htmlFor="is_checked">{(weekdays.thursday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Friday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.friday} value={friday.open} onChange={(e) => setFriday({ ...friday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.friday} value={friday.closed} onChange={(e) => setFriday({ ...friday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="friday" type="checkbox" checked={weekdays.friday} onClick={(e) => setWeekdays({ ...weekdays, friday: !weekdays.friday })} />
                                                                                        <label htmlFor="friday">{(weekdays.friday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Saturday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.saturday} value={saturday.open} onChange={(e) => setSaturday({ ...saturday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.saturday} value={saturday.closed} onChange={(e) => setSaturday({ ...saturday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="saturday" type="checkbox" checked={weekdays.saturday} onClick={(e) => setWeekdays({ ...weekdays, saturday: !weekdays.saturday })} />
                                                                                        <label htmlFor="saturday">{(weekdays.saturday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Sunday</td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.sunday} value={sunday.open} onChange={(e) => setSunday({ ...sunday, open: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="time" className="form-control form-control-sm" required="required" disabled={!weekdays.sunday} value={sunday.closed} onChange={(e) => setSunday({ ...sunday, closed: e.target.value })} />
                                                                                </td>
                                                                                <td>
                                                                                    {/* <div className="checkbox checkbox-primary pl-1">
                                                                                        <input id="sunday" type="checkbox" checked={weekdays.sunday} onClick={(e) => setWeekdays({ ...weekdays, sunday: !weekdays.sunday })} />
                                                                                        <label htmlFor="sunday">{(weekdays.sunday === true) ? "Enable" : "Disable"}</label>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {editMode ? <>
                                                        <div className="form-group row">
                                                            <label className="col-2 col-form-label">Status</label>
                                                            <div className="col-4">
                                                                <div className="checkbox checkbox-primary pl-1">
                                                                    <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                    <label htmlFor="is_published">
                                                                        {formInput.is_published === true ? "Active" : "Inactive"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-2 col-form-label">Tranding</label>
                                                            <div className="col-4">
                                                                <div className="checkbox checkbox-primary pl-1">
                                                                    <input id="in_trending" type="checkbox" checked={formInput.in_trending} onChange={(e) => setFormInput({ ...formInput, in_trending: !formInput.in_trending })} />
                                                                    <label htmlFor="in_trending">
                                                                        {formInput.in_trending === true ? "Yes" : "No"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null}
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Phone</label>
                                                        <div className="col-md-10">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-2 p-0">
                                                                    <input type="text" className="form-control" placeholder="+1" value={formInput.dial_code} onChange={(e) => setFormInput({ ...formInput, dial_code: e.target.value })} />
                                                                </div>
                                                                <div className="col-10 p-0">
                                                                    <input type="text" className="form-control" placeholder="Phone" value={formInput.phone} onChange={(e) => setFormInput({ ...formInput, phone: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Website</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.website} onChange={(e) => setFormInput({ ...formInput, website: e.target.value })} />
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Profile pic</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, profile_pic: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Cover pic</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, cover_pic: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )


}
export default BusinessAccount;