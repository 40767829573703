import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import swal from "sweetalert";
import PageHeader from "../../../layouts/PageHeader";
import Footer from "../../../layouts/Footer";
import { deleteBusinessAccount, getBusinessAccounts } from "../../../../store/actions/businessActions";
import Paginate from "../../../pagination/Paginate";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";

function BusinessAccounts() {
    SetDocumentTitle(`Business Account \u2022 ${APP_NAME}`);
    const table_style = {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        width: "100%"
    }
    const [searchInput, setSearchInput] = useState("");
    const { accounts, page_no, total_pages } = useSelector((state) => state.business_accounts);
    const dashboardData = useSelector((state) => state.dashboard_data);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBusinessAccounts(1, searchInput));
    }, [dispatch])

    const addNewAccount = () => {
        navigate('/business/accounts/create');
    }

    const businessAccountsSearch = () => {
        dispatch(getBusinessAccounts(1, searchInput));
    }


    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteBusinessAccount(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getBusinessAccounts(current_page, searchInput));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Business Account</h4>
                                        <p className="sub-header">
                                            {/* {business_accounts.length} Account Fetched */}
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewAccount}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={businessAccountsSearch}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar" style={table_style}>
                                                <thead>
                                                    <tr>
                                                        <th>Profile</th>
                                                        <th>Name</th>
                                                        <th>User Account</th>
                                                        <th>About</th>
                                                        <th>Category</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {accounts && accounts.map((business_account) => {
                                                        return (
                                                            <tr key={business_account._id}>
                                                                <td>
                                                                    {
                                                                        (business_account.profile_pic === '-' || business_account.profile_pic == "" || business_account.profile_pic == undefined) ?
                                                                            <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" /> :
                                                                            <img src={business_account.profile_pic} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <h4 className="header-title">{business_account.name}</h4>
                                                                    {business_account.slogan}
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex justify-content-arround">
                                                                        {
                                                                            (business_account?.user?.profile_pic === '-' || business_account?.user?.profile_pic == "" || business_account?.user?.profile_pic == undefined) ?
                                                                                <img src="assets/images/users/no-avatar.jpg" alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" /> :
                                                                                <img src={business_account?.user?.profile_pic} alt="contact-img" title="contact-img" className="rounded-circle avatar-sm" />
                                                                        }
                                                                        <div className="pl-2">
                                                                            <h4 className="header-title">{business_account?.user?.name}</h4>
                                                                            {business_account?.user?.email}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{business_account.about}</td>
                                                                <td>
                                                                    {business_account?.business_category?.name}
                                                                </td>
                                                                <td>
                                                                    <Link to={`/business/accounts/${business_account._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="College Delete" onClick={() => handleDelete(business_account._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default BusinessAccounts;