import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { addNewsFeed, updateNewsFeed } from "../../../store/actions/newsFeedActions";
import { API_PARAMS_VALUE } from "../../constants/actionTypes";
import { getNewsCategory } from "../../../store/actions/newsCategoryActions";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function NewsFeed() {
    const [formInput, setFormInput] = useState({
        title: "",
        link: "",
        pubDate: Date(),
        description: "",
        content: "",
        source_text: "",
        source_url: "",
        channel_title: "",
        channel_image_url: "",
        featured_news: false, //True for mobile news banners screen 
        is_published: true,
        category: "",
        media: "",
    })
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { news_feeds } = useSelector((state) => state.news_feeds);
    const news_categories = useSelector((state) => state.news_categories);
    const { id } = useParams();
    console.log(formInput);
    useEffect(() => {
        dispatch(getNewsCategory(1));
        if (id) {
            setEditMode(true);
            const singleNewsFeeds = news_feeds.find(news_feed => news_feed._id == id);
            setFormInput({
                title: singleNewsFeeds?.title,
                link: singleNewsFeeds?.link,
                pubDate: singleNewsFeeds?.pubDate,
                description: singleNewsFeeds?.description,
                content: singleNewsFeeds?.content,
                source_text: singleNewsFeeds?.source.text,
                source_url: singleNewsFeeds?.source.url,
                channel_title: singleNewsFeeds?.channel.title,
                channel_image_url: singleNewsFeeds?.channel.image_url,
                featured_news: singleNewsFeeds?.featured_news, //True for mobile news banners screen 
                category: singleNewsFeeds?.category[0],
                media: singleNewsFeeds?.media_content,
                is_published: singleNewsFeeds?.is_published,
            })
        }
    }, [dispatch, id])//Load when something dispatched...


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', formInput.title);
        formData.append('link', formInput.link);
        formData.append('description', formInput.description);
        formData.append('pubDate', formInput.pubDate);
        formData.append('content', formInput.content);
        formData.append('source_text', formInput.source_text);
        formData.append('source_url', formInput.source_url);
        formData.append('channel_title', formInput.channel_title);
        formData.append('channel_image_url', formInput.channel_image_url);
        formData.append('featured_news', formInput.featured_news);
        formData.append('category[]', formInput.category);
        formData.append('media', formInput.media);
        formData.append('is_published', formInput.is_published);
        formData.append('platform', API_PARAMS_VALUE.admin_platform);
        if (!editMode) {//Create New Post 
            dispatch(addNewsFeed(formData));
            navigate('/news-feed');
        }
        else {
            dispatch(updateNewsFeed(id, formData));
            navigate('/news-feed');
        }
    }

    const reactQuillhandleChange = (html) => {
        setFormInput({ ...formInput, content: html });
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create News Feed" : "Edit News Feed"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.length} Users Fetched */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label" >Title</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" required={true} value={formInput.title} onChange={(e) => setFormInput({ ...formInput, title: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label" >Link</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" value={formInput.link} onChange={(e) => setFormInput({ ...formInput, link: e.target.value })} />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Description</label>
                                                            <div className="col-md-10">
                                                                <textarea className="form-control" rows="3" value={formInput.description} onChange={(e) => setFormInput({ ...formInput, description: e.target.value })} >
                                                                </textarea>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Channel Title</label>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control" placeholder="" value={formInput.channel_title} onChange={(e) => setFormInput({ ...formInput, channel_title: e.target.value })} />
                                                            </div>
                                                            <label className="col-md-2 col-form-label">Channel Image Url</label>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control" value={formInput.channel_image_url} onChange={(e) => setFormInput({ ...formInput, channel_image_url: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Source Text</label>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control" placeholder="" value={formInput.source_text} onChange={(e) => setFormInput({ ...formInput, source_text: e.target.value })} />
                                                            </div>
                                                            <label className="col-md-2 col-form-label">Source Url</label>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control" value={formInput.source_url} onChange={(e) => setFormInput({ ...formInput, source_url: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Is Featured News</label>
                                                            <div className="col-md-2">
                                                                <div className="checkbox checkbox-primary">
                                                                    <input id="featured_news" type="checkbox" checked={formInput.featured_news} onChange={(e) => setFormInput({ ...formInput, featured_news: !formInput.featured_news })} />
                                                                    <label htmlFor="featured_news">
                                                                        {(formInput.featured_news) ? "Featured news" : ""}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <label className="col-md-2 col-form-label">Category</label>
                                                            <div className="col-md-2">
                                                                <div className="col-md-10">
                                                                    <select className="form-control" value={formInput.category} onChange={(e) => setFormInput({ ...formInput, category: e.target.value })}>
                                                                        <option value="">Please choose</option>
                                                                        {news_categories && news_categories.map((news_category) => {
                                                                            return (<option key={news_category._id} value={news_category._id}>{news_category.name}</option>);
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <label className="col-md-2 col-form-label">Status</label>
                                                            <div className="col-md-2">
                                                                <div className="checkbox checkbox-primary">
                                                                    <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                    <label htmlFor="is_published">
                                                                        {(formInput.is_published) ? "Active" : "InActive"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Content</label>
                                                            <div className="col-md-10">
                                                                {/* onChange={(e) => setFormInput({ ...formInput, content: e.target.value })} */}
                                                                <ReactQuill value={formInput.content} onChange={reactQuillhandleChange} modules={{
                                                                    toolbar: [
                                                                        [{ 'header': [1, 2, false] }],
                                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                                        ['link', 'image'],
                                                                        ['clean']
                                                                    ],
                                                                }} formats={[
                                                                    'header',
                                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                                    'list', 'bullet', 'indent',
                                                                    'link', 'image'
                                                                ]} />
                                                                {/* <textarea className="form-control" rows="5" value={formInput.content} onChange={(e) => setFormInput({ ...formInput, content: e.target.value })} >
                                                                </textarea> */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Media content</label>
                                                            <div className="col-md-10">
                                                                <input type="file" accept="image/png, image/jpg, image/png" required={(formInput.featured_news && !editMode) ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, media: e.target.files[0] })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button type="submit" className="btn btn-primary mb-2">
                                                                {!editMode ? "Submit" : "Update"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )
}

export default NewsFeed;