import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import Paginate from "../../pagination/Paginate";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
import { getMediaContent } from "../../../store/actions/mediaContentActions";
function MediaContent() {
    SetDocumentTitle(`HBCU TV Censor Board \u2022 ${APP_NAME}`);
    const [searchInput, setSearchInput] = useState("");
    const { content, page_no, total_pages } = useSelector((state) => state.mediaContent);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMediaContent(1, searchInput));
    }, [dispatch])

    const addNewCollege = () => {
        navigate('/colleges/create')
    }
    const collegeSearch = () => {
        dispatch(getMediaContent(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                //dispatch(deleteCollege(id))
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getMediaContent(current_page, searchInput));
    }
    String.prototype.trunc = function (n) {
        return this.substr(0, n - 1) + (this.length > n ? '...' : '');
    };
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Media Content</h4>
                                        <p className="sub-header">
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewCollege}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={collegeSearch}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Cover Image</th>
                                                        <th width="30%">Name</th>
                                                        <th>Type / Maturity Rating</th>
                                                        <th>Released Date</th>
                                                        <th>Director</th>
                                                        <th>Create</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {content && content.map((media_content) => {
                                                        return (
                                                            <tr key={media_content._id}>
                                                                <td>
                                                                    <img src={media_content?.cover_image} alt="" className="img-thumbnail img-fluid" width="100px" />
                                                                </td>
                                                                <td>
                                                                    <h5 className="m-0">{media_content?.name}</h5>
                                                                    <p>
                                                                        {media_content?.overview.trunc(300)}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        {media_content?.type.toUpperCase()}
                                                                    </p>
                                                                    <b>{media_content?.maturity_rating} </b>
                                                                </td>
                                                                <td>{media_content?.released_date}</td>
                                                                <td>{media_content?.director}</td>
                                                                <td>
                                                                    {media_content.createdAt?.split("T")[0]}
                                                                </td>
                                                                <td>
                                                                    <Link to={`/content/view/${media_content.type}/${media_content._id}`} className="table-action-btn" title="View">
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>
                                                                    <Link to={`/content/${media_content.type}/${media_content._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="Media Delete" onClick={() => handleDelete(media_content._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default MediaContent;