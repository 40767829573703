import { useCookies } from "react-cookie";
import axios from "axios";
import { API_URI } from "../../api/index";
import { GET_USERS, DELETE_USER, ADD_USER } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { setLoading } from "./dashboardActions";
import { logOut } from "./authActions";
import { multipartFormData } from "../../services/header";
import { setMessage } from "./appNotificationMessageActions";
export const getUsers = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true))
        axios({
            method: 'POST',
            url: `${API_URI}/users`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                const users = response.data;
                dispatch({
                    type: GET_USERS,
                    users
                })
            }
        }).catch((error) => {

        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export const deleteUser = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/users/${id}`,
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getUsers(1));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const addUser = (user) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/register`,
            data: user,
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getUsers(1));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const updateUser = (user) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/users/update-profile`,
            data: user,
            headers: multipartFormData(),
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getUsers(1));
                dispatch(setMessage(responseData.status_code, `Account updated.`, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

