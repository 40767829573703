import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import PageHeader from "../../../layouts/PageHeader";
import Footer from "../../../layouts/Footer";
import RightSidebar from "../../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import Paginate from "../../../pagination/Paginate";
import { noThumbnail, SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import { getMediaContent } from "../../../../store/actions/mediaContentActions";
import { deleteChannel, getChannels } from "../../../../store/actions/liveTvActions";
import { deleteTvGenres, getTvGenres } from "../../../../store/actions/liveTvGenresActions";

function TvGenres() {
    SetDocumentTitle(`TV Genres \u2022 ${APP_NAME}`);
    const [searchInput, setSearchInput] = useState("");
    const { genres, page_no, total_pages } = useSelector((state) => state.tv);
    console.log(useSelector((state) => state.tv));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTvGenres(1, searchInput));
    }, [dispatch])

    const addNew = () => {
        navigate('/live-tv/genres/create')
    }
    const search = () => {
        dispatch(getTvGenres(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteTvGenres(id));
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getTvGenres(current_page, searchInput));
    }
    String.prototype.trunc = function (n) {
        return this.substr(0, n - 1) + (this.length > n ? '...' : '');
    };
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Tv Genres</h4>
                                        <p className="sub-header">
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNew}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Icon</th>
                                                        <th>Name</th>
                                                        <th>Status</th>
                                                        <th>Created At</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {genres && genres.map((genre) => {
                                                        return (
                                                            <tr key={genre._id}>
                                                                <td>
                                                                    {(genre?.icon !== '' && genre?.icon !== undefined) ? <img src={genre?.icon} alt="" className="img-thumbnail img-fluid" width="100px" /> : noThumbnail()}
                                                                </td>
                                                                <td>
                                                                    <h5 className="m-0">{genre?.name}</h5>
                                                                </td>
                                                                <td>
                                                                    {genre?.is_published ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    {genre.createdAt?.split("T")[0]}
                                                                </td>
                                                                <td>
                                                                    <Link to={`/live-tv/genres/${genre._id}`} className="table-action-btn" title="Edit">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="Delete" onClick={() => handleDelete(genre._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default TvGenres;