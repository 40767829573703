

import { LOGIN_SUCCESS, LOGOUT, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_OTP_VERIFICATION, RESET_PASSWORD, } from "../../components/constants/actionTypes"
import axios from "axios";
import { API_URI } from "../../api/index";
import { setLoading } from "./dashboardActions";
import { setMessage } from "./appNotificationMessageActions";

export const loginStart = (loginCredentials) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/login`,
            data: loginCredentials,
        }).then((response) => {
            if (response.data.status) {
                let auth_token = response.headers['x-auth-token'];
                let responseData = response.data;
                let user = responseData.results[0];
                localStorage.setItem('auth-token', auth_token);//Store authentication token in local storage.
                dispatch({
                    type: LOGIN_SUCCESS,
                    user,
                })
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const logOut = () => {
    return (dispatch) => {
        dispatch({
            type: LOGOUT,
        });
        dispatch(setMessage(200, 'Logged Out.', true));
    }
}

export const forgotPasswordRequest = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/forgot-password`,
            data: data,
        }).then((response) => {
            if (response.data.status && response.data.status_code === 200) {
                let responseData = response.data;
                let user = responseData.results[0];
                let is_otp_send = true;
                dispatch({
                    type: FORGOT_PASSWORD_REQUEST,
                    user,
                    is_otp_send,
                });
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const forgotPasswordOTPVerification = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/forgot-password-otp-verification`,
            data: data,
        }).then((response) => {
            if (response.data.status && response.data.status_code === 200) {
                let responseData = response.data;
                let user = responseData.results[0];
                let auth_token = response.headers['x-auth-token'];
                let is_otp_send = true;
                let is_otp_verified = true;
                dispatch({
                    type: FORGOT_PASSWORD_OTP_VERIFICATION,
                    user,
                    is_otp_send,
                    auth_token,
                    is_otp_verified,
                });
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const resetPassword = (data, header_data) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/reset-password`,
            headers: header_data, //Required for api authentication...
            data: data,
        }).then((response) => {
            if (response.data.status && response.data.status_code === 200) {
                let responseData = response.data;
                let user = responseData.results[0];
                let auth_token = header_data['x-auth-token'];
                let is_otp_send = true;
                let is_otp_verified = true;
                let password_reset_done = true;
                dispatch({
                    type: RESET_PASSWORD,
                    user,
                    is_otp_send,
                    auth_token,
                    is_otp_verified,
                    password_reset_done,
                });
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                let responseData = response.data;
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error.toString(), false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}





