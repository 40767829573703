


import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { deletePlaylist, getPlaylists } from "../../../store/actions/shortsActions";
import swal from "sweetalert";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function Playlists() {
    SetDocumentTitle(`Playlist \u2022 ${APP_NAME}`);
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState({
        name: "",
        description: "",
        thumbnail: "",
        createdAt: "",
    })

    let navigator = useNavigate();

    const playlists = useSelector((state) => state.playlists);
    const addPlaylist = () => {
        navigator("/playlists/create")
    }

    const { id } = useParams(); //View Playlist in modal

    const displayModalData = (event) => {
        if (id) {
            const singlePlaylist = playlists.find(playlist => playlist._id == id);
            console.log(singlePlaylist);
            setModalData({
                name: singlePlaylist.name,
                description: singlePlaylist.description,
                thumbnail: singlePlaylist.thumbnail,
                createdAt: singlePlaylist.createdAt,
            })
        }
    }

    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(deletePlaylist(id))
                }
            });
    }


    useEffect(() => {
        dispatch(getPlaylists(1));
    }, [dispatch, id])//Load when something dispatched...

    return (
        <>
            <div id="wrapper">

                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div id="playlist-view-modal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: 'none', padding: 1 + "rem" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title">Playlist</h4>
                            </div>
                            <div className="modal-body">
                                <div className="avatar-xl member-thumb mx-auto">
                                    {
                                        modalData.thumbnail ?
                                            <img src={modalData.thumbnail} className="img-fluid avatar-lg" alt="profile-image" />
                                            :
                                            <img src="/assets/images/playlist/playlist-thumb.jpg" className="img-fluid avatar-lg" alt="profile-image" />
                                    }
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">{modalData.name}</h4>
                                    <p className="text-muted small">Posted at <span> : </span>
                                        <span>
                                            {modalData.createdAt}
                                        </span>
                                    </p>
                                </div>
                                <p className="text-muted text-justify">
                                    {modalData.description}
                                </p>
                            </div>
                            <div className="modal-footer border-0">
                                <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Playlists</h4>
                                        <p className="sub-header">
                                            {playlists.length} Playlists Fetched
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addPlaylist}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <input id="demo-input-search2" type="text" placeholder="Search" className="form-control" autoComplete="off" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                        <th>Posted At</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {playlists && playlists.map((playlist) => {
                                                        return (
                                                            <tr key={playlist._id}>
                                                                <td>
                                                                    {
                                                                        playlist.thumbnail ?
                                                                            <img src={playlist.thumbnail} alt="image" className="img-fluid avatar-lg" />
                                                                            :
                                                                            <img src="/assets/images/playlist/playlist-thumb.jpg" alt="image" className="img-fluid avatar-lg" />
                                                                    }
                                                                </td>
                                                                <td>{playlist.name}</td>
                                                                <td>{playlist.description}</td>
                                                                <td>{playlist.createdAt}</td>
                                                                <td style={{ width: "18%" }}>
                                                                    <Link to={`/playlists/view/${playlist._id}`} data-toggle="modal" data-target="#playlist-view-modal" className="table-action-btn" title="View Playlist" onClick={displayModalData}>
                                                                        <i className="mdi mdi-eye"></i>
                                                                    </Link>

                                                                    <Link to={`/playlists/${playlist._id}`} className="table-action-btn" title="Edit Playlist">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <Link to="#" onClick={() => handleDelete(playlist._id)} className="table-action-btn" title="Delete Playlist"  >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Playlists;