import { APP_NAME, CURRENT_YEAR, PREV_YEAR } from "../constants/uiTypes";
function Footer() {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {PREV_YEAR} - {CURRENT_YEAR} © {APP_NAME}
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;