import { ADD_BUSINESS_CATEGORY, DELETE_BUSINESS_CATEGORY, GET_BUSINESS_ACCOUNTS, GET_BUSINESS_CATEGORIES, UPDATE_BUSINESS_CATEGORY } from "../../components/constants/actionTypes";

export const businessCategoryReduceer = (state = [], action) => {
    switch (action.type) {
        case GET_BUSINESS_CATEGORIES:
            return action.categories;
        case ADD_BUSINESS_CATEGORY:
            return [action.category, ...state];
        case DELETE_BUSINESS_CATEGORY:
            return state.filter(category => {
                if (category._id === action.id) {
                    return false;
                } else {
                    return true;
                }
            });
        case UPDATE_BUSINESS_CATEGORY:
            state.map((category) => {
                if (category._id === action.category._id) {
                    return false;
                }
                return true;
            });
        default:
            return state;
    }
}

export const businessAccountReducer = (state = { accounts: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_BUSINESS_ACCOUNTS:
            return {
                accounts: action.accounts.results,
                page_no: action.accounts.page_no,
                total_pages: action.accounts.total_pages,
            };
        // case ADD_BUSINESS_ACCOUNT:
        //     return [action.account, ...state];
        // case DELETE_BUSINESS_ACCOUNT:
        //     return state.filter(business_account => {
        //         if (business_account._id === action.id) {
        //             return false;
        //         } else {
        //             return true;
        //         }
        //     });
        default:
            return state;
    }
}