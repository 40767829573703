import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

function Post() {
    const [post, setPost] = useState({
        post_title: "",
        post_body: "",
    })
    const [editMode, setEditMode] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const posts = useSelector((state) => state.posts);
    const auth_user = useSelector((state) => state.auth_user);
    console.log(auth_user);


    const { id } = useParams();
    useEffect(() => {
        if (id) {
            const singlePost = posts.find(post => post.id == id);
            setPost({
                ...post,
                post_title: singlePost.post_title,
                post_body: singlePost.post_body,
            })
            setEditMode(true);
        }
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editMode) {
            // dispatch(addPost(post));
        }
        else {
            //dispatch(updatePost(post, id))
        }
        setPost({
            text_content: "",
            location: "",
            images: "",
            videos: "",
            has_multimedia: "",
            multimedia_types: "",
            user_id: ","
        });
        navigate('/posts')
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create Post" : "Edit Post"}
                                        </h4>
                                        <p className="sub-header">
                                            Your awesome text goes here.
                                        </p>
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Post Body</label>
                                                            <div className="col-md-10">
                                                                <textarea className="form-control" rows="5" onChange={(e) => setPost({ ...post, post_body: e.target.value })} value={post.post_body} >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Images</label>
                                                            <div className="col-md-10">
                                                                <input type="file" accept="image/mp3" required style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Videos</label>
                                                            <div className="col-md-10">
                                                                <input type="file" accept="image/mp3" required style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-md-2 col-form-label">Location</label>
                                                            <div className="col-md-10">
                                                                <div className="contact-map">
                                                                    <iframe src="https://www.google.com/maps/embed/v1/place?q=New+York+University&key=AIzaSyBSFRN6WWGYwmFi498qXXsD2UwkbmD74v4"
                                                                        style={{ width: "100%", height: "360px", border: 0, margin: 0, "overflow": "hidden" }}></iframe>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button type="submit" className="btn btn-primary mb-2">
                                                                {!editMode ? "Submit" : "Update"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}


export default Post;