import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { APP_NAME } from "../../constants/uiTypes";
import { SetDocumentTitle } from "../../helper/functions";
function SingleUser() {
    SetDocumentTitle(`View User \u2022 ${APP_NAME}`);
    const [user, setUser] = useState({//Default user state
        name: "",
        dob: "",
        gender: "",
        email: "",
        phone: "",
        address: "",
        is_otp_verified: "",
        user_activated: "",
        profile_pic: "",
        cover_pic: "",
        otp: "",
        college_id: "",
        followers: "",
        following: "",
        login_with: ""
    })

    const [coverBackground, setCoverBackground] = useState({ // Profile cover image default background..
        backgroundImage: "url('/assets/images/bg-profile.jpg')"
    })
    const { id } = useParams(); //Get Params ID
    const users = useSelector((state) => state.users.users);//Get All Users Form Store
    useEffect(() => {
        if (id) {
            const selectedUser = users.find((user) => user._id == id)
            setUser({
                ...user,
                name: selectedUser.name,
                phone: selectedUser.phone,
                email: selectedUser.email,
                dob: selectedUser.dob,
                cover_pic: selectedUser.cover_pic,
                profile_pic: selectedUser.profile_pic,
                is_otp_verified: selectedUser.is_otp_verified,
                user_activated: selectedUser.user_activated,
                followers: selectedUser.followers_count,
                following: selectedUser.following_count,
                otp: selectedUser.otp ? selectedUser.otp : "",
                gender: selectedUser.gender,
                login_with: selectedUser.accounts_type[0].login_with,
            })

            if (user.cover_pic !== undefined && user.cover_pic !== "") { //Set user background image
                setCoverBackground({
                    backgroundImage: `url('${user.cover_pic}')`
                })
            }
        }
    }, [id])
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="profile-bg-picture" style={coverBackground}>
                                        <span className="picture-bg-overlay"></span>
                                    </div>
                                    <div className="profile-user-box">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <span className="float-left mr-3">
                                                    {
                                                        user.profile_pic === '-' || user.profile_pic === "" ?
                                                            <img src="assets/images/users/no-avatar.jpg" className="avatar-xl rounded-circle" /> :
                                                            <img src={user?.profile_pic} className="avatar-xl rounded-circle" />
                                                    }
                                                </span>
                                                <div className="media-body">
                                                    <h4 className="mt-1 mb-1 font-18 ellipsis">{user.name}</h4>
                                                    {/* <p className="font-13">
                                                     {user?.college}
                                                    </p> */}
                                                    <p className="text-muted mb-0">

                                                        {user.is_otp_verified ?
                                                            <small>
                                                                <i className="fas fa-check-circle text-primary"></i> Verified
                                                            </small>
                                                            :
                                                            <small>
                                                                <i className="fas fa-times-circle text-danger"></i> Unverified
                                                            </small>
                                                        }

                                                        &nbsp; &nbsp;
                                                        {user.user_activated ?
                                                            <small>
                                                                <i className="fas fa-user-check text-primary"></i> Active

                                                            </small>
                                                            :
                                                            <small>
                                                                <i className="fas fa-user-times text-danger"></i> Inactive
                                                            </small>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="text-right">
                                                    <Link to={`/users/${id}`} className="btn btn-success waves-effect waves-light">
                                                        <i className="mdi mdi-account-settings-variant mr-1"></i> Edit Profile
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <h4 className="header-title mt-0 mb-4">Personal Information</h4>
                                        <div className="panel-body">
                                            <p className="text-muted font-13">
                                                {/* Hye, I’m Johnathan Doe residing in this beautiful world. I create websites and mobile apps with great UX and UI design. I have done work with big companies like Nokia, Google and Yahoo. Meet me or Contact me for any queries. One Extra line for filling space. Fill as many you want. */}
                                            </p>
                                            <div className="text-left">
                                                <p className="text-muted font-13"><strong>Full Name :</strong>
                                                    <span className="ml-3">
                                                        {user.name}
                                                    </span>
                                                </p>

                                                <p className="text-muted font-13"><strong>Mobile :</strong>
                                                    <span className="ml-3">
                                                        {user.phone}
                                                    </span>
                                                </p>

                                                <p className="text-muted font-13"><strong>Email :</strong>
                                                    <span className="ml-3">
                                                        {user.email}
                                                    </span>
                                                </p>

                                                <p className="text-muted font-13"><strong>Date of Birth :</strong>
                                                    <span className="ml-3">
                                                        {user.dob}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>Gender:</strong>
                                                    <span className="ml-3">
                                                        {user.gender}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>Login with:</strong>
                                                    <span className="ml-3">
                                                        {user.login_with.toUpperCase()}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div id="dashboard-1" className="widget-box-four-chart"><canvas width="94" height="40" ></canvas></div>
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Total Revenue">Followers</h4>

                                                    <h3 className="mb-0 mt-4"> <span>{user.followers}</span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div id="dashboard-2" className="widget-box-four-chart"><canvas width="94" height="40" ></canvas></div>
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Total Unique Visitors">Following</h4>

                                                    <h3 className="mb-0 mt-4"> <span>{user.following}</span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div id="dashboard-3" className="widget-box-four-chart"><canvas width="94" height="40" ></canvas></div>
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="OTP">OTP</h4>
                                                    <h3 className="mb-0 mt-4"><span>{user.otp}</span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default SingleUser;