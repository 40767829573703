
import { PUBLIC_LOGIN_SUCCESS, PUBLIC_LOGOUT, } from "../../components/constants/actionTypes";

const initialState = {
    isLoggedIn: false,
    user: null,
    token: null,
}
const publicAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case PUBLIC_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.user,
                token: action.token,
            }
        case PUBLIC_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export default publicAuthReducer;