function TermsAndConditions() {
    return (
        <>
            <section className="section pt-4">
                <div className="container">
                    <div className="card bg-light">
                        <div className="card-header">
                        </div>
                        <div className="card-body" >
                            <a className="navbar-brand" href="">
                                <img src="logo101.png" width="40" height="40" alt="" />
                            </a>
                            <h1 className="app-h1">Terms And Conditions</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default TermsAndConditions;