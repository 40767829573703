import { SET_MESSAGE, CLEAR_MESSAGE } from "../../components/constants/actionTypes";


export const setMessage = (messageCode, message, type) => {
    return (dispatch) => {
        let messageObj = {
            'code': messageCode,
            'message': message,
            'notificationType': type,
            'notificationTimestamp': new Date()
        };
        dispatch({
            type: SET_MESSAGE,
            messageObj
        })
    }
}

export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
});