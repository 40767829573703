import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-awesome-slider/dist/styles.css';
import Paginate from "../../pagination/Paginate";
import { loadReportedContent } from "../../../store/actions/dashboardActions";
function Reports() {

    const [reported_content, setReportedContent] = useState("user");
    const { contents, page_no, total_pages } = useSelector((state) => state.reported_content);
    console.log(contents);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadReportedContent(1, reported_content))
    }, [dispatch])

    const handlePageClick = (current_page) => {
        dispatch(loadReportedContent(current_page, reported_content));
    }
    const handleBtnClick = () => {
        dispatch(loadReportedContent(1, reported_content));
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">Reported Content</h4>
                                        {/* <p className="sub-header">
                                            Contents.
                                        </p> */}
                                        <div className="mb-3">
                                            <div class="row">
                                                <div class="col-12 text-sm-center form-inline justify-content-arround">
                                                    <div class="form-group">
                                                        <select id="reported_content" class="form-control mr-2" value={reported_content} onChange={(e) => setReportedContent(e.target.value)}>
                                                            <option value="">Choose Reported Content</option>
                                                            <option value="user">User</option>
                                                            <option value="shorts">Shorts</option>
                                                            <option value="post">Post</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group  inline-block">
                                                        <button id="demo-btn-addrow" class="btn btn-primary" type="button" onClick={handleBtnClick}>
                                                            <i class="mdi mdi-eye mr-2"></i>Show</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table id="datatable-buttons" class="table table-striped dt-responsive nowrap table-actions-bar">
                                            <thead>
                                                <tr>
                                                    <th>Content</th>
                                                    <th>Reported By</th>
                                                    <th>Content Type</th>
                                                    <th>Reported Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    contents && contents.map((reported_content) => {
                                                        let content_title;
                                                        if (reported_content.content_type == "user") {
                                                            content_title = reported_content?.content?.name;
                                                        }
                                                        if (reported_content.content_type == "shorts") {
                                                            content_title = reported_content?.content?.title;
                                                        }
                                                        if (reported_content.content_type == "post") {
                                                            content_title = reported_content?.content?.text_content;
                                                        }
                                                        return (<tr key={reported_content._id}>
                                                            <td >
                                                                {content_title}
                                                            </td>
                                                            <td>
                                                                {
                                                                    reported_content?.reported_by?.name
                                                                }
                                                            </td>
                                                            <td className="text-capitalize">
                                                                {
                                                                    reported_content.content_type
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    reported_content?.createdAt?.split('T')[0]
                                                                }
                                                            </td>
                                                        </tr>);
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                        <div className="table-responsive">

                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Reports;