import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";
import { logOut } from "../../../store/actions/publicAuthActions";

function PublicNavbar(props) {
    const dispatch = useDispatch();
    const performLogOut = () => {
        dispatch(logOut());
    }
    return (
        <nav className="navbar navbar-light bg-light">
            <div className="container">
                <div className="d-flex justify-content-between w-100">
                    <a className="navbar-brand" href="#">
                        <img src="logo101.png" width="40" height="40" alt="HBCU LOGO" />
                    </a>
                    <ul className="list-unstyled topnav-menu float-right my-auto">
                        <li className="dropdown notification-list">
                            <a className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                <img src={props.image} alt="Kiran's avatar" className="rounded-circle" />
                                <span className="pro-user-name ml-1">
                                    {props.name}
                                    <i className="mdi mdi-chevron-down"></i>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">
                                        Welcome {props.name}!
                                    </h6>
                                </div>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item notify-item" onClick={performLogOut}>
                                    <i className="fe-log-out"></i>
                                    <span>Logout</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
export default PublicNavbar;