import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function SingleEvent() {
    SetDocumentTitle(`View Event \u2022 ${APP_NAME}`);
    const [event, setEvent] = useState({
        title: "",
        description: "",
        lat: "",
        long: "",
        place: "",
        price: 0,
        venue_location: "",
        time_zone: "",
        seats: 0,
        seats_availability: 0,
        booking_available: false,
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        is_online: false,
        is_published: true,
        is_paid: false,
        link: "",
        image: "",
    })

    const [coverBackground, setCoverBackground] = useState({
        backgroundImage: "url('/assets/images/bg-profile.jpg')"
    })
    const dispatch = useDispatch();
    const { id } = useParams(); //Get Params ID
    const { events, total_pages, page_no } = useSelector((state) => state.events.eventsData);

    useEffect(() => {
        if (id) {
            const selectedEvent = events.find((event) => event._id == id)
            setEvent({
                ...event,
                title: selectedEvent.title,
                description: selectedEvent.description,
                lat: "",
                long: "",
                place: selectedEvent.location?.place,
                event: 0,
                venue_location: selectedEvent.venue_location,
                time_zone: "",
                seats: selectedEvent.seats,
                seats_availability: selectedEvent.seats_availability,
                price: selectedEvent.price,
                start_date: selectedEvent.date?.start,
                end_date: selectedEvent.date?.end,
                start_time: selectedEvent.time?.start,
                end_time: selectedEvent.time?.end,
                is_online: false,
                is_published: true,
                is_paid: false,
                link: "",
                image: selectedEvent?.image,
                booking_available: selectedEvent.booking_available,
            });
        }

    }, [id])


    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="profile-bg-picture" style={coverBackground}>
                                        <span className="picture-bg-overlay"></span>
                                    </div>
                                    <div className="profile-user-box">
                                        <div className="row">
                                            <div className="col-sm-6">

                                                <span className="float-left mr-3">
                                                    {(event?.image !== '') && (event?.image !== null) ?
                                                        <img src={event.image} alt="" className="img-thumbnail img-fluid" width="100px" />
                                                        :
                                                        <img src="/assets/images/no-thumbnail.jpg" alt="" className="img-thumbnail img-fluid" width="100px" />
                                                    }
                                                </span>
                                                <div className="media-body">
                                                    <h4 className="mt-1 mb-1 font-22 ellipsis">
                                                        {event.title}
                                                    </h4>

                                                    <p className="font-13">
                                                        {event.university}
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="text-right">
                                                    {/* <button type="button" className="btn btn-success waves-effect waves-light">
                                                        <i className="mdi mdi-account-settings-variant mr-1"></i> Edit Profile
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <h4 className="header-title mt-0 mb-4">About</h4>
                                        <div className="panel-body">
                                            <p className="text-muted font-13">
                                                {event.description}
                                            </p>
                                            <div className="text-left">
                                                <p className="text-muted font-13"><strong>Price :</strong>
                                                    <span className="ml-3">
                                                        ${event.price}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>Location :</strong>
                                                    <span className="ml-3">
                                                        {event.place}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>Venue :</strong>
                                                    <span className="ml-3">
                                                        {event.venue_location}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Date/Time">Date/Time :</h4>
                                                    <p className="font-secondary text-muted"><h5>{event.start_date} - {event.end_date}</h5></p>
                                                    <h3 className="mb-0 mt-4"> <span> {event.start_time} - {event.end_time}</span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Seats/Available">Seats/Available :</h4>
                                                    <p className="font-secondary text-muted"><h5>&nbsp;</h5></p>
                                                    <h3 className="mb-0 mt-4"> <span> {event.seats}/  {event.seats_availability}</span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Booking">Booking :</h4>
                                                    <p className="font-secondary text-muted"><h5>&nbsp;</h5></p>
                                                    <h3 className="mb-0 mt-4"><span>{event.booking_available ? "Available" : "Not Available"}</span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default SingleEvent;
