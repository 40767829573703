
import { LOGIN_SUCCESS, LOGIN_FAILED, CLEAR_AUTHENTICATION_ERROR, LOGOUT } from "../../components/constants/actionTypes";

const initialState = {
    isLoggedIn: false,
    user: null,
    error: null
}
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.user
            }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}
export default authReducer;