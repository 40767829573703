import axios from "axios";
import { API_URI } from "../../api";
import { ADD_BUSINESS_ACCOUNT, ADD_BUSINESS_CATEGORY, DELETE_BUSINESS_ACCOUNT, DELETE_BUSINESS_CATEGORY, GET_BUSINESS_ACCOUNTS, GET_BUSINESS_CATEGORIES, UPDATE_BUSINESS_CATEGORY } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { setLoading } from "./dashboardActions";
import { multipartFormData } from "../../services/header";
export const getBusinessCategories = () => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/business-account/category`,
            data: {
                page_number: 1,
                document_limit: 40,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let categories = response.data.results;
                dispatch({
                    type: GET_BUSINESS_CATEGORIES,
                    categories
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const addBusinessCategory = (category) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/business-account/category/create`,
                data: category,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let category = response.data.results[0];
                dispatch({
                    type: ADD_BUSINESS_CATEGORY,
                    category
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}


export const deleteBusinessCategory = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/business-account/category/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                console.log(response);
                dispatch({
                    type: DELETE_BUSINESS_CATEGORY,
                    id
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}


export const updateBusinessCategory = (id, category) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.put(`${API_URI}/business-account/category/${id}`,
            category,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    let category = response.data.results[0];
                    dispatch({
                        type: UPDATE_BUSINESS_CATEGORY,
                        category
                    })
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                dispatch(setLoading(false));
            })
    }
}


export const addBusinessAccount = (account) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/business-account/create`,
                data: account,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                dispatch(getBusinessAccounts(1, ""));
                // let account = response.data.results[0];
                // dispatch({
                //     type: ADD_BUSINESS_ACCOUNT,
                //     account
                // });
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}


export const getBusinessAccounts = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/business-account`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                let accounts = response.data;
                dispatch({
                    type: GET_BUSINESS_ACCOUNTS,
                    accounts
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const deleteBusinessAccount = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/business-account/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                dispatch(getBusinessAccounts(1, ""));
                // dispatch({
                //     type: DELETE_BUSINESS_ACCOUNT,
                //     id
                // });
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const updateBusinessAccount = (id, account) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.put(`${API_URI}/business-account/${id}`,
            account,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    dispatch(getBusinessAccounts(1, ""));
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                dispatch(setLoading(false));
            })
    }
}
