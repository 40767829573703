import { GET_EVENTS, GET_EVENT_BOOKINGS } from "../../components/constants/actionTypes";
const eventReducer = (state = {
    eventsData: { events: [], page_no: 1, total_pages: 1, },
    eventsBookingsData: { event_bookings: [], page_no: 1, total_pages: 1, },
}, action) => {
    switch (action.type) {
        case GET_EVENTS:
            return {
                ...state,
                eventsData: {
                    events: action.events.results,
                    page_no: action.events.page_no,
                    total_pages: action.events.total_pages,
                }
            };
        case GET_EVENT_BOOKINGS:
            return {
                ...state,
                eventsBookingsData: {
                    event_bookings: action.event_bookings.results,
                    page_no: action.event_bookings.page_no,
                    total_pages: action.event_bookings.total_pages,
                }
            };
        default:
            return state;
    }
}

export default eventReducer;