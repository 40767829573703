import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import RightSidebar from "../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
import { getSingleMediaContent, updateSingleMediaContentu, getGenre, getLanguages, getCategories } from "../../../store/actions/mediaContentActions";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
function EditMediaContent() {
    SetDocumentTitle(`Edit Content \u2022 ${APP_NAME}`);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id, type } = useParams();
    const { single_content, languages, genres, categories } = useSelector((state) => state.mediaContent);
    console.log(useSelector((state) => state.mediaContent));


    const [formInput, setFormInput] = useState({
        _id: "",
        thumbnail: "",
        cover_image: "",
        overview: "",
        genres: [],
        selected_genres: [],
        languages: [],
        selected_languages: [],
        starring: [],
        selected_starring: [],
        categories: [],
        selected_categories: [],
        director: "",
        rating: "",
        duration: "",
        maturity_rating: "",
        released_date: "",
        year: 2020,
        type: "",
        name: "",
        media: "",
        series: [],
        is_published: false,
        in_trending: false,
        is_featured: false,
    });
    useEffect(() => {
        if (id && type) {
            if (formInput?._id != id) {
                dispatch(getGenre('genre-wise'));
                dispatch(getCategories('category-wise'))
                dispatch(getLanguages());
                dispatch(getSingleMediaContent(id, type));
            }
            let Selected_genres = [];
            let Selected_languages = [];
            let Selected_starring = [];
            let Selected_categories = [];
            single_content?.languages?.map((data) => {
                languages.filter(language => language.value === data).map((language) => {
                    Selected_languages.push(language);
                })
            });
            let GenreIDs = [];
            single_content?.genres?.map((data) => {

                //For Genres Listing 
                genres.filter(genre => genre.label === data.name).map((genre) => {
                    Selected_genres.push(genre);//Only for display
                    GenreIDs.push(genre.value);//Array of IDs For Update Api Call.
                })
                //For Custom Category Listing 
                categories.filter(genre => genre.label === data.name).map((genre) => {
                    Selected_categories.push(genre);//Only for display
                })


            });
            single_content?.starring?.map((data) => {
                Selected_starring.push({ label: data, value: data })
            });


            setFormInput({
                ...formInput,
                _id: single_content?._id,
                name: single_content?.name,
                overview: single_content?.overview,
                director: single_content?.director,
                rating: single_content?.rating,
                duration: single_content?.duration,
                cover_image: single_content?.cover_image,
                thumbnail: single_content?.thumbnail,
                maturity_rating: single_content?.maturity_rating,
                released_date: single_content?.released_date,
                released_year: single_content?.released_date,
                type: single_content?.type,
                languages: single_content?.languages,
                starring: single_content?.starring,
                media: single_content?.media,
                genres: GenreIDs,
                selected_genres: Selected_genres,
                selected_starring: Selected_starring,
                selected_languages: Selected_languages,
                selected_categories: Selected_categories,
                is_published: single_content?.is_published,
                in_trending: single_content?.in_trending,
                is_featured: single_content?.is_featured,
            });
        }
    }, [id, type, single_content]);
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('overview', formInput.overview);
        formData.append('type', formInput.type);
        formData.append('released_date', formInput.released_date);
        formData.append('maturity_rating', formInput.maturity_rating);
        formData.append('rating', formInput.rating);
        formData.append('thumbnail', formInput.thumbnail);
        formData.append('cover_image', formInput.cover_image);
        formData.append('director', formInput.director);
        formData.append('is_published', formInput.is_published);
        formData.append('in_trending', formInput.in_trending);
        formData.append('is_featured', formInput.is_featured);
        for (let i = 0; i < formInput.genres.length; i++) {
            formData.append("genres", formInput.genres[i]);
        }
        for (let i = 0; i < formInput.categories.length; i++) { //Append categories in genres array for api custom app listing....
            formData.append("genres", formInput.categories[i]);
        }

        for (let i = 0; i < formInput.languages.length; i++) {
            formData.append("languages", formInput.languages[i]);
        }
        for (let i = 0; i < formInput.starring.length; i++) {
            formData.append("starring", formInput.starring[i]);
        }
        /**
         * Api Call
         */
        dispatch(updateSingleMediaContentu(formData, id, type));
        navigator('/content');
    }
    const getCategoriesOptions = (categories_options) => {
        var category = [];
        categories_options.map((option) => {
            category.push(option.value);
        });
        setFormInput({ ...formInput, categories: category });
    }
    const getGenresOptions = (genre_options) => {
        var genre = [];
        genre_options.map((option) => {
            genre.push(option.value);
        });
        setFormInput({ ...formInput, genres: genre, selected_genres: genre_options });
    }
    const getLanguagesOptions = (language_options) => {
        var language = [];
        language_options.map((option) => {
            language.push(option.value);
        })
        setFormInput({ ...formInput, languages: language, selected_languages: language_options });
    }
    const getStarsOptions = (stars_options) => { //Cast of movie //tv-show //web-series
        var starring = [];
        stars_options.map((option) => {
            starring.push(option.value.trim());
        })
        setFormInput({ ...formInput, starring: starring, selected_starring: stars_options });
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            Edit Content
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">
                                                <form onSubmit={handleSubmit}>
                                                    <fieldset >
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Media type</label>
                                                                    <select className="form-control" onChange={(event) => setFormInput({ ...formInput, type: event.target.value })} required={true} value={formInput?.type} disabled>
                                                                        <option value="">Please choose ...</option>
                                                                        <option value="movie">Movie</option>
                                                                        <option value="tv-series">Tv Series</option>
                                                                    </select>
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Maturity ratings</label>
                                                                    <select className="form-control" onChange={(event) => setFormInput({ ...formInput, maturity_rating: event.target.value })} required={true} value={formInput.maturity_rating}>
                                                                        <option value="">Please choose ...</option>
                                                                        <option value="G">General Audiences (G)</option>
                                                                        <option value="PG">Parental Guidance Suggested (PG)</option>
                                                                        <option value="PG-13">Parents Strongly Cautioned (PG-13)</option>
                                                                        <option value="R">Restricted (R)</option>
                                                                        <option value="NC-17">Clearly Adult (NC-17)</option>
                                                                        <option value="NR">Not Rated (NR)</option>
                                                                        <option value="UR">Unrated (UR)</option>
                                                                        <option value="TV-Y">All Children (TV-Y)</option>
                                                                        <option value="TV-Y7">Directed to Older Children (TV-Y7)</option>
                                                                        <option value="TV-Y7 FV">Directed to Older Children - Fantasy Violence (TV-Y7 FV)</option>
                                                                        <option value="TV-G">General Audience (TV-G)</option>
                                                                        <option value="TV-PG">Parental Guidance Suggested (TV-PG)</option>
                                                                        <option value="TV-14">Parents Strongly Cautioned (TV-14)</option>
                                                                        <option value="TV-MA">Mature Audience Only (TV-MA)</option>
                                                                    </select>
                                                                </div>
                                                            </div>  
                                                            <div className="col-md-2">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Release Date</label>
                                                                    <input type="date" className="form-control" id="" onChange={(event) => setFormInput({ ...formInput, released_date: event.target.value })} required={true} value={formInput?.released_date} />
                                                                </div>
                                                            </div>
                                                             <div className="col-md-12">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Name</label>
                                                                    <input type="text" className="form-control" id="" placeholder="eg. The Great Debaters" onChange={(event) => setFormInput({ ...formInput, name: event.target.value })} required={true} value={formInput?.name} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Overview</label>
                                                                    <textarea className="form-control" rows={3} placeholder="overview ...." onChange={(event) => setFormInput({ ...formInput, overview: event.target.value })} value={formInput?.overview}></textarea>
                                                                </div>
                                                            </div>
                                                        <div className="col-md-12">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Custom App Category</label>
                                                                    <Select
                                                                        closeMenuOnSelect={false}
                                                                        components={animatedComponents}
                                                                        value={formInput?.selected_categories}
                                                                        isMulti
                                                                        options={categories}
                                                                        onChange={getCategoriesOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Languages</label>
                                                                    <Select
                                                                        closeMenuOnSelect={false}
                                                                        components={animatedComponents}
                                                                        value={formInput?.selected_languages}
                                                                        isMulti
                                                                        options={languages}
                                                                        onChange={getLanguagesOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Genre</label>
                                                                    <Select
                                                                        closeMenuOnSelect={false}
                                                                        components={animatedComponents}
                                                                        value={formInput?.selected_genres}
                                                                        isMulti
                                                                        options={genres}
                                                                        onChange={getGenresOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Starring (Cast)</label>
                                                                    <CreatableSelect
                                                                        isMulti
                                                                        onChange={getStarsOptions}
                                                                        required={true}
                                                                        value={formInput?.selected_starring}
                                                                    />
                                                                    <small>Use enter to add</small>
                                                                </div>
                                                            </div>
                                                             <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Director</label>
                                                                    <input type="text" className="form-control" id="" placeholder="eg. Denzel Washington" onChange={(event) => setFormInput({ ...formInput, director: event.target.value })} required={true} value={formInput?.director} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Rating (0.0)</label>
                                                                    <input type="tel" className="form-control" onChange={(event) => setFormInput({ ...formInput, rating: event.target.value })} required={true} value={formInput?.rating} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Is Published</label>
                                                                    <div>
                                                                        <input type="checkbox" className="checkbox" onClick={(event) => setFormInput({ ...formInput, is_published: !formInput.is_published })} checked={formInput.is_published} />
                                                                        <label htmlFor="" className="ml-2 form-label">
                                                                            {(formInput?.is_published) ? "Yes" : "No"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">In Trending</label>
                                                                    <div>
                                                                        <input type="checkbox" onClick={(event) => setFormInput({ ...formInput, in_trending: !formInput?.in_trending })} checked={formInput?.in_trending} />
                                                                        <label htmlFor="" className="ml-2 form-label">
                                                                            {(formInput.in_trending) ? "Yes" : "No"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Is Featured</label>
                                                                    <div>
                                                                        <input type="checkbox" onClick={(event) => setFormInput({ ...formInput, is_featured: !formInput.is_featured })} checked={formInput?.is_featured} />
                                                                        <label htmlFor="" className="ml-2 form-label">
                                                                            {(formInput.is_featured) ? "Yes" : "No"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Cover image</label><br />
                                                                    <input type="file" onChange={(event) => setFormInput({ ...formInput, cover_image: event.target.files[0] })} />
                                                                </div>
                                                                <div className="mb-2">
                                                                    <span className="mr-1">Current Image </span>
                                                                    <a href={formInput?.cover_image} target="_blank" >View </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <label htmlFor="" className="form-label">Thumbnail</label><br />
                                                                    <input type="file" onChange={(event) => setFormInput({ ...formInput, thumbnail: event.target.files[0] })} />
                                                                </div>
                                                                <div className="mb-2">
                                                                    <span className="mr-1">Current Thumbnail</span>
                                                                    <a href={formInput?.thumbnail} target="_blank" >View</a>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default EditMediaContent;