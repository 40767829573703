import { GET_COLLEGES } from "../../components/constants/actionTypes";
const collegeReducer = (state = { colleges: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_COLLEGES:
            return {
                colleges: action.colleges.results,
                page_no: action.colleges.page_no,
                total_pages: action.colleges.total_pages,
            };
        default:
            return state;
    }
}

export default collegeReducer;