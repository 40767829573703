import axios from "axios";
import { GET_COLLEGES, ADD_COLLEGE, UPDATE_COLLEGE, DELETE_COLLEGE } from "../../components/constants/actionTypes";
import { API_URI } from "../../api";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setLoading } from "./dashboardActions";


/**
 * Done
 */
export const getColleges = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'POST',
            url: `${API_URI}/colleges`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                const colleges = response.data;
                dispatch({
                    type: GET_COLLEGES,
                    colleges
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

/**
 * Done
 */
export const addCollege = (college) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/colleges/create`,
                data: college,
                headers: multipartFormData(),
            }
        ).then((response) => {
            if (response.data.status) {
                let college = response.data.results[0];
                // dispatch({
                //     type: ADD_COLLEGE,
                //     college
                // })
                dispatch(getColleges(1, ""));
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}


export const updateCollege = (id, college) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.put(`${API_URI}/colleges/${id}`,
            college,
            {
                headers: multipartFormData()
            })
            .then((response) => {
                if (response.data.status) {
                    let college = response.data.results[0];
                    // dispatch({
                    //     type: UPDATE_COLLEGE,
                    //     college
                    // })
                    dispatch(getColleges(1, ""))
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                dispatch(setLoading(false));
            })
    }
}


export const deleteCollege = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/colleges/${id}`,
            headers: authHeader(),
        }).then((response) => {
            if (response.data.status) {
                // dispatch({
                //     type: DELETE_COLLEGE,
                //     id
                // });
                dispatch(getColleges(1, ""))
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}