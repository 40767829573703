function DashboardTiles(props) {
    return (
        <div className="col-xl-3 col-sm-6">
            <div className="card-box widget-box-two widget-two-custom ">
                <div className="media">
                    <div className="avatar-lg rounded-circle bg-primary widget-two-icon align-self-center">
                        <i className={`${props.data_icons} avatar-title font-30 text-white`}></i>
                    </div>
                    <div className="wigdet-two-content media-body">
                        <p className="m-0 text-uppercase font-weight-medium text-truncate" title={props.data_title}>{props.data_title}</p>
                        <h3 className="font-weight-medium my-2">
                            <span data-plugin="counterup">{props.data_count}</span>
                        </h3>
                        <p className="m-0">{props.data_subtitle}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DashboardTiles;