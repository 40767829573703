import { APP_NAME } from "../constants/uiTypes";
function PageHeader() {
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">{APP_NAME}</a></li>
                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                            {/* <li className="breadcrumb-item active">Dashboard 1</li> */}
                        </ol>
                    </div>
                    <h4 className="page-title">Dashboard</h4>
                </div>
            </div>
        </div>
    )
}
export default PageHeader;