import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser, getUsers, updateUser } from "../../../store/actions/userActions";
import { useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";

function User() {
    SetDocumentTitle(`User Action \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        email: "",
        password: "",
        register_type: "email",
        gender: 1,
        dob: '',
        device_id: "WEB",
        notification_token: "WEB",
        device_type: "WEB",
        user_activated: true,
        is_otp_verified: false,
        phone: "",
        profile_pic: "",
        cover_pic: "",
    });
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const users = useSelector((state) => state.users.users);


    useEffect(() => {
        if (id) {
            const singleUser = users.find(user => user._id == id);
            let genderValue;
            switch (singleUser?.gender) { //Set Gender String in UI...
                case "Male":
                    genderValue = 1;
                    break;
                case "Female":
                    genderValue = 2;
                    break;
                case "Others":
                    genderValue = 3;
                    break;
            }
            setFormInput({
                name: singleUser?.name,
                email: singleUser?.email,
                dob: singleUser?.dob,
                is_otp_verified: singleUser?.is_otp_verified,
                user_activated: singleUser?.user_activated,
                phone: singleUser?.phone,
                address: singleUser?.address,
                gender: genderValue,
            });
            setEditMode(true);
        }
    }, [dispatch, id]);



    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editMode) {
            dispatch(addUser(formInput));
            navigator("/users");
        } else {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('name', formInput.name);
            formData.append('phone', formInput.phone);
            formData.append('address', formInput.address);
            formData.append('dob', formInput.dob);
            formData.append('is_otp_verified', formInput.is_otp_verified);
            formData.append('user_activated', formInput.user_activated);
            formData.append('gender', formInput.gender);
            if (formInput.profile_pic != "" && formInput.profile_pic != undefined) {
                formData.append('profile_pic', formInput.profile_pic);
            }
            if (formInput.cover_pic != "" && formInput.cover_pic != undefined) {
                formData.append('cover_pic', formInput.cover_pic);
            }
            dispatch(updateUser(formData));
            navigator('/users');
        }
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create User" : "Edit User"}
                                        </h4>
                                        <div className="row">
                                            <div className="col-12">
                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Full Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Email</label>
                                                        <div className="col-md-10">
                                                            <input type="email" disabled={editMode ? true : false} className="form-control" placeholder="Email" value={formInput.email} onChange={(e) => setFormInput({ ...formInput, email: e.target.value })} required={true} />
                                                        </div>
                                                    </div>


                                                    {!editMode ?
                                                        <div className="form-group row">
                                                            <label className="col-md-2 col-form-label">Password</label>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control" value={formInput.password} onChange={(e) => setFormInput({ ...formInput, password: e.target.value })} required={true} />
                                                                <small><b>Password should be combination of one uppercase, one lower case, one special char, one digit and min 8 char long.</b></small>
                                                            </div>
                                                        </div> : null}

                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" htmlFor="">Date of birth</label>
                                                        <div className="col-md-10">
                                                            <input className="form-control" type="date" name="date" value={formInput.dob} onChange={(e) => setFormInput({ ...formInput, dob: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" htmlFor="example-textarea">Gender</label>
                                                        <div className="col-md-10">
                                                            <select className="form-control" value={formInput.gender} onChange={(e) => setFormInput({ ...formInput, gender: e.target.value })} required={true}>
                                                                <option value="1">Male</option>
                                                                <option value="2">Female</option>
                                                                <option value="3">Others</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {editMode ?
                                                        <>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Phone</label>
                                                                <div className="col-md-10">
                                                                    <input type="tel" className="form-control" value={formInput.phone} onChange={(e) => setFormInput({ ...formInput, phone: e.target.value })} />
                                                                    <small><b>+1</b></small>

                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Address</label>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" value={formInput.address} onChange={(e) => setFormInput({ ...formInput, address: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Status</label>
                                                                <div className="col-md-2">
                                                                    <div className="checkbox checkbox-primary">
                                                                        <input id="is_published" type="checkbox" checked={formInput?.user_activated ? true : false} onChange={(e) => setFormInput({ ...formInput, user_activated: !formInput.user_activated })} />
                                                                        <label htmlFor="is_published">
                                                                            {(formInput.user_activated) ? "Active" : "Inactive"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Verified</label>
                                                                <div className="col-md-2">
                                                                    <div className="checkbox checkbox-primary">
                                                                        <input id="is_verified" type="checkbox" checked={formInput?.is_otp_verified ? true : false} onChange={(e) => setFormInput({ ...formInput, is_otp_verified: !formInput.is_otp_verified })} />
                                                                        <label htmlFor="is_verified">
                                                                            {(formInput.is_otp_verified) ? "Verified" : "Unverified"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Profile pic</label>
                                                                <div className="col-md-10">
                                                                    <input type="file" accept="" style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, profile_pic: e.target.files[0] })} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Cover pic</label>
                                                                <div className="col-md-10">
                                                                    <input type="file" accept="" style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, cover_pic: e.target.files[0] })} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null}
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )


}
export default User;