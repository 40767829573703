import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function SingleCollege() {
    SetDocumentTitle(`View College \u2022 ${APP_NAME}`);
    const [college, setCollege] = useState({//Default user state
        name: "",
        email: "",
        description: "",
        university: "",
        address: "",
        image: "",
        phone: "",
        dial_code: "",
        address_line1: "",
        city: "",
        state: "",
        zip_code: 0,
        country: "",
        country_code: "",
        primary_color: "#9e2313",
        secondary_color: "#000000",
        tertiary_color: "#FFFFFF",
    })

    const [coverBackground, setCoverBackground] = useState({ // Profile cover image default background..
        backgroundImage: "url('/assets/images/bg-profile.jpg')"
    })

    const { id } = useParams(); //Get Params ID
    const { colleges } = useSelector((state) => state.colleges);//Get All Users Form Store
    useEffect(() => {
        if (id) {
            const selectedCollege = colleges.find((college) => college._id == id)
            console.log(selectedCollege);
            setCollege({
                ...college,
                name: selectedCollege.name,
                email: selectedCollege.email,
                description: selectedCollege.description,
                university: selectedCollege.university,
                address: selectedCollege.address,
                image: selectedCollege.image,
                phone: selectedCollege.phone,
                dial_code: selectedCollege.dial_code,
                primary_color: selectedCollege.theme_colors.primary,
                secondary_color: selectedCollege.theme_colors.secondary,
                tertiary_color: selectedCollege.theme_colors.tertiary,
            });
        }
    }, [id])


    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="profile-bg-picture" style={coverBackground}>
                                        <span className="picture-bg-overlay"></span>
                                    </div>
                                    <div className="profile-user-box">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <span className="float-left mr-3">
                                                    {college.image !== '' ?
                                                        <img src={college.image} alt="" className=" rounded" height={"80px"} />
                                                        :
                                                        <img src="/assets/images/users/no-avatar.jpg" alt="" className=" rounded" height={"80px"} />
                                                    }
                                                </span>
                                                <div className="media-body">
                                                    <h4 className="mt-1 mb-1 font-22 ellipsis">
                                                        {college.name}
                                                    </h4>
                                                    <p className="font-13">
                                                        {college.university}
                                                    </p>
                                                    <p className="text-muted mb-0">
                                                        &nbsp; &nbsp;
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <h4 className="header-title mt-0 mb-4">College Information</h4>
                                        <div className="panel-body">
                                            <p className="text-muted font-13">
                                                {college.description}
                                            </p>
                                            <div className="text-left">
                                                <p className="text-muted font-13"><strong>Email :</strong>
                                                    <span className="ml-3">
                                                        {college.email}
                                                    </span>
                                                </p>

                                                <p className="text-muted font-13"><strong>Phone :</strong>
                                                    <span className="ml-3">
                                                        {college.dial_code}   {college.phone}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>Address :</strong>
                                                    <span className="ml-3">
                                                        {college.address?.address_line1}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>City :</strong>
                                                    <span className="ml-3">
                                                        {college.address?.city}, {college.address?.state} {college.address?.zip_code}
                                                    </span>
                                                </p>
                                                <p className="text-muted font-13"><strong>Country/Country code :</strong>
                                                    <span className="ml-3">
                                                        {college.address?.country} ({college.address?.country_code})
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div id="dashboard-1" className="widget-box-four-chart"><canvas width="94" height="40" ></canvas></div>
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Primary Color">Primary color</h4>
                                                    <p className="font-secondary text-muted">{college.primary_color}</p>
                                                    <h3 className="mb-0 mt-1">
                                                        <span className="pl-4 pr-4 border" style={{ "backgroundColor": college.primary_color?.split('_')[0] }}>
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div id="dashboard-2" className="widget-box-four-chart"><canvas width="94" height="40" ></canvas></div>
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Secondary Color">Secondary color</h4>
                                                    <p className="font-secondary text-muted">{college.secondary_color}</p>
                                                    <h3 className="mb-0 mt-1">
                                                        <span className="pl-4 pr-4 border" style={{ "backgroundColor": college.secondary_color?.split('_')[0] }}>
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="card-box widget-box-four">
                                                <div id="dashboard-3" className="widget-box-four-chart"><canvas width="94" height="40" ></canvas></div>
                                                <div className="float-left">
                                                    <h4 className="mt-0 font-16 mb-1 text-overflow" title="Tertiary Color">Tertiary color</h4>

                                                    <p className="font-secondary text-muted">{college.tertiary_color}</p>
                                                    <h3 className="mb-0 mt-1">
                                                        <span className="pl-4 pr-4 border" style={{ "backgroundColor": college.tertiary_color?.split('_')[0] }}>
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default SingleCollege;
