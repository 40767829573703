import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import PageHeader from "../../../layouts/PageHeader";
import Footer from "../../../layouts/Footer";
import RightSidebar from "../../../layouts/RightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import Paginate from "../../../pagination/Paginate";
import { noThumbnail, SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import { getMediaContent } from "../../../../store/actions/mediaContentActions";
import { deleteChannel, getChannels } from "../../../../store/actions/liveTvActions";

function LiveTv() {
    SetDocumentTitle(`Live Tv \u2022 ${APP_NAME}`);
    const [searchInput, setSearchInput] = useState("");
    const { channels, page_no, total_pages } = useSelector((state) => state.liveTv);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getChannels(1, searchInput));
    }, [dispatch])

    const addNew = () => {
        navigate('/live-tv/create')
    }
    const search = () => {
        dispatch(getChannels(1, searchInput));
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteChannel(id));
            }
        });
    }
    const handlePageClick = (current_page) => {
        dispatch(getChannels(current_page, searchInput));
    }
    String.prototype.trunc = function (n) {
        return this.substr(0, n - 1) + (this.length > n ? '...' : '');
    };
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Tv Channels</h4>
                                        <p className="sub-header">
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline justify-content-between">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNew}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input id="user-search" type="text" className="form-control" placeholder="Name" autoComplete="off" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-light-o waves-effect waves-light" type="button" onClick={search}>
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar">
                                                <thead>
                                                    <tr>
                                                        <th>Icon</th>
                                                        <th>Channel Name</th>
                                                        <th>Create</th>
                                                        <th>Status</th>
                                                        <th>Url</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {channels && channels.map((channel) => {
                                                        return (
                                                            <tr key={channel._id}>
                                                                <td>
                                                                    {(channel?.icon !== '' && channel?.icon !== undefined) ? <img src={channel?.icon} alt="" className="img-thumbnail img-fluid" width="100px" /> : noThumbnail()}
                                                                </td>
                                                                <td>
                                                                    <h5 className="m-0">{channel?.name}</h5>
                                                                    <p>
                                                                        {channel?.overview}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {channel?.is_published ? "Active" : "Inactive"}
                                                                </td>

                                                                <td>
                                                                    {channel.createdAt?.split("T")[0]}
                                                                </td>
                                                                <td>
                                                                    <a href={channel?.media?.hls_url} target="_blank">
                                                                        View
                                                                        <svg style={{ 'width': '24px', 'height': '24px' }} viewBox="0 0 28 28">
                                                                            <path fill="#6F6F6F" ng-attr-d="{{icon.data}}" d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"></path>
                                                                        </svg>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/live-tv/${channel._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="Media Delete" onClick={() => handleDelete(channel._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {total_pages > 1 ? <Paginate page_no={page_no} total_pages={total_pages} onClick={handlePageClick}></Paginate> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default LiveTv;