import axios from "axios"
import { API_URI } from "../../api"
import { GET_CHANNELS } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setMessage } from "./appNotificationMessageActions";
import { setLoading } from "./dashboardActions";

export const getChannels = (pageNumber, searchQuery) => {
    return (dispatch,) => {
        dispatch(setLoading(true))
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/live-tv`,
            data: {
                page_number: pageNumber,
                document_limit: 20,
                query: searchQuery,
            },
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let channels = responseData;
                dispatch({
                    type: GET_CHANNELS,
                    channels
                })
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export const deleteChannel = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/hbcu-tv/live-tv/${id}`,
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getChannels(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const addChannel = (channel) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/hbcu-tv/live-tv/create`,
                data: channel,
                headers: multipartFormData(),
            }
        ).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getChannels(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const updateChannel = (id, channel) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/hbcu-tv/live-tv/${id}`,
            data: channel,
            headers: multipartFormData(),
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getChannels(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}