import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import Footer from "../../layouts/Footer";
import PageHeader from "../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCollege, getColleges, updateCollege } from "../../../store/actions/collegeActions";
import { useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function College() {
    SetDocumentTitle(`College Action \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        email: "",
        description: "",
        university: "",
        image: "",
        logo: "",
        app_logo: "",
        phone: "",
        dial_code: "",
        address_line1: "",
        city: "",
        state: "",
        zip_code: 99950,
        country: "",
        country_code: "",
        primary_color: "default_color",
        secondary_color: "default_color",
        tertiary_color: "default_color",

    });
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();
    const navigator = useNavigate();

    const { id } = useParams();
    const { colleges } = useSelector((state) => state.colleges);
    useEffect(() => {
        dispatch(getColleges());
        if (id) {
            setEditMode(true);
            const singleCollege = colleges.find(college => college._id === id);
            setFormInput({
                name: singleCollege.name,
                email: singleCollege.email,
                description: singleCollege.description,
                university: singleCollege.university,
                image: "",
                phone: singleCollege.phone,
                dial_code: singleCollege.dial_code,
                address_line1: singleCollege.address.address_line1,
                city: singleCollege.address.city,
                state: singleCollege.address.state,
                zip_code: singleCollege.address.zip_code,
                country: singleCollege.address.country,
                country_code: singleCollege.address.country_code,
                primary_color: singleCollege.theme_colors.primary,
                secondary_color: singleCollege.theme_colors.secondary,
                tertiary_color: singleCollege.theme_colors.tertiary,
            });
        }
    }, [dispatch, id])


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('email', formInput.email);
        formData.append('description', formInput.description);
        formData.append('university', formInput.university);
        formData.append('image', formInput.image);
        formData.append('logo', formInput.logo);
        formData.append('app_logo', formInput.app_logo);
        formData.append('phone', formInput.phone);
        formData.append('dial_code', formInput.dial_code);
        formData.append('address_line1', formInput.address_line1);
        formData.append('city', formInput.city);
        formData.append('state', formInput.state);
        formData.append('zip_code', formInput.zip_code ?? 0);
        formData.append('country', formInput.country);
        formData.append('country_code', formInput.country_code);
        formData.append('primary_color', formInput.primary_color);
        formData.append('secondary_color', formInput.secondary_color);
        formData.append('tertiary_color', formInput.tertiary_color);
        if (!editMode) {
            dispatch(addCollege(formData));
            navigator("/colleges")
        } else {
            dispatch(updateCollege(id, formData));
            navigator('/colleges');
        }
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create College" : "Edit College"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.all_users.count} Users Found */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">

                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="Name" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Email</label>
                                                        <div className="col-md-10">
                                                            <input type="email" className="form-control" placeholder="Email" value={formInput.email} onChange={(e) => setFormInput({ ...formInput, email: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">About</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="5" onChange={(e) => setFormInput({ ...formInput, description: e.target.value })} value={formInput.description}  >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">University</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="University" value={formInput.university} onChange={(e) => setFormInput({ ...formInput, university: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Phone</label>
                                                        <div className="col-md-10">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-2 p-0">
                                                                    <input type="text" className="form-control" placeholder="+1" value={formInput.dial_code} onChange={(e) => setFormInput({ ...formInput, dial_code: e.target.value })} />
                                                                </div>
                                                                <div className="col-10 p-0">
                                                                    <input type="text" className="form-control" placeholder="Phone" value={formInput.phone} onChange={(e) => setFormInput({ ...formInput, phone: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Street</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="eg. Main Street" value={formInput.address_line1} onChange={(e) => setFormInput({ ...formInput, address_line1: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">City/State/Zip</label>
                                                        <div className="col-md-10">
                                                            <div className="row ">
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control" placeholder="" value={formInput.city} onChange={(e) => setFormInput({ ...formInput, city: e.target.value })} />
                                                                </div>
                                                                <div className="col-3 ">
                                                                    <input type="text" className="form-control" placeholder="" value={formInput.state} onChange={(e) => setFormInput({ ...formInput, state: e.target.value })} />
                                                                </div>
                                                                <div className="col-3">
                                                                    <input type="number" required={true} className="form-control" placeholder="" value={formInput.zip_code} onChange={(e) => setFormInput({ ...formInput, zip_code: e.target.value })} min={1} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">County/Country code</label>
                                                        <div className="col-md-10">
                                                            <div className="row ">
                                                                <div className="col-8">
                                                                    <input type="text" className="form-control" placeholder="United States" value={formInput.country} onChange={(e) => setFormInput({ ...formInput, country: e.target.value })} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <input type="text" className="form-control" placeholder="U.S." value={formInput.country_code} onChange={(e) => setFormInput({ ...formInput, country_code: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Theme Colors</label>
                                                        <div className="col-md-10">
                                                            <div className="row ">
                                                                <div className="col-4">
                                                                    <label className="col-form-label">Primary Color</label>
                                                                    {/* <select className="form-control" value={formInput.primary_color} onChange={(e) => setFormInput({ ...formInput, primary_color: e.target.value })} >
                                                                        <option value="default_color" >Default</option>
                                                                        <option value="red_color" >Red</option>
                                                                        <option value="green_color" >Green</option>
                                                                        <option value="pink_color" >Pink</option>
                                                                        <option value="yellow_color" >Yellow</option>
                                                                        <option value="orange_color">Orange</option>
                                                                        <option value="purple_color" >Purple</option>
                                                                        <option value="blue_color" >Blue</option>
                                                                    </select> */}
                                                                    <input type="color" className="form-control" placeholder="United States" value={formInput.primary_color} onChange={(e) => setFormInput({ ...formInput, primary_color: e.target.value })} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <label className="col-form-label">Secondary Color</label>
                                                                    {/* <select className="form-control" value={formInput.secondary_color} onChange={(e) => setFormInput({ ...formInput, secondary_color: e.target.value })} >
                                                                        <option value="default_color" >Default</option>
                                                                        <option value="red_color" >Red</option>
                                                                        <option value="green_color" >Green</option>
                                                                        <option value="pink_color" >Pink</option>
                                                                        <option value="yellow_color" >Yellow</option>
                                                                        <option value="orange_color">Orange</option>
                                                                        <option value="purple_color" >Purple</option>
                                                                        <option value="blue_color" >Blue</option>
                                                                    </select> */}
                                                                    <input type="color" className="form-control" placeholder="United States" value={formInput.secondary_color} onChange={(e) => setFormInput({ ...formInput, secondary_color: e.target.value })} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <label className="col-form-label">Tertiary Color</label>
                                                                    {/* <select className="form-control" value={formInput.tertiary_color} onChange={(e) => setFormInput({ ...formInput, tertiary_color: e.target.value })} >
                                                                        <option value="default_color" >Default</option>
                                                                        <option value="red_color" >Red</option>
                                                                        <option value="green_color" >Green</option>
                                                                        <option value="pink_color" >Pink</option>
                                                                        <option value="yellow_color" >Yellow</option>
                                                                        <option value="orange_color">Orange</option>
                                                                        <option value="purple_color" >Purple</option>
                                                                        <option value="blue_color" >Blue</option>
                                                                    </select> */}
                                                                    <input type="color" className="form-control" placeholder="United States" value={formInput.tertiary_color} onChange={(e) => setFormInput({ ...formInput, tertiary_color: e.target.value })} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Logo</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, logo: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">App Logo</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, app_logo: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Image</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, image: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <RightSidebar></RightSidebar>
        </>
    )


}
export default College;