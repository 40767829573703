import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from './components/pages/dashboard';
import Login from "./components/auth/Login";
import Users from "./components/pages/users";
import User from "./components/pages/users/create";
import Colleges from "./components/pages/college";
import College from "./components/pages/college/create";
import Posts from "./components/pages/post";
import Post from "./components/pages/post/create";
import SingleUser from "./components/pages/users/view";
import Playlists from "./components/pages/playlist/index";
import Playlist from "./components/pages/playlist/create";
import App from "./App";
import Songs from "./components/pages/song/index";
import Song from "./components/pages/song/create";
import ForgotPassword from "./components/auth/ForgotPassword";
import ProtectedRoute from "./protected-routes";
import { useDispatch, useSelector } from "react-redux";
import SingleCollege from "./components/pages/college/view";
import NewsFeeds from "./components/pages/news-feeds";
import NewsFeed from "./components/pages/news-feeds/create";
import NewsFeedWebView from "./components/pages/news-feeds/single-news-webview";
import PageNotFoundOr404 from "./components/pages/error/404";
import Events from "./components/pages/event";
import TermsAndConditions from "./components/pages/terms-and-conditions";
import Event from "./components/pages/event/create";
import SingleEvent from "./components/pages/event/view";
import BusinessCategories from "./components/pages/business/category/index";
import BusinessCategory from "./components/pages/business/category/create";
import Banners from "./components/pages/banners";
import Banner from "./components/pages/banners/create";
import Shorts from "./components/pages/shorts";
import BusinessAccounts from "./components/pages/business/account";
import BusinessAccount from "./components/pages/business/account/create";
import PrivacyPolicy from "./components/pages/privacy-policy";
import ProductCategories from "./components/pages/marketplace/category";
import ProductCategory from "./components/pages/marketplace/category/create";
import Products from "./components/pages/marketplace/product";
import Product from "./components/pages/marketplace/product/create";
import SingleProduct from "./components/pages/marketplace/product/view";
import Reports from "./components/pages/reports";
import ProductSubcategories from "./components/pages/marketplace/subcategory";
import ProductSubcategory from "./components/pages/marketplace/subcategory/create";
import SportGames from "./components/pages/sports/list-of-sports";
import SportGame from "./components/pages/sports/list-of-sports/create";
import Team from "./components/pages/sports/teams/create";
import Teams from "./components/pages/sports/teams";
import SportHighlights from "./components/pages/sports/highlights";
import SportHighlight from "./components/pages/sports/highlights/create";
import OTPVerification from "./components/auth/OTPVerification";
import Uploader from "./components/uploader/Uploader";
import MyMediaContent from "./components/uploader/MyMediaContent";
import { toast } from "react-toastify";
import MediaContent from "./components/pages/content";
import Genre from "./components/pages/content/genre/create";
import Genres from "./components/pages/content/genre";
import SingleMediaContent from "./components/pages/content/view";
import EditMediaContent from "./components/pages/content/edit";
import LiveTv from "./components/pages/content/hbcu-tv";
import Channel from "./components/pages/content/hbcu-tv/create";
import TvGenres from "./components/pages/content/category";
import TvGenre from "./components/pages/content/category/create";
import Booking from "./components/pages/event/bookings";
function Router() {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    let notification_message = useSelector((state) => state.appNotificationMessage);
    if (Object.getOwnPropertyNames(notification_message.messageObj).length !== 0) {
        if (notification_message.messageObj.notificationType) {
            toast.success(notification_message.messageObj.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error(notification_message.messageObj.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const public_token = localStorage.getItem('public-token');
    return (
        < BrowserRouter >
            <Routes>
                <Route path="/" element={< App />} />
                <Route path="/login" element={< Login />} />
                <Route path="/forgot-password" element={< ForgotPassword />} />
                <Route path="/forgot-password/:tab" element={< OTPVerification />} />
                <Route path="/dashboard" element={
                    <ProtectedRoute user={isLoggedIn} >
                        <Dashboard></Dashboard>
                    </ProtectedRoute>
                } />

                <Route path="/public/uploader/media/:type/:id" element={
                    <MyMediaContent></MyMediaContent>
                } />
                <Route path="/public/uploader" element={
                    <Uploader></Uploader>
                } />


                <Route path="/playlists" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Playlists />
                    </ProtectedRoute>} />
                <Route path="/playlists/view/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Playlists />
                    </ProtectedRoute>
                } />
                <Route path="/playlists/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Playlist />
                    </ProtectedRoute>
                } />
                <Route path="/playlists/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Playlist />
                    </ProtectedRoute>
                } />

                {/**
                 * songs 
                 */}
                <Route path="/songs" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Songs />
                    </ProtectedRoute>
                } />
                <Route path="/songs/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Song />
                    </ProtectedRoute>
                } ></Route>
                <Route path="/songs/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Song />
                    </ProtectedRoute>
                } ></Route>

                <Route path="/posts" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Posts />
                    </ProtectedRoute>
                } />
                <Route path="/posts/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Post />
                    </ProtectedRoute>
                } />


                <Route path="/shorts" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Shorts />
                    </ProtectedRoute>
                } />

                <Route path="/users" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Users />
                    </ProtectedRoute>
                } />
                <Route path="/users/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < User />
                    </ProtectedRoute>
                } />
                <Route path="/users/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < User />
                    </ProtectedRoute>
                } />
                <Route path="/users/view/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < SingleUser />
                    </ProtectedRoute>
                } />

                <Route path="/colleges" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Colleges />
                    </ProtectedRoute>
                } />
                <Route path="/colleges/view/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < SingleCollege />
                    </ProtectedRoute>
                } />
                <Route path="/colleges/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < College />
                    </ProtectedRoute>
                } />

                <Route path="/colleges/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < College />
                    </ProtectedRoute>
                } />

                <Route path="/news-feed" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < NewsFeeds />
                    </ProtectedRoute>
                } />
                <Route path="/news-feed/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < NewsFeed />
                    </ProtectedRoute>
                } />
                <Route path="/news-feed/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < NewsFeed />
                    </ProtectedRoute>
                } />

                {/**
                 * events 
                 */}

                <Route path="/events" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Events />
                    </ProtectedRoute>
                } />
                <Route path="/events/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Event />
                    </ProtectedRoute>
                } />
                <Route path="/events/view/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < SingleEvent />
                    </ProtectedRoute>
                } />
                <Route path="/events/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Event />
                    </ProtectedRoute>
                } />
                <Route path="/events/bookings" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Booking />
                    </ProtectedRoute>
                } />

                {/**
                 * business 
                 */}

                <Route path="/business/category" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < BusinessCategories />
                    </ProtectedRoute>
                } />
                <Route path="/business/category/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < BusinessCategory />
                    </ProtectedRoute>
                } />
                <Route path="/business/category/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < BusinessCategory />
                    </ProtectedRoute>
                } />

                {/**
                 * banners 
                 */}
                <Route path="/app-banner" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Banners />
                    </ProtectedRoute>
                } />

                <Route path="/app-banner/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Banner />
                    </ProtectedRoute>
                } />
                <Route path="/app-banner/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Banner />
                    </ProtectedRoute>
                } />
                {/**
                 * shorts 
                 */}
                <Route path="/shorts" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Shorts />
                    </ProtectedRoute>
                } />


                {/**
                 * business account 
                 */}
                <Route path="/business/accounts" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < BusinessAccounts />
                    </ProtectedRoute>
                } />

                <Route path="/business/accounts/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < BusinessAccount />
                    </ProtectedRoute>
                } />
                <Route path="/business/accounts/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < BusinessAccount />
                    </ProtectedRoute>
                } />

                {/**
                 * marketplace category... 
                 */}
                <Route path="/marketplace/category" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <ProductCategories />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/category/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <ProductCategory />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/category/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <ProductCategory />
                    </ProtectedRoute>
                } />

                {/**
                 * marketplace subcategory... 
                 */}
                <Route path="/marketplace/subcategory" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <ProductSubcategories />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/subcategory/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <ProductSubcategory />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/subcategory/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <ProductSubcategory />
                    </ProtectedRoute>
                } />
                {/**
                 * marketplace Product... 
                 */}
                <Route path="/marketplace/products" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Products />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/products/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Product />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/products/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < Product />
                    </ProtectedRoute>
                } />
                <Route path="/marketplace/products/view/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <SingleProduct />
                    </ProtectedRoute>
                } />
                <Route path="/reports" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Reports />
                    </ProtectedRoute>
                } />

                {/**
                 * 
                 */}

                <Route path="/sports-games" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <SportGames />
                    </ProtectedRoute>
                } />
                <Route path="/sports-games/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < SportGame />
                    </ProtectedRoute>
                } />
                <Route path="/sports-games/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        < SportGame />
                    </ProtectedRoute>
                } />

                {/**
                 * 
                 */}
                <Route path="/teams" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Teams />
                    </ProtectedRoute>
                } />
                <Route path="/teams/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Team />
                    </ProtectedRoute>
                } />
                <Route path="/teams/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Team />
                    </ProtectedRoute>
                } />
                {/**
                 * 
                 */}
                <Route path="/sport-highlights" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <SportHighlights />
                    </ProtectedRoute>
                } />
                <Route path="/sport-highlights/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <SportHighlight />
                    </ProtectedRoute>
                } />

                <Route path="/news-feed/web-view/:id" element={
                    < NewsFeedWebView />
                } />
                <Route path="/terms-and-conditions" element={
                    < TermsAndConditions />
                } />
                <Route path="/privacy-policy" element={
                    < PrivacyPolicy />
                } />
                <Route path="*" element={<PageNotFoundOr404 />} />

                {/* Censor Content  */}
                <Route path="/content" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <MediaContent />
                    </ProtectedRoute>
                } />
                <Route path="/content/:type/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <EditMediaContent />
                    </ProtectedRoute>
                } />
                <Route path="/content/view/:type/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <SingleMediaContent />
                    </ProtectedRoute>
                } />

                <Route path="/genre-and-category" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Genres />
                    </ProtectedRoute>
                } />
                <Route path="/genre-and-category/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Genre />
                    </ProtectedRoute>
                } />
                <Route path="/genre-and-category/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Genre />
                    </ProtectedRoute>
                } />
                <Route path="/sport-highlights/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <SportHighlight />
                    </ProtectedRoute>
                } />

                <Route path="/live-tv" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <LiveTv />
                    </ProtectedRoute>
                } />
                <Route path="/live-tv/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Channel />
                    </ProtectedRoute>
                } />
                <Route path="/live-tv/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <Channel />
                    </ProtectedRoute>
                } />

                <Route path="/live-tv/genres" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <TvGenres />
                    </ProtectedRoute>
                } />
                <Route path="/live-tv/genres/create" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <TvGenre />
                    </ProtectedRoute>
                } />
                <Route path="/live-tv/genres/:id" element={
                    <ProtectedRoute user={isLoggedIn}>
                        <TvGenre />
                    </ProtectedRoute>
                } />

            </Routes>
        </BrowserRouter >)
}

export default Router;
