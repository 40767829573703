import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URI } from "../../../api";
import axios from "axios";
import { useState } from "react";
import React from "react";
import { getWindowDimensions } from "../../helper/functions";
function NewsFeedWebView() {
  const { id } = useParams();
  const [containerClass, setContainerClass] = useState("container-fluid");
  const [newsFeed, setnewsFeed] = useState({
    title: "",
    link: "",
    pubDate: Date(),
    description: "",
    content: "",
    source_text: "",
    source_url: "",
    channel_title: "",
    channel_image_url: "",
    featured_news: false, //True for mobile news banners screen 
    category: [],
    views_count: 0,
    media_content: "assets/svg/News-1.svg"
  })

  useEffect(() => {
    axios.get(`${API_URI}/news-feeds/${id}`,
    ).then((response) => {
      console.log(response);
      if (response.data.status) {
        let news_feed = response.data.results[0];
        setnewsFeed({
          title: news_feed.title,
          link: news_feed?.link,
          pubDate: Date(),
          description: news_feed?.description,
          content: news_feed?.content,
          views_count: news_feed?.views_count,
          source_text: news_feed?.source?.text,
          source_url: news_feed?.source?.url,
          channel_title: news_feed?.channel?.title,
          channel_image_url: news_feed?.channel?.image_url,
          featured_news: false, //True for mobile news banners screen 
          category: [],
          media_content: news_feed?.media_content
        });
      }
    }).catch((error) => {
      console.log(error)
    });

    if (992 <= getWindowDimensions().width) {
      setContainerClass("container p-0");
    }
    console.log(getWindowDimensions());
  }, [id]);



  return (
    <>
      <div className={containerClass}>
        <div className="card card-inverse text-white">
          {
            newsFeed.media_content != "" && newsFeed.media_content != undefined ?
              <img src={newsFeed.media_content} alt={newsFeed.title} className="card-img img-fluid" />
              : <img src="assets/svg/News-1.svg" alt={newsFeed.title} className="card-img img-fluid" />
          }
          <div className="card card-body news__Feed_card">
            <div className="d-flex justify-content-between">
              <div>
                {
                  newsFeed.channel_image_url != "" && newsFeed.channel_image_url != undefined ?
                    <img src={newsFeed.channel_image_url} alt="user-image" className="rounded-circle avatar-sm" />
                    :
                    <img src="assets/svg/News-2.svg" alt="user-image" className="rounded-circle avatar-sm" />
                }
                <span className="font-weight-bold text-dark ml-2">{newsFeed.channel_title}</span>
              </div>
              <span type="button" className="btn btn-outline-danger btn-sm waves-effect waves-light mb-1 mt-1">
                <i className="fa fa-eye me-2"></i> {newsFeed.views_count} Views
              </span>
            </div>
            <h3 className="mt-1 mb-0">{newsFeed.title}</h3>
            {
              //Souce of News 
              (newsFeed.source_text != "" && newsFeed.source_text != undefined) && (newsFeed.source_url != "" && newsFeed.source_url != undefined) ?
                <small className="mb-2 text-dark">
                  Source:: <a href={newsFeed.source_url} target={"_blank"}>{newsFeed.source_text}</a>
                </small>
                : null
            }
            {//If News content not exist then show news description
              (newsFeed.content == "" && newsFeed.content != undefined) ?
                <div className="text-muted" dangerouslySetInnerHTML={{ __html: newsFeed.description }}>
                </div> : null
            }
            <div className=" text-dark news__Feed_container" dangerouslySetInnerHTML={{ __html: newsFeed.content }} >
            </div>
            {//If News content found remove read more button
              (newsFeed.content != "" && newsFeed.content != undefined) ?
                null : <div className="d-flex justify-content-end">
                  <a href={newsFeed.link} className="btn btn-primary" target={"_blank"}>
                    Read More <img src="assets/svg/Left-Icon.svg" width={"16px"} className="ml-1" />
                  </a>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsFeedWebView;