import './App.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
function App() {
  useEffect(() => {
    document.title = "The HBCU - Home";
  }, []);

  return (
    <>
      <div class="home-btn d-none d-sm-block">
        <Link to="/login">
          <i class="fas fa-sign-in-alt h2 "></i>
        </Link>
      </div>

      <div class="account-pages w-100 mt-5 mb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="home-wrapper text-center">
                <svg version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100.000000pt" height="100.000000pt" viewBox="0 0 513.000000 513.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,513.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M264 2606 c5 -1876 10 -2446 19 -2465 7 -14 27 -36 46 -48 l34 -23
                          1702 0 1702 0 76 115 c43 63 77 117 77 120 0 3 -765 5 -1700 5 l-1700 0 -1
                          643 c-1 353 -5 1418 -8 2367 l-6 1725 -124 0 -124 0 7 -2439z"/>
                    <path d="M1530 4930 l0 -120 1545 0 1545 0 0 -2180 0 -2180 125 0 125 0 0
                            2300 0 2300 -1670 0 -1670 0 0 -120z"/>
                    <path d="M670 5000 c0 -36 3 -40 25 -40 16 0 25 6 25 15 0 8 5 15 10 15 6 0
                            10 -33 10 -79 0 -44 -4 -83 -10 -86 -5 -3 -10 -15 -10 -26 0 -16 7 -19 55 -19
                            48 0 55 2 55 20 0 11 -4 20 -10 20 -6 0 -10 35 -10 85 0 50 4 85 10 85 6 0 10
                            -7 10 -15 0 -9 9 -15 25 -15 23 0 25 4 25 40 l0 40 -105 0 -105 0 0 -40z"/>
                    <path d="M900 5015 c0 -14 5 -25 10 -25 6 0 10 -35 10 -85 0 -50 -4 -85 -10
                            -85 -5 0 -10 -9 -10 -20 0 -18 7 -20 55 -20 48 0 55 3 55 19 0 11 -4 23 -10
                            26 -5 3 -10 20 -10 36 0 27 3 30 28 27 20 -2 28 -9 30 -27 2 -15 -3 -28 -12
                            -33 -9 -5 -16 -18 -16 -28 0 -17 7 -20 55 -20 52 0 55 1 55 25 0 14 -4 25 -10
                            25 -6 0 -10 33 -10 80 0 47 4 80 10 80 6 0 10 11 10 25 0 24 -3 25 -55 25 -49
                            0 -55 -2 -55 -20 0 -11 7 -23 15 -26 8 -4 15 -17 15 -30 0 -20 -5 -24 -30 -24
                            -25 0 -30 4 -30 25 0 14 5 25 10 25 6 0 10 11 10 25 0 24 -3 25 -55 25 -52 0
                            -55 -1 -55 -25z"/>
                    <path d="M1150 5015 c0 -14 5 -25 10 -25 6 0 10 -35 10 -85 0 -50 -4 -85 -10
                              -85 -5 0 -10 -9 -10 -20 0 -19 7 -20 105 -20 l105 0 0 40 c0 37 -2 40 -29 40
                              -20 0 -30 -6 -34 -19 -3 -14 -11 -17 -31 -14 -21 4 -26 11 -26 34 0 24 4 29
                              25 29 18 0 25 5 25 20 0 15 -7 20 -25 20 -21 0 -25 5 -25 30 0 27 3 30 30 30
                              17 0 30 -4 30 -10 0 -5 14 -10 30 -10 28 0 30 3 30 35 l0 35 -105 0 -105 0 0
                              -25z"/>
                    <path d="M630 4360 l0 -170 80 0 80 0 0 -625 0 -625 -79 0 -78 0 -7 -37 c-3
                              -21 -6 -98 -6 -170 l0 -133 390 0 390 0 0 170 0 170 -80 0 -80 0 2 228 3 227
                              315 0 315 0 0 -225 0 -225 -85 -5 -85 -5 -3 -167 -2 -168 395 0 395 0 0 170 0
                              170 -80 0 -80 0 2 618 3 617 77 3 78 3 0 174 0 175 -390 0 -390 0 0 -175 0
                              -175 85 0 85 0 -2 -212 -3 -213 -315 0 -315 0 0 210 0 210 83 3 82 3 0 174 0
                              175 -390 0 -390 0 0 -170z"/>
                    <path d="M2610 4355 l0 -175 100 0 100 0 -1 -427 c-1 -236 -4 -516 -8 -623
                              l-6 -195 -92 -3 -93 -3 0 -169 0 -170 781 0 780 0 140 107 139 106 0 327 0
                              326 -95 69 c-52 37 -95 71 -94 74 0 3 43 41 95 85 l94 78 0 298 0 298 -135 86
                              -136 86 -784 0 -785 0 0 -175z m1040 -160 l42 -104 141 -3 142 -3 -107 -63
                              c-60 -35 -110 -67 -113 -72 -2 -4 14 -53 36 -110 22 -56 39 -104 37 -105 -2
                              -2 -50 25 -108 60 -58 36 -109 64 -115 64 -5 0 -57 -28 -115 -64 -58 -35 -106
                              -62 -108 -61 -1 2 15 49 37 106 23 56 38 106 36 110 -3 4 -53 36 -112 71
                              l-108 64 142 3 141 3 42 104 c23 58 43 105 45 105 2 0 22 -47 45 -105z m230
                              -1030 l0 -235 -270 0 -270 0 0 235 0 235 270 0 270 0 0 -235z"/>
                    <path d="M853 2325 l-110 -76 -6 -121 c-4 -67 -7 -426 -7 -798 l0 -677 129
                              -91 130 -92 595 0 595 0 119 83 119 82 7 80 c3 44 6 157 6 251 l0 172 -222 7
                              c-123 4 -245 5 -273 3 l-50 -3 -3 -167 -2 -168 -305 0 -305 0 0 620 0 620 310
                              0 310 0 0 -100 0 -100 270 0 270 0 -2 187 -3 188 -125 87 -125 88 -606 0 -605
                              0 -111 -75z"/>
                    <path d="M2597 2383 c-4 -3 -7 -80 -7 -170 l0 -163 80 0 81 0 -1 -691 c-1
                              -380 0 -692 2 -694 2 -1 68 -48 148 -104 l145 -101 492 0 491 0 151 102 151
                              102 0 688 0 688 75 0 75 0 0 170 0 170 -385 0 -385 0 2 -167 3 -168 68 -3 67
                              -3 -2 -617 -3 -617 -305 0 -305 0 -3 618 -2 617 65 0 65 0 0 169 0 168 -52 7
                              c-71 8 -703 8 -711 -1z"/>
                    <path d="M4026 218 l-95 -133 32 -3 c24 -3 35 2 50 22 16 22 26 26 73 26 51 0
                              54 -1 54 -25 0 -22 4 -25 40 -25 l40 0 0 58 c0 33 -3 93 -6 135 l-7 77 -43 0
                              -43 0 -95 -132z m114 12 c0 -38 -2 -40 -30 -40 -16 0 -30 2 -30 5 0 10 45 75
                              53 75 4 0 7 -18 7 -40z"/>
                    <path d="M4339 323 c-20 -50 -79 -227 -79 -236 0 -4 16 -7 36 -5 33 3 38 7 53
                              48 l16 45 60 5 c90 8 145 50 147 110 2 50 -20 60 -129 60 -93 0 -94 0 -104
                              -27z m151 -48 c0 -28 -25 -45 -69 -45 -32 0 -36 7 -25 48 5 18 13 22 50 22 40
                              0 44 -2 44 -25z"/>
                    <path d="M4635 338 c-48 -140 -85 -251 -85 -254 0 -2 17 -4 38 -4 36 0 38 2
                              55 50 l18 50 50 0 c63 0 116 24 140 64 24 39 24 68 1 89 -24 22 -210 25 -217
                              5z m152 -55 c9 -23 -32 -53 -73 -53 -37 0 -39 5 -24 44 8 22 16 26 50 26 29 0
                              43 -5 47 -17z"/>
                  </g>
                </svg>
                <h2 class=" text-uppercase mt-4">Stay tunned, we're launching very soon</h2>
                <div data-countdown="2020/01/17" class="counter-number mt-5"></div>
                <ul class="nav justify-content-center">
                  <li class="nav-item">
                    <Link to={'/privacy-policy'} class="nav-link active">Privacy Policy</Link>
                  </li>
                  <li class="nav-item">
                    <Link to={'/terms-and-conditions'} class="nav-link active">Terms And Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
