
import { GET_MOVIE_META, GET_PUBLIC_GENRES, GET_PUBLIC_LANGUAGES, PUBLIC_REDIRECT, LOCATION_RELOAD, RESET_PUBLIC_REDIRECT } from "../../components/constants/actionTypes";

const initialState = {
    genres: [],
    languages: [],
    redirect_to: null,
    movie: null,
    page_reload: false,
}
const uploaderReducer = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
        case GET_PUBLIC_GENRES:
            return {
                ...state,
                genres: action.genres,
            }
        case GET_PUBLIC_LANGUAGES:
            return {
                ...state,
                languages: action.languages,
            }
        case PUBLIC_REDIRECT:
            return {
                ...state,
                redirect_to: action.redirect_to,
            }
        case RESET_PUBLIC_REDIRECT:
            return {
                ...state,
                redirect_to: null
            }
        case GET_MOVIE_META:
            return {
                ...state,
                movie: action.movie,
            }
        case LOCATION_RELOAD:
            return {
                ...state,
                page_reload: (action.page_reload) ? true : false
            }
        default:
            return state;
    }
}

export default uploaderReducer;