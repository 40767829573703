import { ADD_PLAYLIST, DELETE_PLAYLIST, GET_PLAYLISTS, GET_SONGS, UPDATE_PLAYLIST, ADD_SONG, DELETE_SONG, GET_SHORTS } from "../../components/constants/actionTypes";
export const shortsReducer = (state = { shorts: [], page_no: 1, total_pages: 1, }, action) => {
    switch (action.type) {
        case GET_SHORTS:
            return {
                shorts: action.shorts.results,
                page_no: action.shorts.page_no,
                total_pages: action.shorts.total_pages,
            };
        default:
            return state;
    }
}

export const playlistReducer = (state = [], action) => {
    switch (action.type) {
        case GET_PLAYLISTS:
            return action.playlists
        case ADD_PLAYLIST:
            return [action.playlist, ...state]
        case DELETE_PLAYLIST:
            return state.filter(playlist => {
                if (playlist._id === action.id) {
                    return false;
                } else {
                    return true;
                }
            });
        case UPDATE_PLAYLIST:
            state.map((playlist) => {
                if (playlist._id === action.playlist._id) {
                    return false;
                }
                return true;
            });
        default:
            return state;
    }
}

export const songReducer = (state = [], action) => {
    switch (action.type) {
        case GET_SONGS:
            return action.songs
        case ADD_SONG:
            return [action.song, ...state]
        case DELETE_SONG:
            return state.filter(song => {
                if (song._id === action.id) {
                    return false;
                } else {
                    return true;
                }
            });
        default:
            return state;
    }
}

