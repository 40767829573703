import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginStart } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../constants/uiTypes";
import { SetDocumentTitle } from "../helper/functions";
function Login() {
    SetDocumentTitle(`Login in to ${APP_NAME} · ${APP_NAME}`);

    const { isLoggedIn } = useSelector((state) => state.auth);
    const [formData, setLogin] = useState({
        email: "",
        login_type: "email",
        password: "",
        plateform: "web", //For Admin Side Authentication
    });

    const [formError, setInputError] = useState({
        email: "",
        password: ""
    });
    const dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn) {
            navigate("/dashboard");
        }
    }, [isLoggedIn]);

    const login = () => {
        if (formData.email === "") {
            setInputError({ ...formError, email: "Email is required." })
        }
        else if (formData.password === "") {
            setInputError({ ...formError, password: "Password is required." })
        }
        else {
            setInputError({ ...formError, email: "", password: "" })
            dispatch(loginStart(formData));
        }
    }
    return (
        <>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="fas fa-home h2"></i>
                </Link>
            </div>
            <div className="account-pages w-100 mt-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card shadow-lg mb-0">
                                <div className="card-body p-4">
                                    <div className="account-box">
                                        <div className="account-logo-box">
                                            <div className="text-center">
                                                <Link to="/">
                                                    <img src="logo101.png" alt="The HBCU Logo" height="80" />
                                                </Link>
                                            </div>
                                            <h5 className="text-uppercase mb-1 mt-4">Sign In</h5>
                                            <p className="mb-0">Login to your Admin account</p>
                                        </div>
                                        <div className="account-content mt-4">
                                            <form className="form-horizontal" >
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="emailaddress">Email address</label>
                                                        <input className="form-control" type="email" required="" placeholder="john@deo.com" onChange={(e) => setLogin({ ...formData, email: e.target.value })} />
                                                        {formError.email ? <div className="invalid-feedback d-block">{formError.email}</div> : null}

                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <Link to="/forgot-password" className="text-muted float-right"><small>Forgot your password?</small></Link>
                                                        <label htmlFor="password">Password</label>
                                                        <input className="form-control" type="password" required="" id="password" placeholder="Enter your password" onChange={(e) => setLogin({ ...formData, password: e.target.value })} />
                                                        {formError.password ? <div className="invalid-feedback d-block">{formError.password}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <div className="checkbox checkbox-success">
                                                            <input id="remember" type="checkbox" />
                                                            <label htmlFor="remember">
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row text-center mt-2">
                                                    <div className="col-12">
                                                        <button className="btn btn-md btn-block btn-primary waves-effect waves-light" type="button" onClick={login}>Sign In</button>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                        <button type="button" className="btn mr-1 btn-facebook waves-effect waves-light">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </button>
                                                        <button type="button" className="btn mr-1 btn-googleplus waves-effect waves-light">
                                                            <i className="fab fa-google"></i>
                                                        </button>
                                                        <button type="button" className="btn mr-1 btn-twitter waves-effect waves-light">
                                                            <i className="fab fa-twitter"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="row mt-4 pt-2">
                                                <div className="col-sm-12 text-center">
                                                    <p className="text-muted mb-0">Don't have an account? <a href="#" className="text-dark ml-1"><b>Sign Up</b></a></p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Login;