import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import swal from "sweetalert";
import PageHeader from "../../../layouts/PageHeader";
import Footer from "../../../layouts/Footer";
import { deleteBusinessCategory, getBusinessCategories } from "../../../../store/actions/businessActions";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
function BusinessCategories() {
    SetDocumentTitle(`Business Categories Action \u2022 ${APP_NAME}`);
    const table_style = {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        width: "100%"
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const categories = useSelector((state) => state.business_categories);
    console.log(categories);

    useEffect(() => {
        dispatch(getBusinessCategories());
    }, [dispatch])

    const addNewCollege = () => {
        navigate('/business/category/create')
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteBusinessCategory(id))
            }
        });
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Business Category</h4>
                                        <p className="sub-header">
                                            {categories.length} Category Fetched
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewCollege}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar" style={table_style}>
                                                <thead>
                                                    <tr>
                                                        <th>Icon</th>
                                                        <th>Name</th>
                                                        <th>Status</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {categories && categories.map((category) => {
                                                        return (
                                                            <tr key={category._id}>
                                                                <td>
                                                                    {category.icon !== '' ?
                                                                        <img src={category.icon} alt="" className="avatar-md" />
                                                                        :
                                                                        <img src="/assets/images/users/no-avatar.jpg" alt="" className="avatar-xl rounded" />
                                                                    }</td>
                                                                <td>{category.name}</td>
                                                                <td>{category.is_published ? "Active" : "Inactive"}</td>
                                                                <td>{category.createdAt.split("T")[0]}</td>
                                                                <td>
                                                                    <Link to={`/business/category/${category._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="College Delete" onClick={() => handleDelete(category._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default BusinessCategories;