import { useEffect, useState } from "react";
import { APP_NAME } from "../constants/uiTypes";
import { SetDocumentTitle } from "../helper/functions";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/actions/dashboardActions";
import { API_URI } from "../../api";
import { multipartFormData } from "../../services/header";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { authHeader } from "../../services/auth-header";
import PublicLogin from "./components/PublicLogin";
import PublicNavbar from "./components/PublicNavbar";
import { forceReloadUI, getGenre, getLanguages, uploadMovieMeta } from "../../store/actions/uploaderActions";
import { remove } from "lodash";
const animatedComponents = makeAnimated();

function Uploader(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn, user, token } = useSelector((state) => state.public_auth);
    const { genres, languages, redirect_to, page_reload } = useSelector((state) => state.uploader);
    const [formInput, setFormInput] = useState({ //UI input state
        thumbnail: "",
        cover_image: "",
        overview: "",
        genres: [],
        languages: [],
        starring: [],
        director: "",
        rating: "",
        duration: "",
        maturity_rating: "",
        released_date: "",
        type: "",
        name: ""
    });
    SetDocumentTitle(`Public Uploader  \u2022 ${APP_NAME}`);
    useEffect(() => {
        if (isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn === true) {
            /***
            * Fetch language code from local json.
            */
            dispatch(getLanguages());
            /**
             * Fetch Genre
             */
            dispatch(getGenre('genre-wise', token));
        }
        if (redirect_to !== null && redirect_to !== undefined && redirect_to !== '') {
            navigate(redirect_to);
        }

    }, [dispatch, isLoggedIn, redirect_to]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('overview', formInput.overview);
        formData.append('type', formInput.type);
        formData.append('released_date', formInput.released_date);
        formData.append('maturity_rating', formInput.maturity_rating);
        formData.append('rating', formInput.rating);
        formData.append('thumbnail', formInput.thumbnail);
        formData.append('cover_image', formInput.cover_image);
        formData.append('director', formInput.director);
        for (let i = 0; i < formInput.genres.length; i++) {
            formData.append("genres", formInput.genres[i]);
        }
        for (let i = 0; i < formInput.languages.length; i++) {
            formData.append("languages", formInput.languages[i]);
        }
        for (let i = 0; i < formInput.starring.length; i++) {
            formData.append("starring", formInput.starring[i]);
        }
        /**
         * Api Call
         */
        dispatch(uploadMovieMeta(formData, token));
    }
    const getGenresOptions = (genre_options) => {
        let genre = [];
        genre_options.map((option) => {
            genre.push(option.value);
        })
        setFormInput({ ...formInput, genres: genre })
    }
    const getLanguagesOptions = (language_options) => {
        let language = [];
        language_options.map((option) => {
            language.push(option.value);
        })
        setFormInput({ ...formInput, languages: language })
    }
    const getStarsOptions = (stars_options) => { //Cast of movie //tv-show //web-series
        let starring = [];
        stars_options.map((option) => {
            starring.push(option.value.trim());
        })
        setFormInput({ ...formInput, starring: starring })
    }
    return (
        <>
            {isLoggedIn ?
                <>
                    <PublicNavbar name={user?.name} image={user?.cover_pic} />
                    <div className="container mt-5">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <fieldset >
                                        <legend>HBCU TV Uploads Mediameta</legend>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledTextInput" className="form-label">Media type</label>
                                                    <select className="form-control" onChange={(event) => setFormInput({ ...formInput, type: event.target.value })} required={true}>
                                                        <option defaultValue value="">Please choose ...</option>
                                                        <option value="movie">Movie</option>
                                                        {/* <option value="show">Show</option> */}
                                                        <option value="tv-series">Tv Series</option>
                                                        {/* <option value="web-series">Web Series</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledTextInput" className="form-label">Maturity ratings</label>
                                                    <select className="form-control" onChange={(event) => setFormInput({ ...formInput, maturity_rating: event.target.value })} required={true}>
                                                        <option defaultValue value="">Please choose ...</option>
                                                        <option value="G">General Audiences (G)</option>
                                                        <option value="PG">Parental Guidance Suggested (PG)</option>
                                                        <option value="PG-13">Parents Strongly Cautioned (PG-13)</option>
                                                        <option value="R">Restricted (R)</option>
                                                        <option value="NC-17">Clearly Adult (NC-17)</option>
                                                        <option value="NR">Not Rated (NR)</option>
                                                        <option value="UR">Unrated (UR)</option>
                                                        <option value="TV-Y">All Children (TV-Y)</option>
                                                        <option value="TV-Y7">Directed to Older Children (TV-Y7)</option>
                                                        <option value="TV-Y7 FV">Directed to Older Children - Fantasy Violence (TV-Y7 FV)</option>
                                                        <option value="TV-G">General Audience (TV-G)</option>
                                                        <option value="TV-PG">Parental Guidance Suggested (TV-PG)</option>
                                                        <option value="TV-14">Parents Strongly Cautioned (TV-14)</option>
                                                        <option value="TV-MA">Mature Audience Only (TV-MA)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledTextInput" className="form-label">Release Date</label>
                                                    <input type="date" className="form-control" id="exampleFormControlInput1" onChange={(event) => setFormInput({ ...formInput, released_date: event.target.value })} required={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Name</label>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="eg. The Great Debaters" onChange={(event) => setFormInput({ ...formInput, name: event.target.value })} required={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Overview</label>
                                                    <textarea className="form-control" rows={3} placeholder="overview ...." onChange={(event) => setFormInput({ ...formInput, overview: event.target.value })}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Languages</label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={[]}
                                                        isMulti
                                                        options={languages}
                                                        onChange={getLanguagesOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Genre</label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={[]}
                                                        isMulti
                                                        options={genres}
                                                        onChange={getGenresOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Starring (Cast)</label>
                                                    <CreatableSelect isMulti onChange={getStarsOptions} required={true} />
                                                    <small>Use enter to add</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Director</label>
                                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="eg. Denzel Washington" onChange={(event) => setFormInput({ ...formInput, director: event.target.value })} required={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Rating (0.0)</label>
                                                    <input type="tel" className="form-control" onChange={(event) => setFormInput({ ...formInput, rating: event.target.value })} required={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Cover image</label><br />
                                                    <input type="file" onChange={(event) => setFormInput({ ...formInput, cover_image: event.target.files[0] })} required={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-2">
                                                    <label htmlFor="disabledSelect" className="form-label">Thumbnail</label><br />
                                                    <input type="file" onChange={(event) => setFormInput({ ...formInput, thumbnail: event.target.files[0] })} required={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
                : <PublicLogin></PublicLogin>
            }
        </>
    )
}
export default Uploader;