import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Topbar from "../../layouts/Topbar";
import LeftSidebar from "../../layouts/LeftSidebar";
import RightSidebar from "../../layouts/RightSidebar";
import swal from "sweetalert";
import PageHeader from "../../layouts/PageHeader";
import Footer from "../../layouts/Footer";
import { deleteBanner, getBanners } from "../../../store/actions/appBannerActions";
import { SetDocumentTitle } from "../../helper/functions";
import { APP_NAME } from "../../constants/uiTypes";
function Banners() {
    SetDocumentTitle(`Banners \u2022 ${APP_NAME}`);
    const table_style = {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        width: "100%"
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const banners = useSelector((state) => state.banners);

    useEffect(() => {
        dispatch(getBanners());
    }, [])

    const addNewCollege = () => {
        navigate('/app-banner/create')
    }
    const handleDelete = (id) => {
        swal({
            title: "",
            text: "Are you sure? Once deleted, you will not be able to recover this!",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancel", "Delete"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteBanner(id));
            }
        });
    }

    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">All Banners</h4>
                                        <p className="sub-header">
                                            {banners.length} Banner Fetched
                                        </p>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-12 text-sm-center form-inline">
                                                    <div className="form-group mr-2">
                                                        <button id="demo-btn-addrow" className="btn btn-primary" type="button" onClick={addNewCollege}>
                                                            <i className="mdi mdi-plus-circle mr-2"></i> Add New
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table id="datatable-buttons" className="table table-striped dt-responsive nowrap table-actions-bar" style={table_style}>
                                                <thead>
                                                    <tr>
                                                        <th>Banner</th>
                                                        <th>Title</th>
                                                        <th>Type</th>
                                                        <th>Url</th>
                                                        <th>Status</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {banners && banners.map((banner) => {
                                                        return (
                                                            <tr key={banner._id}>
                                                                <td>
                                                                    {banner?.media?.thumbnail !== '' ?
                                                                        <img src={banner?.media?.thumbnail} alt="" className="img-thumbnail img-fluid" width="100px" />
                                                                        :
                                                                        <img src="/assets/images/no-thumbnail.jpg" alt="" className="img-thumbnail img-fluid" width="100px" />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <b>{banner.title}</b><br />
                                                                    <small>{banner.banner_type}</small>
                                                                </td>
                                                                <td>{banner.media_type}</td>
                                                                <td>
                                                                    {banner.redirect_url}
                                                                </td>
                                                                <td>{(banner.is_published == true) ? "Active" : "Inactive"}</td>
                                                                <td>{banner.createdAt.split("T")[0]}</td>
                                                                <td>
                                                                    <Link to={`/app-banner/${banner._id}`} className="table-action-btn" title="Edit College">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Link>
                                                                    <span className="table-action-btn" title="College Delete" onClick={() => handleDelete(banner._id)} >
                                                                        <i className="mdi mdi-close"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}

export default Banners;