import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser, getUsers, updateUser } from "../../../../store/actions/userActions";
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addProduct, addProductCategory, getProductCategories, updateProduct, updateProductCategory } from "../../../../store/actions/marketplaceActions";
function SingleProduct() {
    const [formInput, setFormInput] = useState({
        name: "",
        sort_description: "",
        description: "",
        product_details: "",
        category_id: "",
        price: "",
        cover_image: "",
        images: "",
        is_published: true,
        in_trending: false,
        rating: '0.0',
    });
    const [viewMode, setViewMode] = useState(false);
    const [editor, setEditor] = useState('');
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const products = useSelector((state) => state.products.products);

    useEffect(() => {
        dispatch(getProductCategories(1, ""));
        if (id) {
            setViewMode(true);
            const singleProduct = products.find(product => product._id == id);
            setFormInput({
                ...formInput,
                name: singleProduct?.name,
                sort_description: singleProduct?.sort_description,
                description: singleProduct?.description,
                is_published: singleProduct?.is_published,
                category_id: singleProduct?.category_id,
                price: singleProduct?.price,
                is_published: singleProduct?.is_published,
                in_trending: singleProduct?.in_trending,
                product_details: singleProduct?.product_details,
                cover_image: singleProduct?.cover_image,
                images: singleProduct?.images,
                rating: singleProduct?.rating,
            });
        }
    }, [dispatch, id]);
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <h3 class="d-inline-block d-sm-none">{formInput.name}</h3>
                                                <div class="col-12">
                                                    {
                                                        formInput.cover_image === '' || formInput.cover_image == undefined ?
                                                            <img src="assets/images/no-thumbnail.jpg" className="img-fluid img-thumbnail" /> :
                                                            <img src={formInput.cover_image} className="img-fluid img-thumbnail" />
                                                    }
                                                </div>
                                                <div class="col-12 product-image-thumbs">
                                                    <div class="row mt-3">
                                                        {formInput.images && formInput.images.map((image) => {
                                                            return (
                                                                <div className="col-md-2">
                                                                    <img src={image} alt="Product Image" className="ms-3 img-thumbnail" />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <h3 class="my-3">{formInput.name}</h3>
                                                <p>{formInput.description}</p>
                                                <p class="mb-0 h4">
















                                                    
                                                    <b>Customer reviews</b> {formInput.rating}
                                                </p>
                                                <hr />
                                                <div class="bg-gray py-2 px-3">
                                                    <h2 class="mb-0">
                                                        ${formInput.price}
                                                    </h2>
                                                    <h4 class="mt-0">
                                                        <small>Ex Tax: ${formInput.price} </small>
                                                    </h4>
                                                </div>
                                                <h4>Product Details</h4>
                                                <div class="container" dangerouslySetInnerHTML={{ __html: formInput.product_details }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default SingleProduct;