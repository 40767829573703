import { SET_MESSAGE, CLEAR_MESSAGE } from "../../components/constants/actionTypes";

const initialState = {}
export const appNotificationMessageReducer = (state = { messageObj: initialState }, action) => {
    switch (action.type) {
        case SET_MESSAGE:
            return action;
        case CLEAR_MESSAGE:
            return initialState;
        default:
            return state;
    }
}