import axios from "axios"
import { API_URI } from "../../api"
import { GET_CHANNELS, GET_TV_GENRES } from "../../components/constants/actionTypes";
import { authHeader } from "../../services/auth-header";
import { multipartFormData } from "../../services/header";
import { setMessage } from "./appNotificationMessageActions";
import { setLoading } from "./dashboardActions";

export const getTvGenres = (pageNumber, searchQuery, document_limit) => {
    return (dispatch,) => {
        dispatch(setLoading(true))
        axios({
            method: 'POST',
            url: `${API_URI}/hbcu-tv/live-tv/category`,
            data: {
                page_number: pageNumber,
                document_limit: document_limit ?? 20,
                query: searchQuery,
            },
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                let genres = responseData;
                dispatch({
                    type: GET_TV_GENRES,
                    genres
                })
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export const deleteTvGenres = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios({
            method: 'DELETE',
            url: `${API_URI}/hbcu-tv/live-tv/category/${id}`,
            headers: authHeader()
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getTvGenres(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }
}

export const addTvGenres = (genre) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/hbcu-tv/live-tv/category/create`,
                data: genre,
                headers: multipartFormData(),
            }
        ).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getTvGenres(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export const updateTvGenres = (id, genre) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios({
            method: 'PUT',
            url: `${API_URI}/hbcu-tv/live-tv/category/${id}`,
            data: genre,
            headers: multipartFormData(),
        }).then((response) => {
            let responseData = response.data;
            if (response.data.status) {
                dispatch(getTvGenres(1, ''));
                dispatch(setMessage(responseData.status_code, responseData.message, true));
            } else {
                dispatch(setMessage(responseData.status_code, responseData.message, false));
            }
        }).catch((error) => {
            dispatch(setMessage(400, error, false));
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}