
import { GET_FEED_URLS } from "../../components/constants/actionTypes";
import { API_URI } from "../../api/index";
import axios from "axios";
import { setLoading } from "./dashboardActions";
import { authHeader } from "../../services/auth-header";
export const getFeedUrls = () => {
    return (dispatch,) => {
        dispatch(setLoading(true))
        axios({
            method: 'GET',
            url: `${API_URI}/news-feeds/urls`,
            headers: authHeader()
        }).then((response) => {
            if (response.data.status) {
                const feed_urls = response.data.results;
                dispatch({
                    type: GET_FEED_URLS,
                    feed_urls
                })
            }
        }).catch((error) => {

        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}


export const addFeedUrl = (url) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'POST',
                url: `${API_URI}/news-feeds/urls`,
                data: url,
                headers: authHeader(),
            }
        ).then((response) => {
            if (response.data.status) {
                dispatch(getFeedUrls());
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}


export const removeFeedUrl = (id) => {
    return (dispatch,) => {
        dispatch(setLoading(true));
        axios(
            {
                method: 'DELETE',
                url: `${API_URI}/news-feeds/urls/${id}`,
                headers: authHeader(),
            }
        ).then((response) => {
            if (response.data.status) {
                dispatch(getFeedUrls());
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}