import Topbar from "../../../layouts/Topbar";
import LeftSidebar from "../../../layouts/LeftSidebar";
import RightSidebar from "../../../layouts/RightSidebar";
import Footer from "../../../layouts/Footer";
import PageHeader from "../../../layouts/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SetDocumentTitle } from "../../../helper/functions";
import { APP_NAME } from "../../../constants/uiTypes";
import { addTeam, updateGame, updateTeam } from "../../../../store/actions/sportsActions";
import Select from "react-select";
function Team(props) {
    let accountsOptions = [];
    SetDocumentTitle(`Sport Game Actions \u2022 ${APP_NAME}`);
    const [formInput, setFormInput] = useState({
        name: "",
        description: "",
        logo: "",
        cover_image: "",
        total_player: "",
        is_published: true,
        founded_at: "",
        arena: "",
    });
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const games = useSelector((state) => state.list_of_sports.games);
    console.log(formInput);
    console.log(id);
    const getUsersOptions = (user) => {
        setFormInput({ ...formInput, seller_id: user?.value })
    }

    useEffect(() => {
        if (id) {
            setEditMode(true);
            // const singleGame = games.find(game => game._id === id);
            // setFormInput({
            //     name: singleGame?.name,
            //     is_published: singleGame?.is_published,
            // });
        }
    }, [dispatch, id]);

    const searchUser = (input) => {
        // dispatch(getUsers(1, input));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('description', formInput.description);
        formData.append('cover_image', formInput.cover_image);
        formData.append('logo', formInput.logo);
        formData.append('total_player', formInput.total_player);
        formData.append('founded_at', formInput.founded_at);
        formData.append('arena', formInput.arena);
        if (!editMode) {
            dispatch(addTeam(formData));
            navigator("/teams");
        } else {
            formData.append('is_published', formInput.is_published)
            dispatch(updateTeam(formData, id));
            // navigator("/sports-games");
        }
    }
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box">
                                        <h4 className="header-title">
                                            {!editMode ? "Create New Teams" : "Edit Teams"}
                                        </h4>
                                        <p className="sub-header">
                                            {/* {users.all_users.count} Users Found */}
                                        </p>
                                        <div className="row">
                                            <div className="col-12">
                                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Name</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="eg. Norfolk State Spartans" value={formInput.name} onChange={(e) => setFormInput({ ...formInput, name: e.target.value })} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Description</label>
                                                        <div className="col-md-10">
                                                            <textarea className="form-control" rows="5" value={formInput.description} onChange={(e) => setFormInput({ ...formInput, description: e.target.value })} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Logo</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="image/*" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, logo: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Cover Image</label>
                                                        <div className="col-md-10">
                                                            <input type="file" accept="image/*" required={!editMode ? true : false} style={{ paddingTop: 5 + "px", paddingBottom: 5 + "px" }} onChange={(e) => setFormInput({ ...formInput, cover_image: e.target.files[0] })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label">Game</label>
                                                        <div className="col-md-10">
                                                            <Select name="form-field-name" options={accountsOptions} onChange={getUsersOptions} onInputChange={searchUser} required={true} />
                                                            {editMode ? <> Current Seller Name: <b>{formInput?.seller?.[0]?.name}</b></> : null}

                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Founded at</label>
                                                        <div className="col-md-10">
                                                            <input type="number" className="form-control" placeholder="eg. 1965" value={formInput.founded_at} onChange={(e) => setFormInput({ ...formInput, founded_at: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Arena</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="eg. Norfolk State University" value={formInput.arena} onChange={(e) => setFormInput({ ...formInput, arena: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-2 col-form-label" >Players in team</label>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" placeholder="eg. 4-16" value={formInput.total_player} onChange={(e) => setFormInput({ ...formInput, total_player: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    {editMode ?
                                                        <>
                                                            <div className="form-group row">
                                                                <label className="col-md-2 col-form-label">Status</label>
                                                                <div className="col-md-2">
                                                                    <div className="checkbox checkbox-primary">
                                                                        <input id="is_published" type="checkbox" checked={formInput.is_published} onChange={(e) => setFormInput({ ...formInput, is_published: !formInput.is_published })} />
                                                                        <label htmlFor="is_published">
                                                                            {(formInput.is_published) ? "Active" : "Inactive"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null}
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-2">
                                                            {!editMode ? "Submit" : "Update"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    );
}
export default Team;