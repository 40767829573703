import { ADD_BANNER, DELETE_BANNER, GET_BANNERS, UPDATE_BANNER } from "../../components/constants/actionTypes";

export const appBannerReducer = (state = [], action) => {
    switch (action.type) {
        case GET_BANNERS:
            return action.banners;
        case ADD_BANNER:
            return [action.banner, ...state];
        case DELETE_BANNER:
            return state.filter(banner => {
                if (banner._id === action.id) {
                    return false;
                }
                return true;
            });
        case UPDATE_BANNER:
            return state.map((banner) => {
                if (banner._id == action.banner._id) {
                    return action.banner;
                } else {
                    return banner;
                }
            });
        default:
            return state;
    }
}