import Topbar from "../layouts/Topbar";
import LeftSidebar from "../layouts/LeftSidebar";
import RightSidebar from "../layouts/RightSidebar";
import Footer from "../layouts/Footer";
import PageHeader from "../layouts/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { loadDashboardData } from "../../store/actions/dashboardActions";
import DashboardTiles from "../layouts/elements/DashboardTiles";
import { SetDocumentTitle } from "../helper/functions";
import { APP_NAME } from "../constants/uiTypes";
function Dashboard() {
    SetDocumentTitle(`Dashboard \u2022 ${APP_NAME}`);
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state.dashboard_data);
    useEffect(() => {
        dispatch(loadDashboardData());
    }, [dispatch])
    return (
        <>
            <div id="wrapper">
                <Topbar></Topbar>
                <LeftSidebar></LeftSidebar>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <PageHeader></PageHeader>
                            <div className="row">
                                <DashboardTiles data_count={dashboardData.users ? dashboardData.users : 0} data_title={"Users"} data_icons={"mdi mdi-account-multiple"} data_subtitle=""></DashboardTiles>
                                <DashboardTiles data_count={dashboardData.colleges ? dashboardData.colleges : 0} data_title={"Colleges"} data_icons={"fas fa-university"} data_subtitle=""></DashboardTiles>
                                <DashboardTiles data_count={dashboardData.playlists ? dashboardData.playlists : 0} data_title={"Playlist"} data_icons={"mdi mdi-playlist-music-outline"} data_subtitle=""></DashboardTiles>
                                <DashboardTiles data_count={dashboardData.songs ? dashboardData.songs : 0} data_title={"Songs"} data_icons={"mdi mdi-library-music-outline"} data_subtitle=""></DashboardTiles>
                                <DashboardTiles data_count={dashboardData.news ? dashboardData.news : 0} data_title={"News"} data_icons={"mdi mdi-newspaper"} data_subtitle=""></DashboardTiles>
                                <DashboardTiles data_count={dashboardData.posts ? dashboardData.posts : 0} data_title={"Posts"} data_icons={"mdi mdi-card-bulleted-outline"} data_subtitle=""></DashboardTiles>
                                <DashboardTiles data_count={dashboardData.posts ? dashboardData.posts : 0} data_title={"Shorts"} data_icons={"mdi mdi-code-string"} data_subtitle=""></DashboardTiles>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <RightSidebar></RightSidebar>
        </>
    )
}
export default Dashboard;